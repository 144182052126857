import React from 'react';
import { Grid, SvgIcon } from '@mui/material';
import { GlobalMenuLink, GlobalMenuLinkTypo } from './styles';
import { GlobalMenuDrawerProps } from '../';
import { MenuData } from '@/types/api';
import NextLink from 'next/link';
import LinkMenuIcon from '@/public/icons/link_menu.svg';
import { useBottomSheetIframe } from '@/hooks/useBottomSheetIframe';

type RenderByViewTypeProps = MenuData &
  Pick<GlobalMenuDrawerProps, 'onClose'> & {
    readonly isMobile: boolean;
    readonly isRn: boolean;
  };

const RenderByViewType = (props: RenderByViewTypeProps) => {
  const { action, link, name, onClose, isRn } = props;

  const { openBottomSheet } = useBottomSheetIframe();

  switch (action) {
    case 'newTab': {
      return (
        <GlobalMenuLink
          key={name}
          href={link || ''}
          target={'_blank'}
          rel={'noreferrer'}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            onClose?.();

            setTimeout(() => {
              window.open(link || '', '_blank');
            }, 300);
          }}
        >
          <span style={{ verticalAlign: 'middle' }}>{name}</span>
          <SvgIcon
            sx={{
              width: '20px',
              height: '20px',
              lineHeight: '24px',
              verticalAlign: 'middle',
              marginLeft: '4px',
            }}
          >
            <LinkMenuIcon />
          </SvgIcon>
        </GlobalMenuLink>
      );
    }
    case 'modal':
    case 'layer': {
      return (
        <GlobalMenuLinkTypo
          onClick={() => {
            onClose();
            openBottomSheet({
              isRn,
              url: link || '',
              iframeId: name,
              isFullHeight: isRn,
            });
          }}
        >
          {name}
        </GlobalMenuLinkTypo>
      );
    }
    case undefined:
      return (
        <GlobalMenuLink
          key={name}
          href={link || ''}
          rel={'noreferrer'}
          component={NextLink}
          onClick={onClose}
        >
          {name}
        </GlobalMenuLink>
      );
    default:
      return null;
  }
};

const Menu = (props: RenderByViewTypeProps) => {
  if (!props.isDisplay) {
    return null;
  }

  const MenuComp = <RenderByViewType {...props} />;

  return (
    <>
      {props.isMobile ? (
        <Grid item xs={6} sx={{ padding: '0 20px 0 0' }}>
          {MenuComp}
        </Grid>
      ) : (
        MenuComp
      )}
      {props.childData.map((c) => (
        <Menu {...props} {...c} key={c.name} />
      ))}
    </>
  );
};

export default Menu;
