import React from 'react';
import { Link, LinkProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const LinkStyle = {
  width: '100%',
  display: 'block',
  textDecorationLine: 'none',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: 'white',
  cursor: 'pointer',
  textUnderlineOffset: '10px',
  '@media (min-width: 770px)': {
    fontSize: '16px',
    lineHeight: '24px',
  },
  '@media (min-width: 1921px)': {
    fontSize: '20px',
    lineHeight: '28px',
  },
  '&:hover': { textDecorationLine: 'underline' },
  '&.external': {
    '&.sub::after': {
      width: '16px',
      height: '16px',
    },
  },
};

export const GlobalMenuLink = styled(({ children, ...props }: LinkProps) => (
  <Link {...props}>{children}</Link>
))(() => LinkStyle);

export const GlobalMenuLinkTypo = styled(
  ({ children, ...props }: TypographyProps) => (
    <Typography {...props}>{children}</Typography>
  ),
)(() => LinkStyle);
