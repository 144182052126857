import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import ImageWithErrorHandling from '@/components/ImageWithErrorHandling';
import { imgUrlChk } from '@/utils/function';

export type MyPageProfileProps = PropsWithChildren<{
  profileImageUrl: string;
}>;

const defaultImageUrl = '/images/noProfile.svg';

const MyPageProfile = ({ profileImageUrl, children }: MyPageProfileProps) => {
  return (
    <Box
      sx={{
        padding: '24px 20px',
        backgroundColor: 'black',
        position: 'relative',
        background: `#000 url('/images/mypage/grapch.png') center / cover no-repeat`,
        '.profile-image': {
          display: 'block',
          margin: 'auto',
          borderRadius: '12px',
          objectFit: 'cover',
        },
      }}
    >
      <ImageWithErrorHandling
        src={imgUrlChk(profileImageUrl || defaultImageUrl)}
        errorImage={defaultImageUrl}
        width={180}
        height={180}
        alt={'profile-image'}
        className={'profile-image'}
      />
      {children}
    </Box>
  );
};

export default MyPageProfile;
