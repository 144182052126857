import { Box } from '@mui/material';

const Footer = () => {
  return (
    // <Box>
    //   <div
    //     style={{
    //       borderTop: '1px solid #E7E9EC',
    //       minHeight: '300px',
    //       padding: '40px 30px',
    //     }}
    //   >
    //     footer
    //   </div>
    // </Box>
    <></>
  );
};

export default Footer;
