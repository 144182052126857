import { Box, IconButton } from '@mui/material';
import FileThumbnail, {
  FileThumbnailProps,
} from '@/components/file-thumbnail/FileThumbnail';
import React from 'react';
import { ExtendFile } from '@/components/file-thumbnail/utils';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { dashhed4 } from '@/theme/palette';
import { PlusIcon } from '@/theme/overrides/CustomIcons';

export type MultiFileDragDropProps = {
  files: ExtendFile[];
  onDragStart: (e: DragStartEvent) => void;
  onDragEnd: (e: DragEndEvent) => void;
  onDragCancel: () => void;
  onRemove: (file: ExtendFile) => void;
  activeFile: ExtendFile | null;
  useAddButton: boolean;
  onFileOpen: () => void;
  sortable?: boolean;
} & Pick<
  FileThumbnailProps,
  'previewType' | 'onRemove' | 'disabled' | 'loading'
>;

export default function MultiFileDragDrop({
  files,
  onDragEnd,
  onDragStart,
  onDragCancel,
  onRemove,
  activeFile,
  previewType,
  disabled,
  loading,
  useAddButton,
  onFileOpen,
  sortable,
}: MultiFileDragDropProps) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 1 },
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <Box
        sx={
          previewType === 'filename'
            ? {
                display: 'grid',
                gridTemplateColumns: 'calc(50% - 8px) calc(50% - 8px)',
                gap: '8px',
                paddingTop: 2,
              }
            : {
                display: 'flex',
                paddingTop: 2,
                rowGap: 2,
                columnGap: 2,
                alignItems: 'center',
                flexWrap: 'wrap',
                flexDirection: 'row',
              }
        }
      >
        <SortableContext
          items={files as { id: UniqueIdentifier }[]}
          strategy={rectSortingStrategy}
          disabled={!sortable}
        >
          {files.map((file: ExtendFile, index) => (
            <SortableItem
              key={file.id}
              file={file}
              previewType={previewType}
              disabled={disabled}
              loading={loading}
              onRemove={onRemove}
            ></SortableItem>
          ))}
          <DragOverlay>
            {activeFile && (
              <FileThumbnail
                key={activeFile.id}
                file={activeFile}
                previewType={previewType}
                onRemove={onRemove}
                disabled={disabled}
                loading={loading}
                isDragging={false}
                tooltip
              />
            )}
          </DragOverlay>
          {useAddButton && (
            <IconButton
              sx={{
                width: 160,
                height: 160,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                borderRadius: '6px',
                backgroundImage: dashhed4,
              }}
              onClick={onFileOpen}
              disabled={disabled || loading}
            >
              <PlusIcon />
            </IconButton>
          )}
        </SortableContext>
      </Box>
    </DndContext>
  );
}

function SortableItem(props: FileThumbnailProps) {
  const { file, previewType, onRemove, disabled, loading } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: file.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Box ref={setNodeRef} style={style} {...listeners} {...attributes}>
      <FileThumbnail
        key={file.id}
        previewType={previewType}
        file={file}
        onRemove={onRemove}
        disabled={disabled}
        loading={loading}
        isDragging={isDragging}
        tooltip
      />
    </Box>
  );
}
