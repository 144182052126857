import React from 'react';
import { Box } from '@mui/material';

import { MyPageMainProps } from 'components/MenuDrawer/MyMenuDrawer/V2/Main';

// TODO 자사몰 직원 할인
const CertifyMallIdContent = (props: MyPageMainProps) => {
  return (
    <Box>
      <div>자사몰 직원 할인 해보자아아아아</div>
    </Box>
  );
};

export default CertifyMallIdContent;
