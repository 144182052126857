import React, { Suspense, useEffect, useState } from 'react';
import { Alert, Skeleton, Snackbar } from '@mui/material';
import {
  BottomButton,
  CustomCloseIcon,
  CustomDrawer,
  DisplayName,
  FirstContainer,
  ProfileContainer,
  SecondContainer,
  StyleCopyLayout,
  StyledButton,
  StyledButtonImg,
  StyledContainer,
  StyledCopyButton,
  StyledImg,
  StyledLabel,
  StyleInput,
} from '@/components/Profile/styles';
import { CustomDivider } from '@/styles/styles';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SecondItem from '@/components/Profile/SecondItem';
import { TOKEN_KEY_SET } from '@/const/tokens';
import { removeCookie } from '@/utils/tokens';
import { useRouter } from 'next/router';
import Alarm, { AlarmSuspense } from '@/components/Alarm';
import { disableMessage } from '@/utils/firebase/initialize';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { useFindPassport, updateProfileImage } from '@/hooks/apiHooks';
import { useForm, useWatch } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import ChangeUserPassport from '@/components/Profile/ChangeUserPassport';
import { imgUrlChk } from '@/utils/function';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFiles } from '@fortawesome/pro-duotone-svg-icons';
import IntroduceJob from '@/components/Profile/IntroduceJob';
import { Passport } from '@/types/api';
import { t } from 'i18next';
import MyMenuDrawer from '../MenuDrawer/MyMenuDrawer/V2';

type FormType = {
  fileList: File[];
};

const Profile = ({
  nameColor,
  // TODO V2 Mypage 개발 후 정리
  useMypageV2 = false,
}: {
  nameColor?: string;
  useMypageV2?: boolean;
}) => {
  const {
    name,
    profileImageUrl,
    departmentName,
    employeeNumber,
    email,
    companyName,
    passportId,
  } = useRecoilValue(MyInfoState);

  const { mutate } = useFindPassport(passportId);
  const router = useRouter();

  const [open, setOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const [upLoading, setUpLoading] = useState(false);
  const reset = useResetRecoilState(MyInfoState);
  const setMyInfoState = useSetRecoilState(MyInfoState);
  const { register, reset: profileReset, control } = useForm<FormType>();

  const fileList = useWatch({ control, name: 'fileList' });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = async () => {
    disableMessage();
    reset();
    TOKEN_KEY_SET.forEach(removeCookie);
    setTimeout(
      () =>
        router
          .push(
            `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${process.env.NEXT_PUBLIC_ORIGIN_URL} `,
          )
          .then((_) => console.log('로그아웃 완료')),
      500,
    );
  };

  useEffect(() => {
    const uploadFile = fileList?.length > 0 ? fileList[0] : undefined;
    if (uploadFile) {
      setUpLoading(true);
      updateProfileImage({ id: passportId, obj: uploadFile })
        .then(() => {
          profileReset();
          mutate<Passport>().then((res) =>
            setMyInfoState((state) => ({
              ...state,
              profileImageUrl: res?.detail.profileImageUrl ?? profileImageUrl,
            })),
          );
          setUpLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setUpLoading(false);
        });
    }
  }, [fileList]);

  const profileImage = profileImageUrl.includes('http')
    ? profileImageUrl
    : `https:${profileImageUrl}`;

  return email ? ( // FIXME
    <>
      <StyledButton type={'button'} onClick={handleOpen}>
        <StyledButtonImg
          width={30}
          height={30}
          src={imgUrlChk(profileImage)}
          alt={'profile-small'}
        />
      </StyledButton>
      {/* TODO V2 Mypage 개발 후 정리 */}
      {useMypageV2 ? (
        <MyMenuDrawer isOpen={open} onClose={handleClose} />
      ) : (
        <CustomDrawer open={open} onClose={handleClose}>
          <StyledContainer>
            <button type={'button'} onClick={handleClose}>
              <CustomCloseIcon />
            </button>
            <FirstContainer>
              {profileImageUrl ? (
                <div style={{ position: 'relative' }}>
                  <StyledImg
                    width={249}
                    height={320}
                    src={imgUrlChk(profileImage)}
                    alt={'profile-medium'}
                  />
                  <form>
                    <StyledLabel>
                      {upLoading ? (
                        <CircularProgress size={'1rem'} />
                      ) : (
                        <ModeEditRoundedIcon />
                      )}
                    </StyledLabel>
                    <StyleInput
                      {...register('fileList')}
                      disabled={upLoading}
                    />
                  </form>
                </div>
              ) : (
                <Skeleton variant={'circular'} width={150} height={150} />
              )}
              <DisplayName>{name}</DisplayName>
              <ChangeUserPassport />
              <ProfileContainer color={nameColor}>
                <div>{departmentName}</div>
                <StyleCopyLayout>
                  <CopyToClipboard text={employeeNumber}>
                    <StyledCopyButton onClick={() => setToastOpen(true)}>
                      {employeeNumber}
                      <FontAwesomeIcon
                        icon={faFiles}
                        size="sm"
                        style={{ marginLeft: '0.5rem' }}
                      />
                    </StyledCopyButton>
                  </CopyToClipboard>
                </StyleCopyLayout>
                <StyleCopyLayout>
                  <CopyToClipboard text={email}>
                    <StyledCopyButton onClick={() => setToastOpen(true)}>
                      {email}
                      <FontAwesomeIcon
                        icon={faFiles}
                        size="sm"
                        style={{ marginLeft: '0.5rem' }}
                      />
                    </StyledCopyButton>
                  </CopyToClipboard>
                </StyleCopyLayout>
              </ProfileContainer>
              <div style={{ marginTop: '1.5rem' }}>{companyName}</div>
            </FirstContainer>
            <IntroduceJob />
            <CustomDivider />
            <SecondContainer>
              <SecondItem />
            </SecondContainer>
            <BottomButton onClick={handleLogout}>Log out</BottomButton>
          </StyledContainer>
          <Snackbar
            style={{ position: 'absolute' }}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={toastOpen}
            onClose={() => setToastOpen(false)}
          >
            <Alert
              onClose={() => setToastOpen(false)}
              severity="success"
              sx={{ width: '100%' }}
            >
              {t('사번이 복사되었습니다.')}
            </Alert>
          </Snackbar>
        </CustomDrawer>
      )}

      <Suspense fallback={<AlarmSuspense />}>
        <Alarm />
      </Suspense>
    </>
  ) : (
    <div />
  );
};

export default Profile;
