import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Divider, Grid } from '@mui/material';
import CloseIcon from '@/public/images/icon/close_white_32.svg';
import {
  Category,
  Header,
  MenuSectionTitle,
  MenuSectionWrapper,
  SectionContainer,
} from './styles';
import { GlobalMenuDrawerProps } from '../';
import { MenuData, MenuResponse } from '@/types/api';
import Menu from '../Menu';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PageURL } from '@/constant/pageURL';

const MobileMenu = ({
  onClose,
  rawData,
  isRn,
}: Pick<GlobalMenuDrawerProps, 'onClose'> & {
  rawData?: MenuResponse;
  isRn: boolean;
}) => {
  const [menuData, setMenuData] = useState<
    { title: string; menu: MenuData[] }[]
  >([]);
  const [curr, setCurr] = useState(0);
  const [isClick, setIsClick] = useState(false);

  const headerRef = useRef<HTMLDivElement>(null);
  const parentDetectRef = useRef<HTMLDivElement>(null);

  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const swiperRef = useRef<any | null>(null);
  const categoryWrapperRef = useRef<HTMLDivElement>(null);

  const headerHeight =
    headerRef?.current?.getBoundingClientRect()?.height ?? 131;

  const scrollBottomMargin =
    window.innerHeight -
    headerHeight -
    (sectionRefs.current?.[
      (sectionRefs.current?.length ?? 1) - 1
    ]?.getBoundingClientRect()?.height ?? 315);

  useEffect(() => {
    if (rawData) {
      const menu = rawData.data
        .filter((item) => Boolean(item.isDisplay))
        .map((item) => {
          // 예외처리 - 'L.I.F.E'의 링크를 내부 소스 링크로 변경
          if (item.name === '총무' && item.childData?.length > 0) {
            item.childData = item.childData.map((child) => {
              if (child.name === 'L.I.F.E') {
                return {
                  ...child,
                  link: PageURL.LIFE_HTML,
                };
              }
              return child;
            });
          }

          return {
            title: item.name,
            menu: item.childData,
          };
        });

      setMenuData(menu);
    }
  }, []);

  useEffect(() => {
    if (swiperRef.current === null) {
      return;
    }

    const slideId = `group-keyword-${curr}`;
    const slide = document.getElementById(slideId);

    if (slide) {
      swiperRef.current.swiper.slideTo(curr, 300, false);
    }
  }, [curr]);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (isClick) {
        setIsClick(false);
      } else {
        window.requestAnimationFrame(() => {
          const tabSize = categoryWrapperRef.current?.offsetHeight || 64;
          const scrollY = (parentDetectRef.current?.scrollTop ?? 0) + tabSize;

          if (!sectionRefs || sectionRefs.current.length === 0) {
            return;
          }

          sectionRefs.current.forEach((ref, index) => {
            if (ref) {
              const offsetTop = ref.offsetTop;
              const offsetBottom = ref.offsetTop + ref.clientHeight;

              if (scrollY >= offsetTop && scrollY < offsetBottom) {
                setCurr(index);
              }
            }
          });
        });
      }
    };

    parentDetectRef.current?.addEventListener?.('scroll', handleScroll);
    return () => {
      parentDetectRef.current?.removeEventListener?.('scroll', handleScroll);
    };
  }, [isClick]);

  return (
    <Box
      sx={{
        backgroundColor: 'black',
        color: 'white',
        position: 'relative',
      }}
      component={'div'}
    >
      <Header ref={headerRef}>
        <SectionContainer
          sx={{
            borderBottom: '1px solid #4D5157',
          }}
        >
          <Button
            className={'close'}
            onClick={onClose}
            sx={{
              minWidth: 'auto',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
              marginLeft: '20px',
            }}
          >
            <CloseIcon />
          </Button>
        </SectionContainer>

        <SectionContainer sx={{ overflow: 'hidden' }}>
          <Swiper
            slidesPerView={'auto'}
            ref={swiperRef}
            style={{ padding: '0 20px' }}
          >
            {menuData.map((item, index) => (
              <SwiperSlide
                key={index}
                id={`group-keyword-${index}`}
                style={{
                  width: 'auto',
                }}
              >
                <Category
                  disableRipple
                  className={index === curr ? 'curr' : undefined}
                  onClick={() => {
                    const target = sectionRefs.current[index];
                    if (target) {
                      setIsClick(true);
                      setCurr(index);

                      parentDetectRef.current?.scrollTo?.({
                        top: target.offsetTop,
                        behavior: 'auto',
                      });
                    }
                  }}
                >
                  {item.title}
                </Category>
              </SwiperSlide>
            ))}
          </Swiper>
        </SectionContainer>
      </Header>
      <Box
        sx={{
          height: `calc(100vh - ${headerHeight}px)`,
          overflowY: 'auto',
        }}
        ref={parentDetectRef}
      >
        <Box
          sx={{
            paddingBottom: `${scrollBottomMargin}px`,
            position: 'relative',
          }}
          component={'div'}
        >
          {menuData.map((item, idx) => {
            return (
              <MenuSection
                key={idx}
                idx={idx}
                sectionRefs={sectionRefs}
                title={item.title}
                menuData={item.menu}
                onClose={onClose}
                headerHeight={headerHeight}
                isRn={isRn}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default MobileMenu;

const MenuSection = ({
  sectionRefs,
  idx,
  title,
  menuData = [],
  onClose,
  headerHeight,
  isRn,
}: {
  idx: number;
  sectionRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  title: string;
  menuData?: MenuData[];
  onClose: () => void;
  headerHeight: number;
  isRn: boolean;
}) => {
  return (
    <MenuSectionWrapper
      id={title}
      ref={(ref) => {
        if (sectionRefs.current) {
          sectionRefs.current[idx] = ref;
        }
      }}
      sx={{
        scrollMarginTop: `${headerHeight}px`,
        padding: '24px 20px',
      }}
    >
      <MenuSectionTitle>{title}</MenuSectionTitle>
      <Divider
        sx={{
          margin: '12px 0',
        }}
      />
      <Grid container rowSpacing={'24px'}>
        {menuData.map((c) => (
          <Menu
            {...c}
            key={c.name}
            onClose={onClose}
            isMobile={true}
            isRn={isRn}
          />
        ))}
      </Grid>
    </MenuSectionWrapper>
  );
};
