import React from 'react';
import { Drawer, DrawerProps } from '@mui/material';
import { MyMenuContent, MyMenuContentTitle } from '@/constant/mymenu';
import { MyMenuContentWrapper, MyMenuHeader } from './styles';
import MyPageMain, {
  MyPageMainProps,
} from 'components/MenuDrawer/MyMenuDrawer/V2/Main';
import { MyMenuContentMap } from './@utils/constant';
import useMyMenuDrawerProps, {
  MyMenuDrawerSettings,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/@hooks/useMyMenuDrawerProps';
import { MyInfo } from '@/types/api';

export type MyMenuDrawerProps = {
  readonly isOpen: DrawerProps['open'];
  readonly menuOnClose: () => void;
  readonly subMenuOnClose: () => void;
  readonly myInfo: MyInfo;
  readonly currSubMenu: null | MyMenuContent;
  readonly openSubMenu: (id: MyMenuContent) => void;
  readonly closeSubMenu: (id: MyMenuContent) => void;

  readonly mainProps: MyPageMainProps;
};

const MyMenuDrawer = (settings: MyMenuDrawerSettings) => {
  const {
    isOpen,
    menuOnClose: onClose,
    subMenuOnClose: subMenuOnClose,
    currSubMenu: isOpenSub,
    openSubMenu: openSub,
    closeSubMenu: onCloseSub,
    myInfo,
    mainProps,
  } = useMyMenuDrawerProps(settings);

  return (
    <Drawer open={isOpen} onClose={onClose} anchor={'right'}>
      <MyMenuHeader
        onBack={subMenuOnClose}
        onClose={onClose}
        title={'마이페이지'}
        hasBackButton={false}
      />
      <MyPageMain {...mainProps} />

      {/* Sub Menu Drawer 등록 */}
      {Array.from(MyMenuContentMap.keys()).map((menuKey) => {
        const ContentComponent = MyMenuContentMap.get(menuKey);
        const id = menuKey;
        const isOpen = isOpenSub === menuKey;

        return (
          <Drawer
            key={id}
            id={id}
            open={isOpen}
            onClose={(event: object, reason: string) => {
              if (reason === 'backdropClick') onCloseSub(id);
              else onClose();
            }}
            anchor={'right'}
            ModalProps={{
              sx: { '.MuiModal-backdrop': { opacity: '0 !important' } },
            }}
          >
            <MyMenuHeader
              onBack={() => onCloseSub(id)}
              onClose={onClose}
              hasBackButton={true}
              title={MyMenuContentTitle[id]}
            />
            <MyMenuContentWrapper>
              <ContentComponent
                myInfo={myInfo}
                openSub={openSub}
                onClose={onClose}
              />
            </MyMenuContentWrapper>
          </Drawer>
        );
      })}
    </Drawer>
  );
};

export default MyMenuDrawer;
