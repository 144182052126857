import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { updateProfileImage, useFindPassport } from '@/hooks/apiHooks/index';
import { useCallback, useState } from 'react';
import { Passport } from '@/types/api';

const useUpdateProfileImage = () => {
  const { profileImageUrl, passportId } = useRecoilValue(MyInfoState);
  const setMyInfoState = useSetRecoilState(MyInfoState);
  const { mutate } = useFindPassport(passportId);
  const [isLoading, setIsLoading] = useState(false);

  const update = useCallback(
    async (file?: File) => {
      if (!file) {
        return;
      }

      try {
        setIsLoading(true);
        await updateProfileImage({ id: passportId, obj: file });
        const res = await mutate<Passport>();
        setMyInfoState((state) => ({
          ...state,
          profileImageUrl: res?.detail.profileImageUrl ?? profileImageUrl,
        }));
      } finally {
        setIsLoading(false);
      }
    },
    [profileImageUrl, passportId],
  );

  return {
    isLoading,
    update,
  };
};

export default useUpdateProfileImage;
