export enum MyMenuContent {
  MYPAGE = 'Mypage',
  EDIT_PROFILE = 'EditProfile',
  PERSONAL_INFO = 'PersonalInfo',
  MAIL_SIGNATURE = 'MailSignature',
  LANGUAGE_SETTING = 'LanguageSetting',
  ALARM_SETTING = 'AlarmSetting',
  PASSWORD_SETTING = 'PasswordSetting',
  CHECK_VACATION = 'CheckVacation',
  PROOF_EMPLOYMENT = 'ProofEmployment',
  PAY_STUB = 'PayStub',
  CERTIFY_MALL_ID = 'CertifyMallId',
  WIFI = 'Wifi',
}
export const MyMenuContentTitle: { [key in MyMenuContent]: string } = {
  [MyMenuContent.MYPAGE]: '',
  [MyMenuContent.EDIT_PROFILE]: '프로필 편집',
  [MyMenuContent.PERSONAL_INFO]: '인사정보 조회',
  [MyMenuContent.MAIL_SIGNATURE]: '메일 서명',
  [MyMenuContent.LANGUAGE_SETTING]: '언어 설정',
  [MyMenuContent.ALARM_SETTING]: '알림 설정',
  [MyMenuContent.PASSWORD_SETTING]: '비밀번호 관리',
  [MyMenuContent.CHECK_VACATION]: '휴가 현황 조회',
  [MyMenuContent.PROOF_EMPLOYMENT]: '재직증명서 발급',
  [MyMenuContent.PAY_STUB]: '급여명세서 조회',
  [MyMenuContent.CERTIFY_MALL_ID]: '자사몰 직원할인 등록',
  [MyMenuContent.WIFI]: '방문자 와이파이 인증 (큰길타워)',
};
