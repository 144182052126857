import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Stack, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { BoardDetailLikesStack } from '@/components/Board/BoardDetail/Feedback/BoardDetailLikes/styles';
import { getFeedbackUser } from '@/hooks/apiHooks';
import UserProfilePopper from '@/components/Popper/UserProfilePopper';
import EmptyIcon from '@/public/icons/empty.svg';
import useUserProfilePopper from '@/hooks/useUserProfilePopper';

type BoardDetailLikesProps = {
  documentId: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};
export type BoardDetailLikesRef = {
  refresh: () => void;
};

const BoardDetailLikes = forwardRef<BoardDetailLikesRef, BoardDetailLikesProps>(
  (props, ref) => {
    const { documentId, disabled, sx } = props;
    const { data, isLoading, mutate } = getFeedbackUser(documentId);

    const [passportId, setPassportId] = useState<string>('');

    const {
      anchorEl,
      onOpenPopper: openUserProfilePopper,
      onClickAway: onClickAwayPopper,
    } = useUserProfilePopper<HTMLButtonElement>(passportId, (el) => {
      const userId = el.dataset?.['userId'];
      setPassportId(userId ?? '');
    });

    useImperativeHandle(ref, () => ({
      refresh: mutate,
    }));

    return (
      <BoardDetailLikesStack
        className={'board-detail-likes'}
        sx={{ minHeight: '213px', ...sx }}
      >
        {(!Array.isArray(data) || data.length === 0) && (
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={'4px'}
            sx={{
              width: '100%',
              padding: '74px 0',
            }}
          >
            <EmptyIcon width={'40px'} height={'40px'} />
            <Typography
              textAlign={'center'}
              fontSize={'14px'}
              color={'#B7BBC2'}
            >
              {isLoading
                ? '불러오는 중...'
                : '아직 이 글에 좋아요를 누른 사람이 없어요.'}
            </Typography>
          </Stack>
        )}
        {data?.map((user, index) => (
          <Button
            key={index}
            data-user-id={user.passportId}
            onClick={openUserProfilePopper}
            disabled={disabled}
          >
            {user.name} {user.positionName}
          </Button>
        ))}
        <UserProfilePopper
          anchorEl={anchorEl}
          passportId={passportId}
          onClickAway={onClickAwayPopper}
        />
      </BoardDetailLikesStack>
    );
  },
);
BoardDetailLikes.displayName = 'BoardDetailLikes';

export default BoardDetailLikes;
