import styled from '@emotion/styled';
import { Box, Divider } from '@mui/material';
import { maxBreakpoints, minBreakpoints } from '@/utils/mediaQuery';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CloseIcon from '@mui/icons-material/Close';

const StyledCardLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--card-backgound-color);
  border-radius: 0.42rem;
  height: 262px;
  padding-bottom: 2rem;
  -webkit-box-shadow: 0px 0px 24px -2px rgba(82, 63, 105, 0.05);
  box-shadow: 0px 0px 24px -2px rgba(82, 63, 105, 0.05);
  border: 0;

  @media screen and (${maxBreakpoints.s}) {
    height: auto;

    .card-link-body {
      min-height: 200px;
      padding-bottom: 0;
    }
  }
`;

const NoticeUl = styled.ul`
  padding: 0.75rem;
  display: flex;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 5px;
  .text-danger {
    color: red;
  }
`;

const StyledNotice = styled(NoticeUl)`
  padding: 2rem 1rem 1.5rem 3rem;
  font-size: 12px;
  flex-direction: column;
  word-break: keep-all;
  font-family: var(--font-Pretendard);
`;

const StyledLeftButton = styled.button`
  font-weight: 600;
  font-size: 1.275rem;
  padding: 0;
`;

const RowSpaceBtw = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
`;

const StyledDivider = styled(Divider)`
  background-color: var(--text-secondary-color);
`;

const StyledTitle = styled(Box)`
  /* PortalHomeComponent title style */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-Poppins);
  color: var(--text-primary-color);
  font-weight: 600;
  font-size: 1.275rem;
  padding: 0 2.25rem;
  &.card-link-header {
    min-height: 5rem;
  }

  @media screen and (${maxBreakpoints.lg}) {
    padding: 0 2rem;
  }
`;

const StyledLinkCardBody = styled.div`
  padding: 0 2.25rem 2rem 2.25rem;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (${maxBreakpoints.lg}) {
    padding: 0 2rem 2rem 2rem;
  }

  @media screen and (${minBreakpoints.s}) {
    min-height: 192px;
  }
`;

const StyledBlankLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
StyledBlankLink.defaultProps = {
  target: 'blank',
  rel: 'noopener noreferrer',
};

const FlexRowGapBasic = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const FlexColumnGapBasic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ResponsiveHiddenWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isApp',
})<{
  isApp: boolean | undefined;
}>`
  display: ${({ isApp }) => (isApp ? 'block' : 'none')};
  @media screen and (${minBreakpoints.md}) {
    display: block;
  }
`;

const ResponsiveReverseHiddenWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isApp',
})<{
  isApp: boolean | undefined;
}>`
  display: ${({ isApp }) => (isApp ? 'none' : 'flex')};
  @media screen and (${minBreakpoints.md}) {
    display: none;
  }
`;

const CustomDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  border-top: 1px dashed var(--text-secondary-color);
  border-bottom: none;
  :last-child {
    display: none;
  }
`;

const DialogIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const DialogBackButton = styled(ArrowBackOutlinedIcon)`
  cursor: pointer;
  :hover {
    color: ${(theme) => theme.theme.colors.primary};
  }
`;

const DialogCloseButton = styled(CloseIcon)`
  cursor: pointer;
  :hover {
    color: ${(theme) => theme.theme.colors.primary};
  }
`;
DialogCloseButton.defaultProps = {
  id: 'dialog-close-button',
};

export {
  StyledCardLayout,
  NoticeUl,
  StyledNotice,
  RowSpaceBtw,
  StyledDivider,
  StyledTitle,
  StyledLeftButton,
  StyledBlankLink,
  FlexRowGapBasic,
  FlexColumnGapBasic,
  ResponsiveHiddenWrapper,
  ResponsiveReverseHiddenWrapper,
  CustomDivider,
  DialogIconContainer,
  DialogCloseButton,
  DialogBackButton,
  StyledLinkCardBody,
};
