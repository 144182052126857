import { t } from 'i18next';

export const TODAY = new Date();

export const getFirstDayOfWeek = (date: Date) =>
  new Date(
    date.setDate(date.getDate() - (date.getDay() == 0 ? 6 : date.getDay() - 1)),
  );

export const getLastDayOfWeek = (date: Date) =>
  new Date(date.setDate(date.getDate() - date.getDay() + 7));

const padZero = (s: number) => s.toString().padStart(2, '0');

// YYYY-MM-dd
export const formatFullDateString = (date: Date) =>
  `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
    date.getDate(),
  )}`;

// M/d
export const formatMddString = (date: Date, mid?: string) =>
  `${date.getMonth() + 1}${mid ? mid : '/'}${date.getDate()}`;

export const formatyyyyMMddString = (date: Date, mid?: string) =>
  `${date.getFullYear()}${mid ? mid : '/'}${('0' + (date.getMonth() + 1)).slice(
    -2,
  )}${mid ? mid : '/'}${('0' + date.getDate()).slice(-2)}`;

export const calculateDday = (targetDate: string) => {
  const currentDate = new Date();
  const target = new Date(targetDate);
  const timeDiff = target.getTime() - currentDate.getTime();
  const dDay = Math.ceil(timeDiff / (1000 * 3600 * 24));
  switch (dDay) {
    case 0:
      return 'D-Day';
    default: {
      const result = Math.abs(dDay);
      return dDay > 0 ? `D-${result}` : `D+${result}`;
    }
  }
};

export const formatBirthday = (date?: string) => {
  if (date) {
    const [month, day] = date.split('-');
    return `${t(`${month}월`)} ${t(`${day}일`)}`;
  } else {
    return '-';
  }
};
