import React, { ReactNode, useEffect, useState } from 'react';
import { StyledButton } from '@/components/InterviewCard/InterviewButton/styles';
import { Dialog, DialogContent } from '@mui/material';
import { StyledTitle } from '@/components/LinkableDialog/styles';
import { DialogCloseButton } from '@/styles/styles';
import {
  APPROVAL_AUTHOR_ME_URL,
  APPROVAL_CONFIRM_ME_URL,
  APPROVAL_UNREADREFEEREE_ME_URL,
  SA_APPROVAL_REJECT_CARD,
  SA_APPROVAL_REJECT_TAX,
  SA_HOLIDAY,
  SA_NO_APPROVAL,
  SA_NO_PROGRESS_CARD,
  SA_NO_PROGRESS_TAX,
} from '@/const/legacyApiList';
import { useSWRConfig } from 'swr';
import { FullHeightHeightIframeWithLoading } from '@/components/IframeWithLoading';

export enum RevalidateApi {
  Approval = 'approval',
  SA = 'sa',
}

export const revalidateApiList: Map<RevalidateApi, string[]> = new Map([
  [
    RevalidateApi.Approval,
    [
      APPROVAL_AUTHOR_ME_URL,
      APPROVAL_CONFIRM_ME_URL,
      APPROVAL_UNREADREFEEREE_ME_URL,
    ],
  ],
  [
    RevalidateApi.SA,
    [
      SA_HOLIDAY,
      SA_NO_PROGRESS_TAX,
      SA_NO_PROGRESS_CARD,
      SA_NO_APPROVAL,
      SA_APPROVAL_REJECT_TAX,
      SA_APPROVAL_REJECT_CARD,
    ],
  ],
]);

type P = {
  children?: ReactNode;
  title: ReactNode;
  dialogTitle?: ReactNode;
  url: string;
  revalidateTarget?: RevalidateApi;
};

const LinkableDialog = ({
  children,
  title,
  dialogTitle,
  url,
  revalidateTarget,
}: P) => {
  const { mutate } = useSWRConfig();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);

    if (revalidateTarget) {
      revalidateApiList
        .get(revalidateTarget)
        ?.forEach((apiUrl) => mutate(apiUrl, undefined, { revalidate: true }));
    }
  };

  const handleMessageEvent = (event: MessageEvent) => {
    console.log(event);
    if (event?.data) {
      try {
        const parsed = JSON.parse(event?.data);
        if (parsed['actionType'] === '`MODAL_CLOSE`') {
          handleClose();
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (open) {
      window.addEventListener('message', handleMessageEvent);
    } else {
      window.removeEventListener('message', handleMessageEvent);
    }
  }, [open]);

  return (
    <>
      <StyledButton onClick={handleOpen}>
        {children ? children : title}
      </StyledButton>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <StyledTitle>
          {dialogTitle ? dialogTitle : title}
          <DialogCloseButton onClick={handleClose} />
        </StyledTitle>
        <DialogContent style={{ padding: '0' }} dividers>
          <FullHeightHeightIframeWithLoading url={url} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LinkableDialog;
