import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import getFileData, { ExtendFile, fileFormat, fileThumb } from './utils';
import React, { useMemo } from 'react';
import { IconButton } from '@mui/material';
import { CircleDeleteIcon } from '@/theme/overrides/CustomIcons';
import AttachmentFile from 'components/AttachmentFile';
import { dashhed4 } from '@/theme/palette';

export type FileThumbnailProps = {
  file: ExtendFile;
  tooltip?: boolean;
  previewType: 'imageView' | 'image' | 'filename';
  onRemove: (file: ExtendFile) => void;
  disabled?: boolean;
  loading?: boolean;
  isDragging?: boolean;
};

export default function FileThumbnail({
  file,
  tooltip,
  previewType,
  onRemove,
  disabled,
  isDragging,
  loading,
}: FileThumbnailProps) {
  const { name = '', path = '', preview = '' } = getFileData(file);

  const renderContent = useMemo(() => {
    const format = fileFormat(path || preview);
    const extendFile = file as ExtendFile;

    switch (previewType) {
      case 'imageView':
      case 'image': {
        const Comp =
          previewType === 'imageView' ? (
            <Box
              component="img"
              src={preview}
              sx={{
                width: 1,
                height: 1,
                flexShrink: 0,
                objectFit: 'cover',
                borderRadius: '6px',
              }}
            />
          ) : (
            <Box
              component="img"
              src={fileThumb(format)}
              sx={{
                width: 32,
                height: 32,
                flexShrink: 0,
              }}
            />
          );

        const renderContent = (
          <Box
            sx={{
              borderRadius: '6px',
              backgroundColor: '#F9FAFB',
              border: `solid 1px transparent`,
              ...(isDragging && {
                backgroundImage: dashhed4,
              }),
            }}
          >
            <Box
              sx={{
                width: 160,
                height: 160,
                borderRadius: '6px',
                position: 'relative',
              }}
            >
              {Comp}
              {isDragging && (
                <Box
                  sx={{
                    position: 'absolute',
                    border: `solid 1px transparent`,
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    background: '#F9FAFB',
                    opacity: 0.65,
                  }}
                />
              )}
              <IconButton
                sx={{
                  top: '-12px',
                  right: '-12px',
                  position: 'absolute',
                  p: 1,
                  '&:hover': {
                    background: 'transparent',
                  },
                }}
                disabled={disabled || loading}
                onClick={() => onRemove(extendFile)}
              >
                <CircleDeleteIcon />
              </IconButton>
            </Box>
          </Box>
        );

        if (tooltip) {
          return (
            <Tooltip title={name}>
              <Stack
                flexShrink={0}
                component="span"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: 'fit-content',
                  height: 'inherit',
                }}
              >
                {renderContent}
              </Stack>
            </Tooltip>
          );
        }

        return renderContent;
      }
      case 'filename':
        return (
          <AttachmentFile
            file={{
              ...extendFile,
              link: extendFile.path ?? '',
            }}
            buttonOnClick={() => onRemove(extendFile)}
            disabled={disabled || loading}
            buttonIcon={<CircleDeleteIcon />}
          />
        );
    }
  }, [name, path, preview, previewType, onRemove, disabled, loading]);

  return <>{renderContent}</>;
}
