import {
  Box,
  BoxProps,
  Button,
  Link,
  LinkProps,
  Stack,
  StackProps,
  styled,
} from '@mui/material';
import React from 'react';

export const IconButton = styled(Button)(() => ({
  minWidth: '28px',
  padding: '8px 10px',
}));

export const Wrapper = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>{children}</Box>
))(() => ({}));

export const HeaderWrapper = styled(({ children, ...props }: StackProps) => (
  <Stack
    direction={'row'}
    justifyContent={'space-between'}
    alignItems={'center'}
    {...props}
  >
    {children}
  </Stack>
))(() => ({
  position: 'relative',
  height: '72px',
  boxSizing: 'border-box',
  margin: '0 auto',
  minWidth: '1050px',
}));

export const Logo = styled(({ children, ...props }: LinkProps) => (
  <Link {...props}>{children}</Link>
))(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '72px',
  height: '32px',
  svg: {
    width: '100%',
    height: 'auto',
  },
}));
