import { useMediaQuery } from '@mui/material';
import {
  LayoutType,
  MaxDesktopWidthMap,
  MaxMobileWidthMap,
} from '@/constant/layout';

const useLayoutSize = (layoutType = LayoutType.DEFAULT) => {
  const maxMobileWidth = MaxMobileWidthMap[layoutType];
  const maxDesktopWidth = MaxDesktopWidthMap[layoutType];

  const isMobile = useMediaQuery(`(max-width:${maxMobileWidth}px)`);
  const isSmallDesktop = useMediaQuery(
    `(min-width: ${maxMobileWidth}px) and (max-width:${maxDesktopWidth}px)`,
  );
  const isDesktop = !isMobile;

  return {
    maxMobileWidth,
    isMobile,
    maxDesktopWidth,
    isSmallDesktop,
    isDesktop,
  };
};

export default useLayoutSize;
