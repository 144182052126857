import * as React from 'react';
import { Autocomplete, ListItem, TextField } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { CircleDeleteIcon, SelectArrow } from '@/theme/overrides/CustomIcons';
import {
  AutocompleteHighlightColor,
  AutocompleteListItemStyle,
  AutocompletePaperStyle,
  AutocompleteSelectStyle,
  AutocompleteTextFieldStyle,
} from '@/components/AutoCompleteHighLightsSelect/styles';
import { WrappedAutoCompleteProps } from '@/components/AutoCompleteHighLightsSelect/common';
import { ErrorHelperTextStyle } from '@/components/HookForm/RHFTextField';

export default function AutoCompleteHighLightsSelect<
  TOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  value,
  options,
  labelKey,
  valueKey,
  placeholder,
  helperText,
  error,
  ...props
}: WrappedAutoCompleteProps<TOption, Multiple, DisableClearable, FreeSolo>) {
  const optionValue = (option: string | TOption) =>
    typeof option === 'string' ? option : valueKey ? option[valueKey] + '' : '';
  const optionLabel = (option: string | TOption) =>
    typeof option === 'string' ? option : labelKey ? option[labelKey] + '' : '';

  return (
    <Autocomplete
      options={options}
      value={value}
      popupIcon={
        <SelectArrow
          style={{
            height: '20px',
            width: '20px',
          }}
          stroke={props.disabled ? '#D1D8E2' : 'black'}
        />
      }
      slotProps={{
        paper: {
          elevation: 0,
          style: AutocompletePaperStyle,
        },
      }}
      clearIcon={<CircleDeleteIcon />}
      sx={{ ...AutocompleteSelectStyle, ...props.sx }}
      getOptionLabel={(option) => optionLabel(option)}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error ? error?.message : helperText ?? ''}
          placeholder={placeholder}
          margin="none"
          InputProps={{
            ...params.InputProps,
            sx: AutocompleteTextFieldStyle,
          }}
          FormHelperTextProps={{
            sx: {
              ...ErrorHelperTextStyle,
            },
          }}
        />
      )}
      renderOption={(props, option, { inputValue }, data) => {
        const { key, ...optionProps } = props;
        const { value, focused } = data;
        const realValue =
          typeof value === 'string'
            ? value
            : valueKey && value
            ? String((value as Record<typeof valueKey, string>)[valueKey])
            : '';
        const matches = match(optionLabel(option), inputValue ?? '', {
          insideWords: true,
        });
        const isSelected = realValue === optionValue(option);
        const parts = parse(optionLabel(option), matches);

        return (
          <ListItem key={key} sx={AutocompleteListItemStyle} {...optionProps}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    color:
                      focused && isSelected && part.highlight
                        ? AutocompleteHighlightColor
                        : isSelected
                        ? 'black'
                        : part.highlight
                        ? AutocompleteHighlightColor
                        : 'inherit',
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </ListItem>
        );
      }}
      {...props}
    />
  );
}
