import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Pretendard } from '@/styles/fonts';
import { isIOS, isNativeApp } from '@/utils/userEnv';

type BoardDetailContentProps = {
  content: string;
};

const BoardDetailContent = (props: BoardDetailContentProps) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent(replaceContent(props.content));
  }, [props.content]);

  useEffect(() => {
    if (isNativeApp() && isIOS(window.navigator.userAgent)) {
      const buttons: NodeListOf<HTMLButtonElement> =
        document.querySelectorAll('button.iframe-link');

      buttons.forEach((button) => {
        const src = button.getAttribute('data-src') || '';
        button.onclick = () => {
          console.log('iframe 대신 새탭 링크', src);
          window.open(src, '_blank');
        };
      });
    }
  }, [content]);

  const replaceContent = (text: string) => {
    text = text
      .replace(/href/g, 'target="_blank" href')
      .replace(/100%px/g, '100%');

    // iOS 포털 앱에서 영상 첨부된 게시글 조회 시 리다이렉트 되는 문제 있음
    // https://fnf.atlassian.net/browse/PORTAL-5022
    // iframe 태그를 버튼 태그로 변경하고, 클릭 시 새탭으로 영상 재생
    if (isNativeApp() && isIOS(window.navigator.userAgent)) {
      text = replaceIframeWithButton(text);
    }

    return text;
  };

  const replaceIframeWithButton = (text: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const iframes = doc.querySelectorAll('iframe');

    iframes.forEach((iframe) => {
      if (iframe.parentElement) {
        iframe.parentElement.style.padding = '0px';
        iframe.parentElement.style.height = 'auto';
      }
      const iframeSrc = iframe.getAttribute('src') || '';

      const button = document.createElement('button');
      button.classList.add('iframe-link');
      button.textContent = '👉 동영상 재생';

      // data-src 속성에 iframe src 값 설정
      button.setAttribute('data-src', iframeSrc);

      // iframe 태그를 버튼으로 대체
      iframe.replaceWith(button);
    });

    return doc.body.innerHTML;
  };

  return (
    <Box
      className={Pretendard.className}
      sx={{
        paddingTop: '20px',
        '*': { maxWidth: '100% !important' },
        img: { objectFit: 'contain', height: 'auto' },
        'ol:not(.PlaygroundEditorTheme__ol1)': {
          'list-style': 'none',
          margin: 0,
          padding: 0,
        },
        'ul:not(.PlaygroundEditorTheme__ul)': {
          'list-style': 'none',
          margin: 0,
          padding: 0,
        },
        'button.iframe-link': {
          display: 'block',
          width: 'fit-content',
          padding: '10px 12px',
          border: '1px solid black',
          borderRadius: '6px',
          cursor: 'pointer',
          '&:hover': { backgroundColor: '#EEF4FC' },
        },
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default BoardDetailContent;
