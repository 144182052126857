import { useContext } from 'react';
import {
  SnackbarContext,
  SnackbarContextProps,
} from '@/components/CustomToast/CustomToastProvider';

const useCustomToast = (): SnackbarContextProps => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('CustomToastProvider가 설정되었는지 확인해주세요');
  }

  return context;
};

export default useCustomToast;
