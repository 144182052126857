import React from 'react';
import { Box } from '@mui/material';

import { MyPageMainProps } from 'components/MenuDrawer/MyMenuDrawer/V2/Main';

// TODO 알림 설정 조회 작업
const AlarmSettingContent = (props: MyPageMainProps) => {
  return (
    <Box>
      <div>알림 설정을 해보자아아아아</div>
    </Box>
  );
};

export default AlarmSettingContent;
