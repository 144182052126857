import React, { useEffect, useRef, useState } from 'react';
import BoardForm from 'components/Board/BoardForm';
import { BoardFormData } from '@/components/Board/BoardForm/@hooks/useBoardForm';
import useBoardFormProps, {
  BoardFormSettings,
} from '@/components/Board/BoardForm/@hooks/useBoardFormProps';
import { useGetEditDetailBoard } from '@/hooks/apiHooks';
import BoardDetailModal, {
  BoardDetailModalRef,
} from '@/components/Board/BoardDetailModal/BoardDetailModal';

type BoardEditProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  documentId?: string;
} & Pick<BoardFormSettings, 'onWriteComplete'>;

const BoardEdit: React.FC<
  BoardEditProps & Pick<BoardFormSettings, 'boardDetailModalRef'>
> = ({
  isModalOpen,
  closeModal,
  documentId,
  boardDetailModalRef,
  onWriteComplete,
}) => {
  const { data, isLoading } = useGetEditDetailBoard(false, documentId);
  const [boardData, setBoardData] = useState<BoardFormData>();

  const props = useBoardFormProps({
    boardData,
    mode: 'edit',
    isModalOpen,
    closeModal,
    boardDetailModalRef,
    onWriteComplete,
  });

  useEffect(() => {
    if (data && !boardData && !isLoading) {
      setBoardData(data);
    }
  }, [data, boardData, isLoading]);

  useEffect(() => {
    return () => {
      setBoardData(undefined);
    };
  }, []);

  return <BoardForm {...props} />;
};

const BoardEditWrapper = (props: BoardEditProps) => {
  const boardDetailModalRef = useRef<BoardDetailModalRef>(null);

  return (
    <>
      {props.isModalOpen ? (
        <BoardEdit {...props} boardDetailModalRef={boardDetailModalRef} />
      ) : null}
      <BoardDetailModal ref={boardDetailModalRef} />
    </>
  );
};

export default BoardEditWrapper;
