import { SubContentProps } from '../../LanguageSettingContent';
import { useSignatureData } from '@/hooks/apiHooks';
import { useCallback, useEffect } from 'react';
import useCustomToast from '@/hooks/useCustomToast';
import { copyToClipboard } from '@/utils';
import { FNCoCards, FNFCards, MailSignatureLink } from './constants';

const useMailSignatureProps = ({
  myInfo: { employeeNumber, isFnCo },
}: SubContentProps) => {
  const { data: signData, error } = useSignatureData(employeeNumber);
  const { showSuccess, showError } = useCustomToast();

  const copyButtonOnClick = useCallback((id: string) => {
    return () =>
      copyToClipboard(
        0,
        1,
        id,
        () => {
          showSuccess({
            message: '복사가 완료되었어요.',
            duration: 1000,
          });
        },
        () => {
          showError({
            message: '복사에 실패했어요.',
            duration: 1000,
          });
        },
      );
  }, []);

  useEffect(() => {
    if (error) {
      showError({
        message: `서명 정보를 가져오는데 실패했어요.\n${error.message}`,
      });
    }
  }, [error]);

  return {
    signData,
    copyButtonOnClick,
    signatureCards: isFnCo ? FNCoCards : FNFCards,
    mailLink: MailSignatureLink,
    showSlogan: isFnCo,
  };
};

export default useMailSignatureProps;
