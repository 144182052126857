import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Stack } from '@mui/material';
import CloseIcon from '@/public/images/icon/close_white_32.svg';
import { GlobalMenuGridItem, SectionTitle } from './styles';
import styled from '@emotion/styled';
import { GlobalMenuDrawerProps } from '../';
import { MenuData as MenuItem, MenuResponse } from '@/types/api';
import Menu from '../Menu';
import { PageURL } from '@/constant/pageURL';

const PCMenu = ({
  onClose,
  rawData,
}: Pick<GlobalMenuDrawerProps, 'onClose'> & {
  rawData?: MenuResponse;
}) => {
  const [menuData, setMenuData] = useState<MenuItem[][]>([]);

  useEffect(() => {
    if (rawData) {
      const groupByGridNumber = (data: MenuItem[]): MenuItem[][] => {
        const grouped: Record<number, MenuItem[]> = {};

        data.forEach((item) => {
          const gridNumber = item.gridNumber ?? 0;

          // 예외처리 - 'L.I.F.E'의 링크를 내부 소스 링크로 변경
          if (item.name === '총무' && item.childData?.length > 0) {
            item.childData.forEach((child) => {
              if (child.name === 'L.I.F.E') {
                child.link = PageURL.LIFE_HTML;
              }
            });
          }

          if (item.childData.length === 0) {
            item.childData = [
              {
                name: item.name,
                link: item.link,
                action: item.action,
                isDisplay: item.isDisplay,
                childData: [],
              },
            ];
          }

          if (!grouped[gridNumber]) {
            grouped[gridNumber] = [];
          }

          grouped[gridNumber].push(item);
        });

        return Object.values(grouped);
      };

      setMenuData(groupByGridNumber(rawData.data));
    }
  }, [rawData]);

  return (
    <Box sx={{ backgroundColor: 'black', color: 'white' }}>
      <Box sx={{ display: 'flex', padding: '12px 22px' }}>
        <Button
          className={'close'}
          onClick={onClose}
          sx={{
            minWidth: 'auto',
            marginLeft: 'auto',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
          }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Container maxWidth={'xl'} sx={{ paddingX: '0 !important' }}>
        <GlobalMenuGridContainer
          column={menuData.length || 1}
          row={menuData?.[0]?.length || 1}
        >
          {menuData.map((row, rowIndex) =>
            row.map((item, columnIndex) => (
              <GlobalMenuGridItem
                key={`${rowIndex}-${columnIndex}`}
                sm={12 / (12 - menuData.length)}
              >
                <SectionTitle>{item.name}</SectionTitle>
                <Stack gap={'16px'}>
                  {(item.childData ?? []).map((c) => (
                    <Menu
                      {...c}
                      key={c.name}
                      onClose={onClose}
                      isMobile={false}
                      isRn={false}
                    />
                  ))}
                </Stack>
              </GlobalMenuGridItem>
            )),
          )}
        </GlobalMenuGridContainer>
      </Container>
    </Box>
  );
};

export default PCMenu;

const GlobalMenuGridContainer = styled.section<{ column: number; row: number }>`
  max-width: 1380px;
  max-height: 80vh;
  display: grid;
  gap: 40px;
  margin: 0 auto;
  overflow-y: scroll;
  padding-bottom: 64px;

  grid-template-columns: repeat(${(el) => el.column}, 1fr);
  grid-template-rows: repeat(${(el) => el.row}, auto);
  grid-auto-flow: column;
`;
