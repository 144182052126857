import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import React from 'react';
import { ImageCropEditorProps } from '@/components/ImageCropEditor/useImageCropEditorProps';

const ImageCropEditor = ({
  previewImage: src,
  minHeight,
  minWidth,
  ...editorProps
}: ImageCropEditorProps) => {
  return (
    <ReactCrop {...editorProps} style={{ border: '3px solid #000' }}>
      {src && <img alt={'크롭할 이미지'} src={src} />}
    </ReactCrop>
  );
};

export default ImageCropEditor;
