// eslint-disable-next-line @typescript-eslint/no-empty-function
import * as Sentry from '@sentry/nextjs';
import { getCookie } from '@/utils/tokens';
import { USER_NAME } from '@/const/tokens';
import { Organization } from '@/types/api';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export const emptyFunction = () => {};

export const getRandom = () => {
  let result = '';
  const condition = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 6; i++) {
    result += condition.charAt(Math.floor(Math.random() * condition.length));
  }
  return result;
};

export const imgUrlChk = (url?: string) => {
  // [참고] 클라우드플레어 이미지 리사이즈 처리 가능(URL 으로 다양한 옵션을 부여)
  // https://developers.cloudflare.com/images/transform-images/transform-via-url/#fit
  return url
    ? url.includes('https') || url.includes('http')
      ? url
      : `https:${url}`
    : '';
};

export const sendSentry = (error: any, tokenType: string) => {
  const { url, method } = error.config;
  Sentry.setExtra('userId', getCookie(USER_NAME) ?? 'NO_LOGIN_ID');
  Sentry.setExtra('TokenType', tokenType);
  Sentry.setExtra('url', url);
  Sentry.setExtra('method', method);
  Sentry.captureException(error);
};

export const getLimitDay = () => {
  const today = new Date();
  const date = new Date(today.getTime() - 2 * 24 * 60 * 60 * 1000);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// export const findNameKorByOrgCode = (data: Organization, orgCode: string) => {
//   console.log(data);
//   if (data.orgCode === orgCode) {
//     return data.nameKor;
//   } else {
//     findNameKorByOrgCode(data.children, orgCode);
//   }
// };

export const findNameKorByOrgCode = (
  data: Organization,
  orgCode: string,
): string | null => {
  if (data.orgCode === orgCode) {
    return data.name;
  } else if (data.children) {
    for (let i = 0; i < data.children.length; i++) {
      const result = findNameKorByOrgCode(data.children[i], orgCode);
      if (result) {
        return result;
      }
    }
  }
  return null; // orgCode를 찾지 못한 경우
};

export const isDepartment = (data: Organization, orgCode: string): boolean => {
  if (data.orgCode === orgCode && data.children.length === 0) {
    return true;
  } else if (data.children) {
    for (let i = 0; i < data.children.length; i++) {
      const result = isDepartment(data.children[i], orgCode);
      if (result) {
        return result;
      }
    }
  }
  return false;
};

export const sendPostMessageForModal = ({
  boardNo,
  documentNo,
  category,
}: {
  boardNo: string;
  documentNo: number;
  category: string;
}) => {
  const sendData = JSON.stringify({
    actionType: 'MODAL',
    tabName: '',
    url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/V?n=${boardNo}&d=${documentNo}&c=${category}`,
  });
  window?.ReactNativeWebView?.postMessage(sendData);
};

export const sendBadgeCount = ({
  tabName,
  badgeCount,
}: {
  tabName: string;
  badgeCount: number;
}) => {
  const sendData = JSON.stringify({
    actionType: 'BADGE_UPDATE',
    tabName: tabName,
    badgeCount: badgeCount,
  });
  window?.ReactNativeWebView?.postMessage(sendData);
};

export const strDate = (dateData?: Date | string) => {
  if (!dateData) {
    return '-';
  }
  const { t } = useTranslation();
  const date = typeof dateData === 'string' ? new Date(dateData) : dateData;
  const now = new Date();

  const clearTime = (d: Date) =>
    new Date(d.getFullYear(), d.getMonth(), d.getDate());

  const diffInSeconds =
    (clearTime(now).getTime() - clearTime(date).getTime()) / 1000;

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;
  const secondsInMonth = 30 * secondsInDay;
  const secondsInYear = 12 * secondsInMonth;

  if (clearTime(now).getTime() === clearTime(date).getTime()) {
    return t('오늘');
  } else if (diffInSeconds < 31 * secondsInDay) {
    const days = Math.floor(diffInSeconds / secondsInDay);
    return `${days}${t('일 전')}`;
  } else if (diffInSeconds < secondsInYear) {
    const months = Math.floor(diffInSeconds / secondsInMonth);
    return `${months}${t('개월 전')}`;
  } else {
    const years = Math.floor(diffInSeconds / secondsInYear);
    return `${years}${t('년 전')}`;
  }
};

export const rnPermissionDeny = (errorCode?: number) => {
  if (window.isRNWebview) {
    const sendData = JSON.stringify({
      actionType: 'PERMISSION_DENIED',
      errorCode,
    });
    window?.ReactNativeWebView?.postMessage(sendData);
  }
};

export const tagToColor = (tag: string) => {
  switch (tag) {
    case 'HR팀용':
    case '근태':
    case '채용':
    case '신청':
      return {
        color: '#545E40',
        borderColor: 'rgba(131, 186, 15, 0.20)',
        background:
          'linear-gradient(0deg, rgba(131, 186, 15, 0.06) 0%, rgba(131, 186, 15, 0.06) 100%), #FFF',
      };
    case '발주':
    case '매장':
    case '거래처 신규/변경':
      return {
        color: '#6D445D',
        borderColor: 'rgba(217, 81, 163, 0.20)',
        background:
          'linear-gradient(0deg, rgba(217, 81, 163, 0.06) 0%, rgba(217, 81, 163, 0.06) 100%), #FFF',
      };
    case '보안':
    case '업무':
      return {
        color: 'rgba(74, 55, 115, 1)',
        borderColor: 'rgba(105, 75, 168, 0.20)',
        background:
          'linear-gradient(0deg, rgba(105, 75, 168, 0.06) 0%, rgba(105, 75, 168, 0.06) 100%), #FFF',
      };
    case '정산':
    case 'EAS':
      return {
        color: 'rgba(94, 73, 42, 1)',
        borderColor: 'rgba(213, 135, 19, 0.20)',
        background:
          'linear-gradient(0deg, rgba(213, 135, 19, 0.06) 0%, rgba(213, 135, 19, 0.06) 100%), #FFF',
      };
    default:
      return {
        color: '#4D5157',
        borderColor: 'rgba(172, 172, 172, 0.20)',
        background:
          'linear-gradient(0deg, rgba(172, 172, 172, 0.06) 0%, rgba(172, 172, 172, 0.06) 100%), #FFF',
      };
  }
};

export const strStatus = (status: string) => {
  const { t } = useTranslation();
  switch (status) {
    case 'R': //반려
    case 'D': //삭제
      return {
        title: t('반려'),
        color: '#F55060',
        borderColor: 'rgba(245, 80, 96, 0.24)',
        backgroundColor: 'rgba(245, 80, 96, 0.12)',
      };
    case 'C':
      return {
        title: t('완료'),
        color: '#43B16F',
        borderColor: 'rgba(67, 177, 111, 0.24)',
        backgroundColor: 'rgba(67, 177, 111, 0.12)',
      };
    case 'H':
      return {
        title: t('보류'),
        color: '#FEAE00',
        borderColor: 'rgba(254, 174, 0, 0.24)',
        backgroundColor: 'rgba(254, 174, 0, 0.12)',
      };
    case 'S':
      return {
        title: t('임시저장'),
        color: '#000000',
        borderColor: 'rgba(231, 233, 236, 1)',
        backgroundColor: 'rgba(231, 233, 236, 0.32)',
      };
    case 'P':
    default:
      return {
        title: t('진행중'),
        color: '#436FB1',
        borderColor: 'rgba(67, 111, 177, 0.24)',
        backgroundColor: 'rgba(67, 111, 177, 0.12)',
      };
  }
};

const NO_DIMS_URL = 'static-dashff.fnf.co.kr';

export const resizeItemImage = (url?: string) => {
  return url
    ? url.includes(NO_DIMS_URL)
      ? url
      : url + '/dims/resize/400'
    : '/images/markup.svg';
};

export const resizeImage = (url?: string) => {
  return url
    ? url.includes(NO_DIMS_URL)
      ? url
      : url + '/dims/resize/400'
    : '/images/image-error-gray.svg';
};

export const saleFormatString = (amount: number | string) => {
  return Math.floor(
    typeof amount === 'string' ? parseInt(amount) / 1000 : amount / 1000,
  ).toLocaleString();
};

export const saleFormatNumber = (amount: number | string) => {
  return Math.floor(
    typeof amount === 'string' ? parseInt(amount) / 1000 : amount / 1000,
  );
};

export const monthToString = (arg: string) => {
  return t(`${arg}월`);
};

export enum RnMessage {
  LOG_OUT = 'LOG_OUT',
  SET_SALES_DEFAULT_DATA = 'SET_SALES_DEFAULT_DATA',
  SET_SALES_INITIALS = 'SET_SALES_INITIALS',
  CHANGE_DEVELOPER_MODE = 'CHANGE_DEVELOPER_MODE',
  GET_VERSION_INFO = 'GET_VERSION_INFO',
  GET_APP_CONFIG = 'GET_APP_CONFIG',
  RESTART = `RESTART`,
}

export const sendToRN = (message: RnMessage, data?: any) => {
  switch (message) {
    default:
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ actionType: message.toString(), ...data }),
      );
      break;
  }
};

export const hoursAgo = (dateInput: string) => {
  // 입력받은 시간을 Date 객체로 변환
  const pastDate = new Date(dateInput);
  // 현재 시간
  const currentDate = new Date();

  // 두 시간의 차이를 밀리초로 계산
  const diff = currentDate.getTime() - pastDate.getTime();
  // 밀리초를 시간으로 변환 (1시간 = 3600000 밀리초)
  const hours = diff / 3600000;

  // 소수점 아래를 버림하여 정수로 표현
  const roundedHours = Math.floor(hours);

  // 결과 문자열 생성
  if (roundedHours <= 0) {
    return 'Updated Just Now';
  } else {
    return `Updated ${roundedHours} Hours Ago`;
  }
};
