import React, { useEffect } from 'react';
import { RemoteFile } from '@/components/file-thumbnail/utils';
import {
  ActionButton,
  Content,
  FileName,
  Wrapper,
} from '@/components/AttachmentFile/styles';
import FileIcon from '@/public/icons/file.svg';
import { Box, Link, Tooltip } from '@mui/material';

export type AttachmentFileProps<CustomFile> = {
  readonly file: CustomFile;
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly buttonOnClick: (file: CustomFile) => void;
  readonly buttonIcon?: React.ReactNode;
  readonly useLink?: boolean;
};

const AttachmentFile = <CustomFile extends RemoteFile>({
  file,
  loading,
  disabled,
  buttonOnClick,
  buttonIcon,
  useLink = false,
}: AttachmentFileProps<CustomFile>) => {
  const { link, name } = file;

  return (
    <Wrapper key={link}>
      <FileIcon width={'20px'} height={'20px'} />
      <Content>
        <Box flex={1} className={'ellipsis'}>
          {useLink ? (
            <Tooltip title={name} placement="bottom-start">
              <Link
                href={link}
                target={'_blank'}
                sx={{
                  fontSize: '14px',
                  color: 'black',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {name}
              </Link>
            </Tooltip>
          ) : (
            <FileName>{name}</FileName>
          )}
        </Box>
        {buttonOnClick && (
          <ActionButton
            onClick={() => buttonOnClick(file)}
            disabled={disabled || loading}
          >
            {buttonIcon}
          </ActionButton>
        )}
      </Content>
    </Wrapper>
  );
};

export default AttachmentFile;
