import styled from '@emotion/styled';

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0.5rem 0 0.5rem 0;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const StyledButton = styled.button`
  &:hover {
    text-decoration-line: underline;
  }
`;

StyledButton.defaultProps = {
  type: 'button',
};

const IconLayout = styled.button<{ newMessage: boolean }>`
  position: relative;
  height: calc(1.5em + 1.65rem + 2px);
  width: calc(1.5em + 1.65rem + 2px);
  border-radius: 0.42rem;
  transition: all 0.2s ease-in-out;
  .svg-inline--fa {
    color: ${({ theme, newMessage }) =>
      newMessage ? 'rgb(0, 102, 204)' : theme.colors.gray};
    --fa-secondary-opacity: ${({ newMessage }) => (newMessage ? 1 : 0.5)};
    --fa-primary-opacity: 1;
    font-size: 1.5rem;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
IconLayout.defaultProps = {
  type: 'button',
};

const NotiCountBadge = styled.div`
  position: absolute;
  top: 3px;
  left: 20px;
  min-width: 20px;
  background-color: #f55060;
  color: white;
  padding: 0 4px;
  z-index: 1;
  border-radius: 52px;
  font-size: 12px;
  line-height: 18px;
`;

const ContentWrapper = styled.div<{ mobileMode?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  overflow-y: scroll;
  max-height: ${({ mobileMode }) => (mobileMode ? '60vh' : '80vh')};
`;

const Layout = styled.div`
  padding: 1rem;
  width: 27rem;
  min-width: 300px;
  font-family: var(--font-Pretendard);
`;

const StyledNodata = styled.div`
  display: flex;
  width: 100%;
  height: 75vh;
  align-items: center;
  justify-content: center;
`;

export {
  NotiCountBadge,
  StyledHeader,
  StyledTitle,
  StyledButton,
  ContentWrapper,
  Layout,
  StyledNodata,
  IconLayout,
};
