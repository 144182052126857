import React, { useState } from 'react';
import { Button, Divider, Stack, SxProps, Typography } from '@mui/material';
import { CommentItem } from './styles';
import { imgUrlChk } from '@/utils/function';
import ImageWithErrorHandling from '@/components/ImageWithErrorHandling';
import { Theme } from '@mui/material/styles';
import EmptyIcon from '@/public/icons/empty.svg';
import LikeOnIcon from '@/public/images/icon/like_on_20.svg';
import LikeOffIcon from '@/public/images/icon/like_off_20.svg';
import ReadMoreIcon from '@/public/images/icon/read_more_24.svg';
import { useDeleteComment, useLikeComment } from '@/hooks/apiHooks';
import { useCustomDialog } from '@/hooks/useCustomDialog';
import { User } from '@/types/api';
import { ActionType } from '@/constant/button';
import PopMenu from '@/components/PopMenu';
import PopMenuItem from '@/components/PopMenu/PopMenuItem';
import ContentWithMention from '@/components/Comment/CommentList/ContentWithMentions';
import useCustomToast from '@/hooks/useCustomToast';

export type Comment = {
  id: string;
  commentGroupId: string;
  content: string;
  deletedBy: null | string;
  deletedAt: null | string;
  createdBy: User;
  createdAt: string;
  status: {
    isAnonymous: number;
    isTopHold: number;
  };
  feedback: {
    agree: any[];
    agreeCount: number;
    disagree: any[];
    disagreeCount: number;
    recommend: any[];
    recommendCount: number;
    isAgree: boolean;
    isDisagree: boolean;
    isRecommend: boolean;
  };
  customData: any[];
  isDeleteEnable: boolean;
};

type CommentListProps = {
  list: Comment[];
  sx?: SxProps<Theme>;
  onChanged?: (action: ActionType, commentId: string) => void;
};
const CommentList = (props: CommentListProps) => {
  const { list, sx, onChanged } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const dialog = useCustomDialog();
  const toast = useCustomToast();

  const toggleCommentLike = async (commentId: string, isLike: boolean) => {
    const result = await useLikeComment(commentId, isLike);
    if (result === true) {
      if (onChanged) onChanged(ActionType.LIKE, commentId);
    } else {
      toast.showError({
        message: result || '피드백 실패. 새로고침 후 다시 시도해 주세요.',
      });
    }
  };

  const deleteComment = () => {
    const commentId = menuAnchorEl?.dataset.commentId;
    if (!commentId) return;

    dialog.show({
      message: '댓글을 삭제하시겠습니까?',
      dismissButtonTitle: '취소',
      confirmButtonTitle: '삭제',
      onConfirm: async () => {
        const result = await useDeleteComment(commentId);
        if (result === true) {
          toast.show({
            severity: 'success',
            message: '댓글이 삭제되었습니다.',
          });
          if (onChanged) onChanged(ActionType.DELETE, commentId);
        } else {
          toast.showError({
            message: result || '삭제 실패. 새로고침 후 다시 시도해 주세요.',
          });
        }
      },
    });
    setMenuAnchorEl(null);
  };

  return (
    <Stack direction={'column'} gap={'24px'} sx={sx}>
      {(!Array.isArray(list) || list.length === 0) && (
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={'4px'}
          padding={'74px 0'}
        >
          <EmptyIcon width={'40px'} height={'40px'} />
          <Typography textAlign={'center'} fontSize={'14px'} color={'#B7BBC2'}>
            작성된 댓글이 없어요.
            <br />
            첫번째 댓글을 남겨보세요!
          </Typography>
        </Stack>
      )}
      {list.map((item) => (
        <CommentItem key={item.id} gap={'16px'}>
          <ImageWithErrorHandling
            src={imgUrlChk(item.createdBy.profileImageUrl || '')}
            width={27}
            height={27}
            alt={'profile-image'}
            className={'profile-image'}
          />
          <Stack
            direction={'column'}
            spacing={'8px'}
            sx={{ width: 'calc(100% - 27px - 16px)' }}
          >
            <p className={'name'}>
              {item.createdBy.name} {item.createdBy.positionName}
            </p>
            <ContentWithMention content={item.content} />
            <Stack
              direction={'row'}
              justifyContent={'start'}
              alignItems={'center'}
            >
              <Button
                className={'like'}
                disableRipple
                onClick={() => {
                  toggleCommentLike(item.id, !item.feedback.isRecommend);
                }}
              >
                {item.feedback.isRecommend ? <LikeOnIcon /> : <LikeOffIcon />}
                <span className={'likeCount'}>
                  {item.feedback.recommendCount}
                </span>
              </Button>
              <Divider
                orientation={'vertical'}
                variant={'middle'}
                flexItem
                sx={{
                  height: '12px',
                  margin: '0 8px',
                  alignSelf: 'auto',
                }}
              />
              <span className={'createdAt'}>{item.createdAt}</span>
            </Stack>
          </Stack>
          {item.isDeleteEnable && (
            <Button
              data-comment-id={item.id}
              aria-controls={isMenuOpen ? 'basic-menu' : undefined}
              aria-haspopup={'true'}
              aria-expanded={isMenuOpen ? 'true' : undefined}
              className={'myCommentMenu'}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setMenuAnchorEl(event.currentTarget);
              }}
            >
              <ReadMoreIcon />
            </Button>
          )}
        </CommentItem>
      ))}
      <PopMenu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{ 'aria-labelledby': 'menu-button' }}
      >
        <PopMenuItem type={ActionType.DELETE} onClick={() => deleteComment()}>
          삭제
        </PopMenuItem>
      </PopMenu>
    </Stack>
  );
};

export default CommentList;
