import { useState } from 'react';
import { MyMenuContent } from '@/constant/mymenu';
import { useRecoilState } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { MyMenuDrawerProps } from '@/components/MenuDrawer/MyMenuDrawer/V2';
import useMainProps from './useMainProps';

export type MyMenuDrawerSettings = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

const useMyMenuDrawerProps = ({
  isOpen,
  onClose,
}: MyMenuDrawerSettings): MyMenuDrawerProps => {
  const [currSub, setCurrSub] =
    useState<MyMenuDrawerProps['currSubMenu']>(null);

  const [myInfo] = useRecoilState(MyInfoState);

  const menuOnClose = () => {
    onClose();
    closeSubMenu();
  };

  const openSubMenu = (id: MyMenuContent) => {
    if (!id || id === currSub) return;
    setCurrSub(id);
  };

  const closeSubMenu = (id?: MyMenuContent) => {
    if (id && id !== currSub) return;
    setCurrSub(null);
  };

  const subMenuOnClose = () => {
    setCurrSub(null);
  };

  return {
    isOpen,
    menuOnClose,
    currSubMenu: currSub,
    subMenuOnClose,
    openSubMenu,
    closeSubMenu,
    myInfo,
    mainProps: useMainProps({ myInfo, onClose, openSubMenu }),
  };
};

export default useMyMenuDrawerProps;
