import { useCallback, useEffect, useState } from 'react';
import {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
  ReactCropProps,
} from 'react-image-crop';
import {
  calculateCenteredCrop,
  getCroppedImgFromOriginal,
  imgSrcToImage,
  resizeImage,
} from '@/components/ImageCropEditor/index.util';

type ImageCropEditorSettings = Omit<
  ReactCropProps,
  'onChange' | 'crop' | 'onComplete'
> & {
  readonly src: string;
  readonly cropCanvasSize: number;
  readonly handleOnError?: (e: unknown) => void;
};

export type ImageCropEditorProps = ReactCropProps & {
  readonly isMinimumReached: boolean;
  readonly getCroppedImage: () => string;
  readonly previewImage: string;
  readonly completedCrop?: PixelCrop;
  readonly selectedImageSize: { width: number; height: number };
};

const useImageCropEditorProps = (
  props: ImageCropEditorSettings,
): ImageCropEditorProps => {
  const {
    minWidth,
    minHeight,
    src,
    aspect = 1,
    cropCanvasSize,
    handleOnError,
  } = props;

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const [previewImage, setPreviewImage] = useState('');
  const [resizeScale, setResizeScale] = useState(1);
  const [isMinimumReached, setIsMinimumReached] = useState(false);

  const [selectedImageSize, setSelectedImageSize] = useState({
    width: 0,
    height: 0,
  });

  const [originalImageElement, setOriginalImageElement] =
    useState<HTMLImageElement>();

  const getCroppedImage = useCallback(() => {
    if (!completedCrop || !originalImageElement) return '';

    return getCroppedImgFromOriginal(
      originalImageElement,
      completedCrop,
      resizeScale,
    );
  }, [originalImageElement, completedCrop, resizeScale]);

  useEffect(() => {
    if (!src) {
      return;
    }

    const img = imgSrcToImage(src);

    img.onload = () => {
      const { width, height } = img;
      const scale = cropCanvasSize / width;
      setResizeScale(scale);
      setPreviewImage(resizeImage(img, scale));
      setOriginalImageElement(img);

      const initialCrop = centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 100,
          },
          aspect,
          width,
          height,
        ),
        width,
        height,
      );

      setCrop(initialCrop);

      setCompletedCrop({
        unit: 'px',
        ...calculateCenteredCrop(img.width * scale, img.height * scale, aspect),
      });

      const initialSetting = validate(
        calculateCenteredCrop(img.width, img.height, aspect),
        1,
        minWidth,
        minHeight,
      );

      setSelectedImageSize({
        width: initialSetting.width,
        height: initialSetting.height,
      });

      setIsMinimumReached(initialSetting.isMinimumReached);
    };

    if (handleOnError) {
      img.onerror = handleOnError;
    }
  }, [src, aspect, cropCanvasSize, handleOnError]);

  return {
    ...props,
    crop,
    getCroppedImage,
    isMinimumReached,
    onChange: useCallback((crop) => setCrop(crop), []),
    onComplete: (crop, percentageCrop) => {
      setCompletedCrop(crop);

      const { width, height, isMinimumReached } = validate(
        crop,
        resizeScale,
        minWidth,
        minHeight,
      );
      setSelectedImageSize({
        width,
        height,
      });
      setIsMinimumReached(isMinimumReached);
    },
    previewImage,
    completedCrop,
    selectedImageSize,
  };
};

export default useImageCropEditorProps;

const validate = (
  crop: Pick<PixelCrop, 'width' | 'height'>,
  scale: number,
  minWidth?: number,
  minHeight?: number,
): { width: number; height: number; isMinimumReached: boolean } => {
  const resizedWidth = parseInt(`${crop.width / scale}`);
  const resizedHeight = parseInt(`${crop.height / scale}`);
  const isMinimumReached =
    minWidth && minHeight
      ? resizedWidth < minWidth || resizedHeight < minHeight
      : false;
  return {
    width: resizedWidth,
    height: resizedHeight,
    isMinimumReached,
  };
};
