import React from 'react';
import { Box } from '@mui/material';
import CustomTooltip from '@/components/CustomTooltip';

type TooltipProps = {
  readonly title: React.ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ title }) => {
  return (
    <CustomTooltip title={title} style={{ marginLeft: '8px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon />
      </Box>
    </CustomTooltip>
  );
};

export default Tooltip;

const Icon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="6.5"
      stroke="#B7BBC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="10.0078"
      y="7.11816"
      width="0.00833333"
      height="0.00833333"
      transform="rotate(-180 10.0078 7.11816)"
      stroke="#B7BBC2"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0078 10.0068L10.0078 12.8957"
      stroke="#B7BBC2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
