import { uuidv4 } from '@firebase/util';
import {
  ExtendFile,
  uploadImage,
  validateFile,
} from '@/components/file-thumbnail/utils';
import React, { useState } from 'react';
import UploadMultiFile, {
  UploadMultiFileProps,
} from '@/components/Upload/UploadMultiFile';

import useCustomToast from '@/hooks/useCustomToast';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

type P = {
  value: ExtendFile[];
  setValue: React.Dispatch<React.SetStateAction<ExtendFile[]>>;
  isOnlyImage?: boolean;
  maxSize?: number;
  maxLength?: number;
  upload?: boolean;
  sortable?: boolean;
} & Pick<UploadMultiFileProps, 'previewType' | 'useAddButton' | 'placeHolder'>;

export default function MultiFileUploader(props: P) {
  const {
    value,
    setValue,
    isOnlyImage = true,
    maxSize,
    maxLength,
    previewType,
    useAddButton,
    placeHolder,
    upload,
    sortable,
  } = props;

  const [isUpLoading, setIsUpLoading] = useState(false);
  const { showError } = useCustomToast();
  const [activeFile, setActiveFile] = useState<ExtendFile | null>(null);

  const handleAdd = (acceptedFiles: File[]) => {
    try {
      validateFile(acceptedFiles, {
        maxSize,
        isOnlyImage,
        maxLength,
        existFileLength: value.length,
      });
    } catch (e) {
      if (e instanceof Error) {
        return showError({ message: e.message });
      } else {
        return showError({
          message: '파일 첨부에 에러가 발생했어요. 다시 시도해주세요',
        });
      }
    }

    const newItems = acceptedFiles.map((file: File) => {
      const extendFile: ExtendFile = {
        ...file,
        isUploaded: false,
        id: uuidv4(),
        preview: URL.createObjectURL(file),
        status: 'new',
        file,
      };

      return extendFile;
    });

    if (!upload) {
      setValue([...value, ...newItems]);
      return;
    }

    setIsUpLoading(true);
    Promise.all(
      newItems.map(async (item) => {
        try {
          const { file } = item;
          const uploadedFile = await uploadImage(file!);
          return {
            ...item,
            isUploaded: true,
            file: undefined,
            path: uploadedFile.url,
            preview: uploadedFile.url,
            name: uploadedFile.name,
          };
        } catch (error) {
          showError({
            message: (
              <>
                파일 첨부에 실패했어요. 다시 시도해 주세요.
                <br />
                계속 오류가 발생할 경우 IT Service를 통해 오류를 접수해 주세요.
              </>
            ),
          });
        }
      }),
    )
      .then((results) => {
        const uploadedItems: ExtendFile[] = [];
        results.forEach((item) => {
          if (item) {
            uploadedItems.push(item as ExtendFile);
          }
        });
        setValue([...value, ...uploadedItems]);
      })
      .finally(() => {
        setIsUpLoading(false);
      });
  };

  const handleRemove = (file: ExtendFile) => {
    let files = value;
    const search = files.filter((f) => f == file);
    if (search?.length) {
      if (search[0].status === 'new') {
        files = files.filter((f) => f !== file);
      } else {
        files.forEach((_file) => {
          if (_file == file) {
            _file.status = 'deleted';
          }
        });
      }
    }

    setValue([...files]);
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    const search = value.filter((item) => item.id === active.id);
    if (search && search.length > 0) {
      setActiveFile(search[0]);
    }
  };

  const handleDragEnd = ({ over }: DragEndEvent) => {
    if (over && activeFile) {
      const activeIndex = value.findIndex((item) => item.id === activeFile.id);
      const overIndex = value.findIndex((item) => item.id === over.id);
      setValue(arrayMove(value, activeIndex, overIndex));
    }

    setActiveFile(null);
  };

  const handleDragCancel = () => {
    setActiveFile(null);
  };

  return (
    <UploadMultiFile
      files={value}
      onDrop={handleAdd}
      useAddButton={useAddButton}
      previewType={previewType}
      placeHolder={placeHolder}
      error={false}
      dragPreviewProps={{
        onDragEnd: handleDragEnd,
        onDragStart: handleDragStart,
        onDragCancel: handleDragCancel,
        onRemove: handleRemove,
        activeFile: activeFile,
      }}
      loading={isUpLoading}
      sortable={sortable}
    />
  );
}
