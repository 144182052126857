const AutocompleteSelectStyle = {
  height: 'auto',
  borderRadius: '6px',
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    borderWidth: '1px',
  },
  "& button[title='Clear']": {
    visibility: 'visible',
  },
  '& .MuiAutocomplete-noOptions': {
    color: '#B7BBC2',
    fontSize: '14px',
  },
  '& .MuiAutocomplete-endAdornment': {
    zIndex: 1,
    marginRight: '-1px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '7.5px 30px 7.5px 3px',
  },
};

const AutocompleteTextFieldStyle = {
  paddingTop: '4px',
  paddingBottom: 0,
  height: '44px',
  fontSize: '14px',
  lineHeight: '28px',
  paddingLeft: '8px',
  '&.MuiOutlinedInput-root,&.MuiInput-root:before': {
    paddingLeft: '11px',
    borderWidth: '0px !important',
    background: '#fff',
    borderRadius: '6px',
  },
  '&.MuiInput-root:after': {
    borderColor: 'black',
    borderWidth: '1px',
  },
  '& .MuiInputBase-input': {
    minWidth: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E7E9EC',
    borderWidth: '1px',
  },
  '&.Mui-Error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F55060',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    borderWidth: '1px',
  },
  '&.Mui-disabled': {
    background: '#F9FAFB',
    borderColor: '#E7E9EC',
    color: '#ff0',
  },
};

const MuitlAutocompleteTextFieldStyle = {
  '&.MuiOutlinedInput-root': {
    display: 'flex',
    transform: 'translateZ(0)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    rowGap: '4px',
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '12px',
    height: 'auto',
    minHeight: '44px',
    fontSize: '14px',
    lineHeight: '28px',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
      borderWidth: '1px',
    },

    '& .MuiAutocomplete-input': {
      display: 'inline-block !important',
      width: 'auto',
      position: 'relative',
      paddingLeft: '4px',
    },
    '&.Mui-disabled': {
      background: '#F9FAFB',
      borderColor: '#E7E9EC',
      color: '#ff0',
    },
  },
};

const AutocompletePaperStyle = {
  boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
  // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  border: '1px solid #000',
  borderRadius: 6,
  marginTop: 4,
  padding: '8px 16px',
};

const AutocompleteListItemStyle = {
  fontSize: '14px',
  color: '#B7BBC2',
  background: 'transparent',
  borderRadius: '4px',
  marginBottom: '12px',
  '&.MuiAutocomplete-option': {
    padding: '4px',
    fontSize: '14px',
    lineHeight: '20px',
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
  '&:focus': {
    background: '#F3F5F6',
  },
  '&:hover': {
    color: '#B7BBC2',
    backgroundColor: '#F3F5F6',
  },
  '&.MuiAutocomplete-option.Mui-focused': {
    background: '#F3F5F6',
  },
  '&.MuiAutocomplete-option.Mui-selected': {
    background: '#F3F5F6',
    fontWeight: 400,
    color: 'black',
    '&:hover': {
      backgroundColor: '#F3F5F6',
    },
  },
  '&.MuiAutocomplete-option[aria-selected="true"]': {
    '&.Mui-focused': {
      background: '#F3F5F6',
    },
    background: 'transparent',
  },
  '&.Mui-disabled': {
    background: '#F9FAFB',
    borderColor: '#E7E9EC',
    color: '#D1D8E2',
  },
};

const AutocompleteSelectedText = {
  background: 'black',
  color: 'white',
  display: 'inline-block',
  padding: '4px 10px 4px 0',
  borderRadius: '5px',
  fontSize: '14px',
  lineHeight: '20px',
  maxWidth: '100%',
  margin: '0 8px 0 0 !important',
  height: 'auto',
  cursor: 'default',
  '&:hover': {
    background: 'black',
  },
  '& .MuiChip-label': {
    paddingLeft: '10px',
    paddingRight: '0px',
  },
  '& .MuiChip-deleteIcon': {
    marginRight: '4px',
    marginLeft: '4px',
  },
};

const AutocompleteHighlightColor = '#0066CC';

export {
  AutocompleteSelectStyle,
  AutocompleteTextFieldStyle,
  MuitlAutocompleteTextFieldStyle,
  AutocompletePaperStyle,
  AutocompleteListItemStyle,
  AutocompleteSelectedText,
  AutocompleteHighlightColor,
};
