import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ImageSelectorOption, {
  ImageOptionSelectorProps,
} from 'components/ImageOptionSelector'; // Update the import path as needed

export type RHFImageOptionSelectorProps<ValueType> = Pick<
  ImageOptionSelectorProps<ValueType>,
  'options'
> & {
  readonly name: string;
};

function RHFImageOptionSelector<ValueType = string>({
  name,
  options,
}: RHFImageOptionSelectorProps<ValueType>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ImageSelectorOption<ValueType> options={options} field={field} />
      )}
    />
  );
}

export default RHFImageOptionSelector;
