import React from 'react';
import { useSignatureData } from '@/hooks/apiHooks';
import {
  CardLayout,
  StyledLink,
  StyledOrganization,
  StyledP,
  StyledP2,
  StyledP3,
  StyledP4,
  StyledSpan,
  StyledTable,
  StyledTd,
  StyledTd2,
} from '@/components/Profile/SecondItem/MailSignature/SignatureItem/styles';
import CopyBanner from '@/components/Profile/SecondItem/MailSignature/CopyBanner';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { Skeleton } from '@mui/material';
import { imgUrlChk } from '@/utils/function';
import { SignatureCard } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/MailSignature/@hooks/constants';

export const SignatureItem = ({
  title,
  color,
  hasLogo: logo,
}: SignatureCard) => {
  const { employeeNumber } = useRecoilValue(MyInfoState);
  const { data: signData, isLoading } = useSignatureData(employeeNumber);
  const idName = `email_signature_${color}_${logo}`;

  if (isLoading) {
    return (
      <Skeleton
        style={{
          margin: '0 auto',
          height: '25rem',
          width: '80%',
        }}
      />
    );
  }
  return (
    <>
      <CopyBanner title={title} id={idName} />
      <CardLayout id={idName}>
        <StyledTable color={color} cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <StyledTd>
                <table cellPadding="0" cellSpacing="0">
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        {signData?.name}
                        <span style={{ color: '#f4ede8' }}>
                          {' '}
                          {signData?.nickName}
                        </span>
                        <StyledOrganization>
                          {signData?.displayOrganizationShortName}
                        </StyledOrganization>
                      </td>
                    </tr>
                    <tr>
                      <td width="110" style={{ verticalAlign: 'bottom' }}>
                        {logo && (
                          <img
                            src={imgUrlChk(signData?.companyLogoImageUrl)}
                            style={{ height: '31px' }}
                          />
                        )}
                      </td>
                      <StyledTd2>
                        <StyledP>
                          <StyledLink href={'mailto:' + signData?.email}>
                            {signData?.email}
                          </StyledLink>
                        </StyledP>
                        {signData?.insidePhone && (
                          <StyledP>
                            <StyledLink href={'tel:' + signData.insidePhone}>
                              {signData.insidePhone}
                            </StyledLink>
                          </StyledP>
                        )}
                        <StyledP2>
                          <StyledLink href={'tel:' + signData?.mobilePhone}>
                            {signData?.mobilePhone}
                          </StyledLink>
                        </StyledP2>
                        <StyledP3>
                          <StyledLink
                            href={
                              signData?.homePageUrl.includes('http')
                                ? signData?.homePageUrl
                                : `https://${signData?.homePageUrl}`
                            }
                          >
                            {signData?.homePageUrl}
                          </StyledLink>
                        </StyledP3>
                        <StyledP4>
                          <StyledSpan>{signData?.companyAddress}</StyledSpan>
                        </StyledP4>
                      </StyledTd2>
                    </tr>
                  </tbody>
                </table>
              </StyledTd>
            </tr>
          </tbody>
        </StyledTable>
      </CardLayout>
    </>
  );
};
