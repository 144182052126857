import { Theme } from '@mui/material/styles';
import Checkbox from './Checkbox';
import Menu from './Menu';
import IconButton from './IconButton';
import Button from './Button';
import Select from './Select';
import Divider from './Divider';
import TextField from './TextField';
import Chip from './Chip';
import Table from './Table';
import Radio from './Radio';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Checkbox(theme),
    Menu(theme),
    IconButton(theme),
    Button(theme),
    Select(theme),
    Divider(theme),
    TextField(theme),
    Chip(theme),
    Table(theme),
    Radio(theme),
  );
}
