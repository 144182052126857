import React from 'react';
import Popper from '@mui/material/Popper';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { imgUrlChk } from '@/utils/function';
import ImageWithErrorHandling from '@/components/ImageWithErrorHandling';
import { useFindPassport } from '@/hooks/apiHooks';
import MsTeamsIcon from '@/public/images/icon/ms_teams.svg';
import { UserProfilePopperZIndex } from '@/constant/z-index';
import { copyClipBoardText } from '@/utils';
import useCustomToast from '@/hooks/useCustomToast';
import useLayoutSize from '@/hooks/useLayoutSize';

type UserProfilePopperProps = {
  anchorEl: HTMLElement | null;
  passportId: string;
  onClickAway?: (event: MouseEvent | TouchEvent) => void;
};

const UserProfilePopper = (props: UserProfilePopperProps) => {
  const { isMobile } = useLayoutSize();
  const { anchorEl, passportId, onClickAway } = props;
  const open = Boolean(anchorEl);
  const { data, isLoading } = useFindPassport(passportId);
  const toast = useCustomToast();

  const copyEmail = async () => {
    if (!data?.detail.email) return;
    const resultCopy = await copyClipBoardText(data.detail.email);
    if (resultCopy) {
      toast.show({
        severity: 'success',
        message: '이메일이 복사되었습니다.',
        duration: 3000,
      });
    }
  };

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        onClickAway?.(event);
      }}
    >
      <Popper
        id={`user-profile-${passportId}`}
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-start'}
        disablePortal={true}
        modifiers={[
          {
            name: 'preventOverflow',
            options: { padding: 10 },
          },
        ]}
        sx={{ zIndex: UserProfilePopperZIndex }}
      >
        <Box
          sx={{
            width: isMobile ? 'auto' : '284px',
            minWidth: isMobile ? '160px' : '284px',
            maxWidth: isMobile ? '200px' : '284px',
            padding: isMobile ? '16px 12px' : '16px',
            borderRadius: '7px',
            boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'white',
            '.profile-image': {
              width: isMobile ? '80px' : '160px',
              height: isMobile ? '80px' : '160px',
              margin: '0 auto',
              borderRadius: '100%',
              objectFit: 'cover',
            },
          }}
        >
          {!data ? (
            <Typography fontSize={'14px'}>
              {isLoading && '불러오는 중'}
              {!isLoading && (
                <>
                  프로필 카드가 없습니다.
                  <br />
                  휴직 혹은 퇴사자일 수 있습니다.
                </>
              )}
            </Typography>
          ) : (
            <Stack direction={'column'} spacing={isMobile ? '8px' : '14px'}>
              <ImageWithErrorHandling
                src={imgUrlChk(data.detail.profileImageUrl)}
                width={90}
                height={90}
                alt={'profile-image'}
                className={'profile-image'}
              />
              <Stack
                direction={'column'}
                spacing={'2px'}
                sx={{
                  '> p': {
                    textAlign: isMobile ? 'center' : 'left',
                  },
                  '.name': {
                    fontSize: isMobile ? '16px' : '21px',
                    span: {
                      display: 'inline-block',
                      marginLeft: '2px',
                      fontSize: '14px',
                      color: '#B5B5C3',
                    },
                  },
                  '.nickname': {
                    fontSize: isMobile ? '12px' : '14px',
                    color: '#888D96',
                  },
                }}
              >
                <p className={'name ellipsis-line2'}>
                  {data.detail.name}
                  <span>{data.detail.positionName}</span>
                </p>
                {data.detail.nickname && (
                  <p className={'nickname ellipsis'}>{data.detail.nickname}</p>
                )}
              </Stack>
              <Stack
                direction={'column'}
                spacing={isMobile ? '2px' : '4px'}
                sx={{
                  p: {
                    textAlign: isMobile ? 'center' : 'left',
                    fontSize: isMobile ? '12px' : '12.6px',
                    color: 'black',
                  },
                  '.email': { color: '#3699FF' },
                }}
              >
                <p className={'companyName ellipsis'}>
                  {data.detail.companyName}
                </p>
                <p className={'departmentName ellipsis'}>
                  {data.detail.divisionName} {data.detail.departmentName}
                </p>
                {!isMobile && (
                  <>
                    <Stack
                      component={'p'}
                      direction={'row'}
                      alignItems={'center'}
                      flexWrap={'wrap'}
                      divider={
                        <Divider
                          orientation={'vertical'}
                          sx={{ height: '12px', marginX: '8px' }}
                        />
                      }
                      className={'email'}
                    >
                      <Button
                        sx={{
                          padding: '0 1px',
                          textAlign: 'start',
                          maxWidth: 'calc(100% - 36px)',
                          textOverflow: 'ellipsis',
                          wordWrap: 'break-word',
                          overflow: 'hidden',
                          color: 'black',
                          '&::after': {
                            content: '""',
                            width: '20px',
                            height: '20px',
                            marginLeft: '2px',
                            backgroundImage: 'url(/icons/copy-2.svg)',
                          },
                        }}
                        onClick={copyEmail}
                      >
                        {data.detail.email}
                      </Button>
                      <a
                        href={`https://teams.microsoft.com/l/chat/0/0?users=${data.detail.email}`}
                        target={'_blank'}
                        rel="noreferrer"
                        style={{ height: '16px' }}
                      >
                        <MsTeamsIcon />
                      </a>
                    </Stack>
                    <p className={'phoneInside'}>
                      <a href={`tel:${data.detail.phoneInside}`}>
                        {data.detail.phoneInside}
                      </a>
                    </p>
                    <p className={'phoneMobile'}>
                      <a href={`tel:${data.detail.phoneMobile}`}>
                        {data.detail.phoneMobile}
                      </a>
                    </p>
                  </>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default UserProfilePopper;
