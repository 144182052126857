import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import { $getRoot, $insertNodes } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';

const LoadInitialContent = ({
  initialContent,
}: {
  initialContent?: string;
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!initialContent) {
      return;
    }
    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialContent, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      $getRoot().clear();
      $insertNodes(nodes);
    });
  }, []);

  return null;
};

export default LoadInitialContent;
