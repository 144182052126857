export enum PostMessageActionType {
  NAVI_OPEN = 'NAVI_OPEN',
  NAVI_CLOSE = 'NAVI_CLOSE',
  MOVE_TAB = 'MOVE_TAB',
}

export const sendPostMessage = (
  actionType: PostMessageActionType,
  values?: { [key in string]: string },
) => {
  if (!window) return;

  try {
    const jsonValue = JSON.stringify({
      actionType,
      ...values,
    });
    window.ReactNativeWebView?.postMessage(jsonValue);
  } catch (e) {
    console.error('[sendPostMessage]', e);
  }
};
