import React from 'react';
import { DialogContext } from '@/components/CustomDialog/CustomDialogProvider';

export const useCustomDialog = () => {
  const dialog = React.useContext(DialogContext);

  if (!dialog) {
    throw new Error('useConfirmDialog must be used within a DialogProvider');
  }

  return dialog;
};
