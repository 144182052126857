import { createApiClient } from '@/utils/api/ApiDefault';
import { PortalToken } from '@/types/api';
import { getCookie, setCookie } from '@/utils/tokens';
import {
  DISPLAY_TYPE,
  JWT_ACCESS_TOKEN,
  JWT_REFRESH_TOKEN,
} from '@/const/tokens';
import axios from 'axios';
import { emptyFunction, rnPermissionDeny, sendSentry } from '@/utils/function';

const axiosInstance = createApiClient();

export const API_TIMEOUT = 8000;

axiosInstance.defaults.timeout = API_TIMEOUT;
// 타임아웃 에러 처리

axiosInstance.interceptors.request.use(async (config) => {
  // NOTE: 요청 전달되기 전 작업 처리
  const extraAuthorization = config.headers.Authorization;

  if (extraAuthorization) {
    if (process.env.NODE_ENV === 'development')
      console.log(`👉 Extra Auth 존재`);
  } else {
    const accessToken = getCookie(JWT_ACCESS_TOKEN);
    if (accessToken && accessToken !== 'undefined')
      config.headers.Authorization = `Bearer ${getCookie(JWT_ACCESS_TOKEN)}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  // NOTE: 응답 200번대 status일 때 응답 성공 직전 호출
  // NOTE: 이 작업 이후 .then()으로 이어진다
  (response) => response,
  // NOTE: 응답 200번대가 아닌 status일 때 응답 에러 직전 호출
  // NOTE: 이 작업 이후 .catch()로 이어진다
  async (err) => {
    const originalRequest = err.config;

    if (err.code === 'ECONNABORTED') {
      console.log('Request timeout: 8 seconds');
      sendSentry(err, 'Timeout'); // Sentry 로깅
      return Promise.reject(err);
    }

    const status = err?.response?.status;
    const code = err?.response?.data?.code;

    if (status === 401 || (code === '2002' && !originalRequest._retry)) {
      const refreshToken = getCookie(JWT_REFRESH_TOKEN);

      if (!refreshToken || refreshToken === 'undefined') {
        window.isRNWebview
          ? rnPermissionDeny(3)
          : (window.location.href = '/login');

        // Promise chaining 끊기
        return new Promise(emptyFunction);
      }

      originalRequest._retry = true;
      const token = await axios
        .post<PortalToken>(
          `${process.env.NEXT_PUBLIC_API_URL}/api/auth/portal/token`,
          {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
          },
        )
        .then((res) => res.data.data);

      const {
        access_token,
        refresh_token,
        token_type,
        refresh_expires_in,
        expires_in,
      } = token;

      setCookie(JWT_ACCESS_TOKEN, access_token, expires_in);
      setCookie(JWT_REFRESH_TOKEN, refresh_token, refresh_expires_in);
      setCookie(DISPLAY_TYPE, 'image', expires_in);

      originalRequest.headers.authorization = `Bearer ${access_token}`;

      return axios(originalRequest);
    }

    if (status < 400 || status >= 500) {
      sendSentry(err, 'JWT');
    }
    return Promise.reject(err);
  },
);

export { axiosInstance as jwtInstance };
