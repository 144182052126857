import React from 'react';
import { Divider, Skeleton, Stack } from '@mui/material';

import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';
import { OutlookLink } from './style';
import useMailSignatureProps from './@hooks/useMailSignatureProps';
import { SubContentProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSettingContent';
import SignatureCard from './SignatureCard';
import SloganCard from './SignatureCard/SloganCard';

const MarginTop = `${32 - 24}px`;

const MailSignature = (props: SubContentProps) => {
  const { signData, copyButtonOnClick, mailLink, signatureCards, showSlogan } =
    useMailSignatureProps(props);

  return (
    <Stack sx={{ padding: '28px 20px 80px' }}>
      <ContentDescription>
        원하시는 서명을 아래에서 복사 후 Outlook에서 설정해 사용하세요.
      </ContentDescription>

      <OutlookLink href={mailLink} target="_blank">
        <img src={'/icons/mypage/outlook.png'} alt={'outlook icon'} />
        Outlook에서 설정하기
      </OutlookLink>

      <Stack gap={'24px'} mt={'28px'}>
        <Divider />
        {signData ? (
          <>
            {signatureCards.map((item, index) => (
              <SignatureCard
                key={`${index}`}
                {...item}
                data={signData}
                copyButtonOnClick={copyButtonOnClick}
                wrapperStyle={{ marginTop: index > 0 ? MarginTop : 0 }}
              />
            ))}
            {showSlogan && (
              <SloganCard
                marginTop={MarginTop}
                copyButtonOnClick={copyButtonOnClick}
              />
            )}
          </>
        ) : (
          <>
            <Skeleton width={'100%'} height={'180px'} animation={'wave'} />
            <Skeleton
              width={'100%'}
              height={'180px'}
              animation={'wave'}
              sx={{ marginTop: MarginTop }}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default MailSignature;
