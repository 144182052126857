import { LanguageSettingContentProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSettingContent';
import React, { useState } from 'react';
import { Language, LanguageValue, MyInfo } from '@/types/api';

const useLanguageSettingContentProps = ({
  myInfo,
}: {
  myInfo: MyInfo;
}): LanguageSettingContentProps => {
  const { language = 'ko' } = myInfo;
  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageValue>(language);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as LanguageValue;
    console.log('[JM] value', value);
    setSelectedLanguage(value);
    // TODO JM 언어 변경 api 후 toast
  };

  return {
    language,
    selectedLanguage,
    handleChange,
    selectOptions: Object.keys(Language)
      .filter((item) => item !== 'default')
      .map((item) => {
        return {
          value: item,
          label: Language[item as LanguageValue],
        };
      }),
  };
};

export default useLanguageSettingContentProps;
