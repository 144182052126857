import React from 'react';
import { Box, ButtonProps, Divider, Stack } from '@mui/material';
import MyPageProfile from '../../@components/Profile';
import { SubContentProps } from '../../SubMenu/LanguageSettingContent';
import { VisuallyHiddenInput } from '@/components/Board/BoardForm/DisplaySections/styled';
import Spinner from '@/components/@IntranetLibrary/Spinner';
import { Form, ProfileEditButton, SubmitButton } from './style';
import useEditProfileProps from './@hooks/useEditProfileProps';
import FormProvider, {
  FormProviderProps,
} from '@/components/HookForm/FormProvider';
import IntranetLibraryLabel from '@/components/@IntranetLibrary/Label';
import IntranetLibraryBoxButton from '@/components/@IntranetLibrary/BoxButton';
import { FormGroup } from '@/components/Board/BoardForm/style';
import RHFAutoCompleteHighLightsSelect from '@/components/HookForm/RHFAutoCompleteHighLightsSelect';
import RHFTextField from '@/components/HookForm/RHFTextField';
import RHFTextArea from '@/components/HookForm/RHFTextarea';

export type EditProfileProps = {
  readonly profileImageUrl: string;
  readonly isEditButtonDisabled: boolean;
  readonly editButtonOnClick: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;

  readonly jobOptions: string[];
  readonly jobValue: string;
  readonly isJobListFetching: boolean;

  readonly nicknameHelperText: string;
  readonly nicknameOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  readonly isCheckButtonDisabled: boolean;
  readonly isCheckButtonLoading: boolean;
  readonly checkButtonOnClick: ButtonProps['onClick'];

  readonly isSubmitButtonDisabled: boolean;
  readonly isFormSubmitting: boolean;

  readonly formProps: FormProviderProps;
};

const EditProfile = (props: SubContentProps) => {
  const {
    nicknameHelperText,
    nicknameOnChange,
    jobOptions,
    jobValue,
    isJobListFetching,
    editButtonOnClick,
    isEditButtonDisabled,
    profileImageUrl,
    formProps,
    isCheckButtonDisabled,
    isCheckButtonLoading,
    checkButtonOnClick,
    isSubmitButtonDisabled,
    isFormSubmitting,
  } = useEditProfileProps(props);

  return (
    <Stack>
      <MyPageProfile profileImageUrl={profileImageUrl}>
        <ProfileEditButton disableRipple component={'label'}>
          {isEditButtonDisabled ? (
            <Spinner theme={'black'} size={'16px'} />
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.86584 12.2134L11.8658 12.2134"
                stroke="black"
                strokeWidth="0.8"
                strokeLinecap="round"
              />
              <path
                d="M11.9383 5.75706L6.2429 11.4524L3.80295 11.7639L4.12158 9.3311L9.81693 3.63574L11.9383 5.75706Z"
                stroke="black"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          <VisuallyHiddenInput
            type="file"
            hidden
            accept={'image/*'}
            onChange={editButtonOnClick}
            disabled={isEditButtonDisabled}
          />
        </ProfileEditButton>
      </MyPageProfile>
      <FormProvider {...formProps}>
        <Form>
          <FormGroup gapsize={'s'}>
            <IntranetLibraryLabel
              isRequired={true}
              title={'영어이름'}
              size={14}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                width: '100%',
              }}
            >
              <Stack sx={{ flex: 1 }}>
                <RHFTextField
                  name={'nickname'}
                  helperText={nicknameHelperText}
                  variant={'outlined'}
                  onChange={nicknameOnChange}
                  placeholder={'Charles'}
                  sx={{
                    flex: 1,

                    '& .MuiInputBase-root': {
                      height: '48px',
                      fontSize: '14px',
                      lineHeight: '20px',
                    },
                  }}
                />
              </Stack>
              <IntranetLibraryBoxButton
                variant={'primary'}
                size={'small'}
                onClick={checkButtonOnClick}
                disabled={isCheckButtonDisabled}
                loading={isCheckButtonLoading}
                sx={{
                  width: '100px',
                }}
              >
                중복확인
              </IntranetLibraryBoxButton>
            </Box>
          </FormGroup>
          <FormGroup gapsize={'s'} sx={{ margin: '28px 0 24px' }}>
            <IntranetLibraryLabel isRequired={false} title={'직무'} size={14} />
            <RHFAutoCompleteHighLightsSelect
              name="job"
              options={jobOptions}
              value={jobValue}
              noOptionsText={'해당하는 직무가 없습니다.'}
              loading={isJobListFetching}
            />
          </FormGroup>
          <FormGroup gapsize={'s'}>
            <IntranetLibraryLabel
              isRequired={false}
              title={'한줄 요약'}
              size={14}
            />
            <RHFTextField
              name={'summary'}
              variant={'outlined'}
              maxLength={40}
              placeholder={'Portal Product Manager'}
              sx={{
                '& .MuiInputBase-root': {
                  height: '48px',
                  fontSize: '14px',
                  lineHeight: '20px',
                },
              }}
            />
          </FormGroup>
          <FormGroup gapsize={'s'}>
            <IntranetLibraryLabel isRequired={false} title={'내용'} size={14} />
            <RHFTextArea
              name={'content'}
              maxLength={300}
              minRows={3}
              placeholder={
                '저의 주요 업무는 A, B, C입니다.\n요즘 진행중인 프로젝트는 ~를 위한 ~입니다.\n~가 필요할 때 저에게 연락주세요.'
              }
            />
          </FormGroup>
        </Form>
        <Divider />
        <SubmitButton
          variant={'primary'}
          size={'large'}
          type={'submit'}
          disabled={isSubmitButtonDisabled}
          loading={isFormSubmitting}
        >
          저장
        </SubmitButton>
      </FormProvider>
    </Stack>
  );
};

export default EditProfile;
