import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';

const SignatureCardHeader = ({
  title,
  copyButtonOnClick,
}: {
  title: string;
  copyButtonOnClick: () => void;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '12px',
      }}
    >
      {title && (
        <Typography
          sx={{
            flex: 1,
            color: '#888D96',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
          }}
        >
          {title}
        </Typography>
      )}
      <IconButton
        disableRipple
        onClick={copyButtonOnClick}
        sx={{
          display: 'flex',
          gap: '4px',
          width: 'auto',
          padding: 0,
          margin: '0 0 0 auto',
          color: '#000',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M4.32487 16.8738H12.4956C12.6623 16.8738 12.8222 16.8076 12.9401 16.6897C13.0579 16.5719 13.1241 16.412 13.1241 16.2453V8.07442L10.6101 5.5603H4.95339C4.7867 5.5603 4.62683 5.62652 4.50896 5.74439C4.39109 5.86227 4.32487 6.02214 4.32487 6.18883V16.8738Z"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.758 14.4397H15.0467C15.2133 14.4397 15.3732 14.3735 15.4911 14.2557C15.609 14.1378 15.6752 13.9779 15.6752 13.8112V5.64034L13.1611 3.12622H7.50441C7.33772 3.12622 7.17785 3.19244 7.05998 3.31031C6.94211 3.42818 6.87589 3.58805 6.87589 3.75475V5.06168"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>복사</span>
      </IconButton>
    </Box>
  );
};

export default SignatureCardHeader;
