import React, { PropsWithChildren } from 'react';
import { Box, Drawer, DrawerProps, IconButton } from '@mui/material';
import { GNBHeight } from '@/layout/Default';

export type BottomSheetProps = PropsWithChildren<{
  readonly isOpen: DrawerProps['open'];
  readonly handleClose?: () => void;
  readonly isFullHeight?: boolean;
}>;

const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  handleClose,
  isFullHeight,
  children,
}) => {
  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      anchor={'bottom'}
      className="custom-bottom-sheet"
      sx={{
        '&.custom-bottom-sheet > .MuiPaper-root': {
          width: '100%',
          height: isFullHeight ? `100vh` : `calc(100vh - ${GNBHeight})`,
          borderRadius: isFullHeight ? '0' : '12px 12px 0 0',
          border: '1px solid #000',
          boxShadow: '0px -16px 32px 0px rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          display: 'flex',
          padding: '30px 30px 0 30px',
          boxSizing: 'border-box',
          background: 'white',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <IconButton
          type={'button'}
          disableRipple
          onClick={handleClose}
          sx={{ marginLeft: 'auto', width: '32px', height: '32px' }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="shape">
              <path
                id="Vector 46"
                d="M17 1L1 17"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector 47"
                d="M1 1L17 17"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </IconButton>
      </Box>
      {children}
    </Drawer>
  );
};

export default BottomSheet;
