export enum LayoutType {
  DEFAULT = 'Default',
  APPROVAL = 'Approval',
  BOARD_DETAIL = 'BoardDetail',
  BOARD_DETAIL_MODAL = 'BoardDetailModal',
}
export const MaxMobileWidthMap: { [key in LayoutType]: number } = {
  [LayoutType.DEFAULT]: 769,
  [LayoutType.APPROVAL]: 769,
  [LayoutType.BOARD_DETAIL]: 769,
  [LayoutType.BOARD_DETAIL_MODAL]: 769,
};
export const MaxDesktopWidthMap: { [key in LayoutType]: number } = {
  [LayoutType.DEFAULT]: 2050,
  [LayoutType.APPROVAL]: 2250,
  [LayoutType.BOARD_DETAIL]: 1120,
  [LayoutType.BOARD_DETAIL_MODAL]: 1032,
};
