import React from 'react';
import { Menu, MenuProps } from '@mui/material';

type PopMenuProps = MenuProps;

const PopMenu = (_props: PopMenuProps) => {
  const { children, sx, ...props } = _props;
  return (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
      sx={{
        '.MuiPaper-root': {
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
        },
        '& .MuiMenu-list': {
          width: '104px',
          padding: '6px 10px',
          borderRadius: '8px',
          '> li': {
            width: '100%',
            padding: '6px',
            margin: 0,
            fontSize: '14px',
            color: 'black',
          },
        },
        ...sx,
      }}
    >
      {children}
    </Menu>
  );
};

export default PopMenu;
