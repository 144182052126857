import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import BottomSheet from './';

type CloseAction = () => boolean;

type OpenSheetProps = {
  content: ReactNode;
  customOpenAction: (() => void) | null;
  customCloseAction: CloseAction | null;
};

export interface BottomSheetContextType {
  isOpen: boolean;
  openSheet: (props: OpenSheetProps) => void;
  closeSheet: () => void;
  setCloseHandler: (closeAction: CloseAction) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BottomSheetContext = createContext<
  BottomSheetContextType | undefined
>(undefined);

const BottomSheetProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [bottomSheetContent, setBottomSheetContent] = useState<ReactNode>(null);
  const [closeAction, setCloseAction] = useState<CloseAction | null>(null);

  const openSheet = ({
    content,
    customOpenAction,
    customCloseAction,
  }: OpenSheetProps) => {
    setBottomSheetContent(content);
    if (customOpenAction && typeof customOpenAction === 'function') {
      customOpenAction?.();
    }

    setCloseAction(() => customCloseAction);
    setIsOpen(true);
  };

  const closeSheet = () => {
    if (closeAction && typeof closeAction === 'function' && closeAction()) {
      return;
    }

    setIsOpen(false);
  };

  const setCloseHandler = (action: CloseAction) => {
    setCloseAction(() => action);
  };

  useEffect(() => {
    return () => {
      setBottomSheetContent(null);
      setIsOpen(false);
      setCloseAction(null);
    };
  }, []);

  return (
    <BottomSheetContext.Provider
      value={{
        isOpen,
        openSheet,
        closeSheet,
        setCloseHandler,
        setIsOpen,
      }}
    >
      {children}
      <BottomSheet isOpen={isOpen} handleClose={closeSheet}>
        {bottomSheetContent}
      </BottomSheet>
    </BottomSheetContext.Provider>
  );
};

export default BottomSheetProvider;
