import React, { Fragment } from 'react';
import { useChangePassport, usePassport } from '@/hooks/apiHooks';
import { useRouter } from 'next/router';
import { RnMessage, sendToRN } from '@/utils/function';
import CustomComboBox from '@/components/CustomComboBox';

type P = {
  mobileMode?: boolean;
};

export default function ChangeUserPassport({ mobileMode }: P) {
  const { data } = usePassport();

  const router = useRouter();

  const handleSelected = (value: string) => {
    useChangePassport(value).then((_) => {
      window.isRNWebview ? sendToRN(RnMessage.RESTART) : router.reload();
    });
  };
  const defaultPassport = {
    value: data?.defaultPassport.passportId || '',
    title: data?.defaultPassport.detail.companyName || '',
  };
  const list = data?.assignedPassports.map((res) => ({
    value: res.passportId,
    title: res.detail.companyName,
    subTitle: res.detail.departmentName,
    image: res.detail.profileImageUrl,
  }));

  return (data?.assignedPassports.length || 0) > 1 ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mobileMode ? '24px 20px' : '',
        width: '100%',
      }}
    >
      {list && (
        <CustomComboBox
          defaultValue={defaultPassport}
          list={list}
          handleSelected={handleSelected}
          style={{ width: '100%' }}
        />
      )}
    </div>
  ) : (
    <Fragment />
  );
}
