import React, { useEffect, useState } from 'react';
import { Drawer, useMediaQuery } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { useMenu } from '@/hooks/apiHooks';
import PCMenu from './PC';
import MobileMenu from '@/components/MenuDrawer/GlobalMenuDrawer/Mobile';
import { MenuResponse } from '@/types/api';
import { IframeFlag } from '@/layout/Default';

export type GlobalMenuDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

const GlobalMenuDrawer = ({ isOpen, onClose }: GlobalMenuDrawerProps) => {
  const myInfo = useRecoilValue(MyInfoState);
  const { data: rawData } = useMenu(myInfo);
  const isMobile = useMediaQuery(`(max-width:1050px)`);

  const [data, setData] = useState<MenuResponse>();

  // TODO : 서버에서 변경해서 내려달라고하기
  useEffect(() => {
    if (rawData) {
      const newData = rawData.data.map((data) => {
        if (data.childData.find((child) => child.name === '인사조회')) {
          return {
            ...data,
            childData: data.childData.map((child) => {
              if (child.name === '인사조회') {
                return {
                  ...child,
                  link: `${child.link}?${IframeFlag}=true`,
                };
              } else {
                return child;
              }
            }),
          };
        } else {
          return data;
        }
      });

      setData({
        ...rawData,
        data: newData,
      });
    }
  }, [rawData]);

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor={'top'}>
        {isMobile ? (
          <MobileMenu onClose={onClose} rawData={data} isRn={false} />
        ) : (
          <PCMenu onClose={onClose} rawData={data} />
        )}
      </Drawer>
    </>
  );
};

export default GlobalMenuDrawer;
