import { Description, Title } from '@/components/@IntranetLibrary/Label/styles';
import React from 'react';
import { Box } from '@mui/material';

type IntranetLibraryLabelProps = {
  readonly isRequired: boolean;
  readonly title: React.ReactNode;
  readonly description?: React.ReactNode;
  readonly size: 16 | 14;
};

const IntranetLibraryLabel: React.FC<IntranetLibraryLabelProps> = ({
  size,
  description,
  isRequired,
  title,
}) => {
  return (
    <Box>
      <Title
        sx={{
          fontSize: `${size}px`,
        }}
        className={isRequired ? 'required' : undefined}
        variant="body2"
      >
        {title}
      </Title>
      {description && <Description>{description}</Description>}
    </Box>
  );
};

export default IntranetLibraryLabel;
