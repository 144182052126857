import React from 'react';
import { FormControlLabel, Radio, RadioGroup, SvgIcon } from '@mui/material';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

type Option<ValueType> = {
  readonly value: ValueType;
  readonly label: string;
};

export type IntranetLibraryRadioProps<ValueType> = {
  readonly field: ControllerRenderProps<FieldValues, string>;
  readonly options: Option<ValueType>[];
};

function IntranetLibraryRadio<ValueType = 'string'>({
  options,
  field,
}: IntranetLibraryRadioProps<ValueType>) {
  return (
    <RadioGroup
      row
      {...field}
      sx={{
        '.MuiButtonBase-root': {
          display: 'flex',
          margin: 0,
          padding: 0,
        },
        '.MuiFormControlLabel-root': {
          padding: '0',
          margin: '0 24px 0 0',
          gap: '8px',
        },
        input: {
          width: '1px',
          height: '1px',
        },
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={`${option.value}`}
          value={option.value}
          control={
            <Radio
              icon={
                <SvgIcon viewBox={'0 0 20 20'}>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    fill="white"
                    stroke="#B7BBC2"
                  />
                </SvgIcon>
              }
              checkedIcon={
                <SvgIcon viewBox={'0 0 20 20'}>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    fill="white"
                    stroke="#B7BBC2"
                  />
                  <rect
                    x="4"
                    y="4"
                    width="12"
                    height="12"
                    rx="6"
                    fill="black"
                  />
                </SvgIcon>
              }
            />
          }
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
}

export default IntranetLibraryRadio;
