import { useContext } from 'react';
import {
  BottomSheetIframeContext,
  BottomSheetIframeContextType,
} from '@/components/BottomSheet/BottomSheetIframeProvider';

export const useBottomSheetIframe = (): BottomSheetIframeContextType => {
  const context = useContext(BottomSheetIframeContext);
  if (!context) {
    throw new Error('BottomSheetIframe Provider가 설정되지 않았습니다');
  }
  return context;
};
