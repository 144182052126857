import React from 'react';
import CopyBanner from '@/components/Profile/SecondItem/MailSignature/CopyBanner';
import { CardLayout } from '@/components/Profile/SecondItem/MailSignature/SignatureItem/styles';
import { imgUrlChk } from '@/utils/function';

const SloganItem = () => {
  const idName = `email_signature_slogan`;

  return (
    <>
      <CopyBanner title={'상단 배너'} id={idName} />
      <CardLayout id={idName}>
        <Slogan />
      </CardLayout>
    </>
  );
};

export default SloganItem;

export const Slogan = () => {
  return (
    <img
      style={{ display: 'block' }}
      src={imgUrlChk('//storage.fnf.co.kr/intranet/slogan.png')}
      alt="Hello Beautiful"
      width="199"
      height="29.85"
    />
  );
};
