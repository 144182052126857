export type SignatureCard = {
  readonly title: string;
  readonly color: string;
  readonly hasLogo: boolean;
};

export const MailSignatureLink =
  'https://outlook.office.com/mail/options/accounts-category/signatures-subcategory';

export const CardBackground = {
  Pink: '#f284ae',
  Green: '#527f6a',
  Blue: '#18345b',
};

export const FNFCards: SignatureCard[] = [
  {
    title: 'Type 1. 로고 있는 서명',
    color: CardBackground.Blue,
    hasLogo: true,
  },
  {
    title: 'Type 2. 로고 없는 서명',
    color: CardBackground.Blue,
    hasLogo: false,
  },
];

export const FNCoCards: SignatureCard[] = [
  {
    title: 'Type 1-1. 로고 있는 서명 (분홍)',
    color: CardBackground.Pink,
    hasLogo: true,
  },
  {
    title: 'Type 1-2. 로고 있는 서명 (초록)',
    color: CardBackground.Green,
    hasLogo: true,
  },
  {
    title: 'Type 2-1. 로고 없는 서명 (분홍)',
    color: CardBackground.Pink,
    hasLogo: false,
  },
  {
    title: 'Type 2-2. 로고 없는 서명 (초록)',
    color: CardBackground.Green,
    hasLogo: false,
  },
];
