import Image from 'next/image';
import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { Box } from '@mui/material';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
    fontSize: 11,
    padding: 0,
  },
}));

type CustomTooltipOption = {
  offset: number[];
};

type P = {
  option?: CustomTooltipOption;
  children?: React.ReactElement<any, any>;
} & Omit<TooltipProps, 'children'>;

const CustomTooltip = ({ option, children, title, placement, ...props }: P) => {
  return (
    <LightTooltip
      {...props}
      title={
        <Box style={{ padding: '16px', fontSize: '14px', color: '#888D96' }}>
          {title}
        </Box>
      }
      placement={placement ?? 'right-start'}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: option?.offset ?? [0, -10],
              },
            },
          ],
        },
      }}
    >
      {children ? (
        children
      ) : (
        <Image src={'/images/info.svg'} alt={'info'} width={12} height={12} />
      )}
    </LightTooltip>
  );
};

export default CustomTooltip;
