import {
  Layout,
  StyledContent,
} from '@/components/Profile/SecondItem/Language/styles';
import { useChangeLanguage, usePassport } from '@/hooks/apiHooks';
import React from 'react';
import { useRouter } from 'next/router';
import { LanguageValue } from '@/types/api';
import { RnMessage, sendToRN } from '@/utils/function';
import { t } from 'i18next';
import CustomComboBox from '@/components/CustomComboBox';

const list: { value: LanguageValue; title: string }[] = [
  {
    value: 'ko',
    title: '한국어',
  },
  { value: 'en', title: 'English' },
  { value: 'zh', title: '中文' },
];

const Language = () => {
  const { data } = usePassport();

  const defaultLanguage = {
    value: data?.personalization.lang || 'default',
    title: codeToWord(data?.personalization.lang || 'default'),
  };

  const router = useRouter();

  const handleSelected = (value: LanguageValue) => {
    useChangeLanguage(value).then((_) => {
      window.isRNWebview ? sendToRN(RnMessage.RESTART) : router.reload();
    });
  };
  return (
    <Layout>
      <StyledContent>
        {t('언어를 바꾸면 포탈이 다시 실행됩니다.')}
      </StyledContent>
      <CustomComboBox
        defaultValue={defaultLanguage}
        list={list}
        handleSelected={handleSelected}
        style={{ width: '100%' }}
      />
    </Layout>
  );
};

export default Language;

export const codeToWord = (code: string) => {
  switch (code) {
    case 'ko':
    case 'default':
      return '한국어';
    case 'en':
      return 'English';
    case 'zh':
      return '中文';
    default:
      return '';
  }
};
