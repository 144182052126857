import type { AppContext, AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import ThemeProvider from '@/components/ThemeProvider';
import GlobalStyles from '@/styles/GlobalStyles';
import { ColorModeProvider } from '@/contexts/ColorModeContext';
import InlineScript from '@/styles/InlineScript';
import '@/public/fonts/Font.css';
import '@/public/css/lexical_editor.scss';
import { RecoilRoot } from 'recoil';
import { INIT_MY_INFO } from '@/const/initData';
import { MyInfoState } from '@/recoil/atom';
import SeoHeader from '@/components/SeoHeader';
import '@fortawesome/fontawesome-svg-core/styles.css';
import LoadingLottie from '@/components/LoadingLottie';
import { useEffect, useState } from 'react';
import useHotjar from 'react-use-hotjar';
import process from 'process';
import { useRouter } from 'next/router';
import { I18nextProvider } from 'react-i18next';
import i18n from '../public/utils/i18n';
import { CustomDialogProvider } from '@/components/CustomDialog/CustomDialogProvider';
import { AuthProvider } from '@/auth/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
// If you are using date-fns v2.x, please import `AdapterDateFns`
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CustomToastProvider } from '@/components/CustomToast/CustomToastProvider';
import BottomSheetProvider from '@/components/BottomSheet/BottomSheetProvider';

import { BottomSheetIframeProvider } from '@/components/BottomSheet/BottomSheetIframeProvider';
// If you are using date-fns v3.x, please import the v3 adapter

const myCustomLogger = console.log;

const App = ({ Component, pageProps }: AppProps<AppContext>) => {
  const [loading, setLoading] = useState(true);
  const [opacity, setOpacity] = useState<0 | 1>(1);
  const router = useRouter();
  const needLoading =
    router.route === '/' || router.route === '/login' || router.route === '/m';

  const { initHotjar } = useHotjar();

  useEffect(() => {
    const timeout1 = setTimeout(() => setOpacity(0), 1800); // Opacity를 0으로 설정
    const timeout2 = setTimeout(() => setLoading(false), 2300); // 로딩 상태 해제

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2); // 타이머 정리
    };
  }, []);

  useEffect(() => {
    initHotjar(
      process.env.NEXT_PUBLIC_ORIGIN_URL === 'https://portal.fnf.co.kr'
        ? 3730425
        : 3854712,
      6,
      false,
      myCustomLogger,
    );
  }, [initHotjar]);

  return (
    <>
      <SeoHeader />
      <AuthProvider>
        <SWRConfig>
          <ColorModeProvider>
            <InlineScript />
            <ThemeProvider>
              <GlobalStyles />
              <I18nextProvider i18n={i18n}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <RecoilRoot
                    initializeState={({ set }) =>
                      set(MyInfoState, INIT_MY_INFO)
                    }
                  >
                    <CustomDialogProvider>
                      <CustomToastProvider>
                        <BottomSheetIframeProvider>
                          <BottomSheetProvider>
                            {needLoading && loading && (
                              <LoadingLottie opacity={opacity} />
                            )}
                            <Component {...pageProps} />
                          </BottomSheetProvider>
                        </BottomSheetIframeProvider>
                      </CustomToastProvider>
                    </CustomDialogProvider>
                  </RecoilRoot>
                </LocalizationProvider>
              </I18nextProvider>
            </ThemeProvider>
          </ColorModeProvider>
        </SWRConfig>
      </AuthProvider>
    </>
  );
};

export default App;
