import { useEffect, useRef } from 'react';

type Callback = () => void;

const useInterval = (
  callback: Callback,
  delay: number | undefined,
  deps = [],
) => {
  const savedCallback = useRef<Callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function runCallback() {
      savedCallback?.current?.();
    }

    if (typeof delay === 'number') {
      const interval = setInterval(runCallback, delay);
      return () => clearInterval(interval);
    }
  }, [delay, ...deps]);
};

export default useInterval;
