import { Stack, StackProps, styled } from '@mui/material';
import React from 'react';
import {
  MentionsInput as ReactMentionInput,
  MentionsInputProps,
} from 'react-mentions';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export const mentionInputClassName = 'board-comment-mentions';

export const CommentInputContainer = styled(
  ({ children, ...props }: StackProps) => <Stack {...props}>{children}</Stack>,
)(() => ({
  display: 'flex',
  width: '100%',
  padding: '12px 16px',
  border: '1px solid #E7E9EC',
  borderRadius: '6px',
  backgroundColor: 'white',
  spacing: '8px',

  flexDirection: 'column',
  gap: 0,
  height: 'auto',

  '&.focus': {
    border: '1px solid #000',
  },
}));

export const MentionsInput = styled(
  ({ children, ...props }: MentionsInputProps) => (
    <ReactMentionInput {...props}>{children}</ReactMentionInput>
  ),
)(() => ({
  width: '100%',

  [`& .${mentionInputClassName}__control`]: {
    height: '100px',
    flex: 1,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },

  '&.fixedInput': {
    [`& .${mentionInputClassName}__control`]: {
      height: 'auto',
      overflowY: 'auto',
      transition: 'height 100ms ease-out',
    },
  },
  '&.fixedInput.focus': {
    [`& .${mentionInputClassName}__control`]: {
      height: '100px',
    },
  },

  [`& .${mentionInputClassName}__input`]: {
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    letterSpacing: 'inherit',
    border: 0,
    color: '#000',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',

    '&:focus': {
      outline: 'none',
      borderBottom: 'none',
    },
    '&:disabled': {
      color: '#f0f0f0',
      outline: 'none',
      borderBottom: 'none',
    },
    '&:hover:not(:disabled):not(:focus)': {
      outline: 'none',
      borderBottom: 'none',
    },

    '&::placeholder': {
      color: '#B7BBC2',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px !important',
    },
  },

  [`& .${mentionInputClassName}__highlighter`]: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    height: '100%',
  },

  [`& .${mentionInputClassName}__suggestions`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '294px',
    padding: '16px 12px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    border: '1px solid #e7e9ec',
    borderRadius: '4px',
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
  },

  [`& .${mentionInputClassName}__suggestions__item`]: {},

  [`& .${mentionInputClassName}__suggestions__item--focused`]: {
    backgroundColor: '#E7E9EC',
  },
}));

export const MentionItem = styled(({ children, ...props }: StackProps) => (
  <Stack {...props}>{children}</Stack>
))(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: '4px',
  gap: '8px',
  alignItems: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  overflow: 'hidden',
  lineHeight: '20px',

  '& img': {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    flex: 'none',
    border: '1px solid rgba(0, 0, 0, 0.10)',
  },
  '& .mention-name': {
    color: '#000',
    flex: 'none',
  },
  '& .mention-department': {
    display: 'block',
    color: '##888D96',
    paddingLeft: '8px',
    borderLeft: '1px solid #e7e9ec',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const Footer = styled(({ children, ...props }: StackProps) => (
  <Stack {...props}>{children}</Stack>
))(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: '12px',
  borderTop: '1px solid #E7E9EC',
}));

export const SubmitButton = styled(
  ({ children, ...props }: LoadingButtonProps) => (
    <LoadingButton disableRipple {...props}>
      {children}
    </LoadingButton>
  ),
)(() => ({
  height: '32px',
  padding: '2px 6px',
  fontSize: '14px',
  color: '#000',
  '&:disabled': {
    color: '#B7BBC2',
    cursor: 'disabled',
  },
  '&:hover': {
    color: '#000',
    background: 'transparent',
  },
  '&.focus': {
    color: '#000',
    background: 'transparent',
  },
  '& .loading-button-spinner': {
    padding: '2px',
  },
}));
