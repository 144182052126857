import { Meta } from '@/types/legacyApi';
import { Dayjs } from 'dayjs';
import { ResponsiveStyleValue } from '@mui/system';
import { SideCategoryItem } from '@/components/SideCategory';

export enum ApiTokenType {
  APIX = 'APIX',
  MS = 'MS',
  JWT = 'JWT',
}

export type JardinMenu = {
  createAt: string;
  type: string;
  updateAt: string;
  imageUrl: string;
};

export type JardinClosedTimeResponse = {
  [date: string]: {
    [time: string]: 'N' | 'Y';
  };
};

export type JardinClosedTime = {
  message: string;
  response: JardinClosedTimeResponse;
  status: boolean;
};

export type BoardContent = {
  boardNo: string;
  documentNo: number;
  userName: string;
  deptName: string;
  title: string;
  createdDateTime: string;
  updatedDateTime: string;
  createdDate: string;
  updatedDate: string;
  content: string;
  readCount: number;
  commentCount: number;
  recommendCount: number;
  isMainBanner: number;
  imageUrls: string[];
  viewImage?: 'T' | 'B' | 'L' | 'S';
};

export type Board = {
  boardNo: string;
  documentNo: number;
  category: string;
  boardName: string;
  userName: string;
  deptName: string;
  title: string;
  createdDateTime: string;
  updatedDateTime: string;
  createdDate: string;
  updatedDate: string;
  readCount: number;
  commentCount: number;
  recommendCount: number;
  isMainBanner: number;
  dDay: string;
  thumbnailUrl: string;
  thumbnailPosition: string;
  contentPreview: string;
  watched: 0 | 1;
};

export type NewEmployeeInterview = {
  boardNo: string;
  documentNo: number;
  category: string;
  userName: string;
  deptName: string;
  title: string;
  createdDateTime: string;
  updatedDateTime: string;
  createdDate: string;
  updatedDate: string;
  content: string;
  readCount: number;
  commentCount: number;
  recommendCount: number;
  isMainBanner: number;
  watched: 0 | 1;
};

export type BoardLike = {
  boardNo: string;
  documentNo: number;
  userId: string;
  userName: string;
  readDateTime: string;
  readDate: string;
};

export type BoardLikes = {
  totalCount: number;
  details: BoardLike[];
};

export type User = {
  passportId: string;
  name: string;
  nickName: string;
  divisionName: string;
  departmentName: string;
  positionName: string;
  profileImageUrl: string | null;
};

export type UserV0 = {
  userId: string;
  email: string;
  name: string;
  nickName: string;
  placeName: string;
  deptName: string;
  positionName: string;
  mobilePhone: string;
  insidePhone: string;
  enterDate: string;
  companyName: string;
  userImageUrl: string;
  employeeNumber: string;
  organizationDisplayFullName: string;
  organizationDisplayShortName: string;
  birthDate: string;
  organizationDisplaySimpleName: string;
};

export type MyInfo = PassportDetail & {
  userId: string;
  passportId: string;
  enterDate: string;
  isFnCo: boolean;
  isFnHolding: boolean;
  language?: LanguageValue;
  job: {
    name: string;
    summary: string;
    content: string;
  };
};

export type ApprovalState = {
  headerKey: number;
  myApprovalKey: number;
  myWriteKey: number;
  unRead: boolean;
};

export type WebApprovalState = {
  open: boolean;
  keyword: string;
};

export type ApprovalSortType = 'docId' | 'authoredAt' | 'updatedAt';

export type ApprovalTempFilter = {
  types: ApprovalType[];
  authorPassportIds: FilterPassport[];
  approverPassportIds: FilterPassport[];
  currentApproverPassportIds: FilterPassport[];
  coApproverPassportIds: FilterPassport[];
};

export type ApprovalFilter = {
  id: number;
  sortType: ApprovalSortType;
  types: ApprovalType[];
  authorPassportIds: FilterPassport[];
  approverPassportIds: FilterPassport[];
  approverStatuses: string[];
  currentApproverPassportIds: FilterPassport[];
  coApproverPassportIds: FilterPassport[];
  coApproverStatuses: string[];
  statuses: FilterStatus[];
  referrerPassportIds: FilterPassport[];
  authorCombinedDepartments: string[];
  approverCombinedDepartments: string[];
  endAuthoredAt: PeriodStatus[];
  unreadPassportIds: FilterPassport[];
};

export type PeoPleFilterName =
  | 'authorPassportIds'
  | 'approverPassportIds'
  | 'currentApproverPassportIds'
  | 'coApproverPassportIds';

export type StatusFilterName = 'endAuthoredAt' | 'statuses';

export type FilterPassport = {
  passportId: string;
  profileImageUrl: string;
  positionName: string;
  name: string;
  divisionName: string;
  departmentName: string;
  type?: number;
};

export type TotalApprovalParams = {
  types?: string[];
  listSize?: number;
  currentPage?: number;
  sortType?: ApprovalSortType;
  orderType?: 'desc' | 'asc';
  keyword?: string;
  authorPassportIds?: string[];
  approverPassportIds?: string[];
  approverStatuses?: string;
  currentApproverPassportIds?: string[];
  coApproverPassportIds?: string[];
  coApproverStatuses?: string;
  statuses?: string[];
  referrerPassportIds?: string[];
  authorCombinedDepartments?: string[];
  approverCombinedDepartments?: string[];
  startAuthoredAt?: string;
  endAuthoredAt?: string;
  unreadPassportIds?: string[];
};

export type FilterStatus = {
  name: string;
  type: number;
  value: string;
  value2?: string;
};

export type PeriodStatus = {
  name: string;
  type: number;
  value: string;
  value2: string;
};

export type PassportDetail = {
  employeeNumber: string;
  name: string;
  nickname: string;
  email: string;
  positionName: string;
  positionCode?: string;
  departmentName: string;
  departmentCode: string;
  divisionName: string;
  divisionCode: string;
  companyCode: string;
  companyName: string;
  organizationFullName: string;
  organizationShortName: string;
  profileImageUrl: string;
  phoneInside: string;
  phoneMobile: string;
  birthdayType: number;
  birthday: string;
  joinedAt: string;
  isLeader: boolean;
};

export type FnFComment = {
  boardNo: string;
  documentNo: number;
  commendNo: number;
  snsKey: number;
  recommendCount: number;
  recommended: 0 | 1;
  userId: string;
  userName: string;
  userNickName: string;
  userProfileUrl: string;
  deptName: string;
  content: string;
  deleted: number;
  createdDate: string;
  createdDateTime: string;
};

export type GetComments = {
  totalCount: number;
  details: FnFComment[];
};

export type MSLoginUrl = {
  msAzureLoginUrl: string;
};

export type LinkFilter = {
  id: number;
  service: string;
  category: string;
  displayed: number;
  uri: string;
  name: string;
  description: string;
  icon: string;
  sortNo: number;
  createdUserId: string;
  updatedUserId: string;
  createdAt: string;
  updatedAt: string;
};

export type LinkFilterList = Partial<LinkFilter>[];

export type MsAzureToken = {
  id: string;
  mail: string;
  userId: string;
  displayName: string;
  givenName: string;
  jobTitle: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
};

export type ApixToken = {
  tokenType: string;
  key: string;
  expiresAt: string;
};

export type JwtToken = {
  tokenType: string;
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
  refreshExpiresAt: string;
};

export type JwtRefresh = {
  data: {
    token_type: string;
    access_token: string;
    expires_at: number;
  };
};

export type TokenInfo = {
  msAzure: MsAzureToken;
  apix: ApixToken;
  jwt: JwtToken;
};

export type AzureRefreshToken = {
  data: {
    token_type: string;
    access_token: string;
    expires_at: number;
    refresh_token: string;
  };
};

export type UnreadCount = {
  data: { total: number };
};

export type IssueTokenParams = {
  grant_type: string;
  loginId: string;
  password: string;
  provider: string;
  access_token: string;
  id_token: string;
  refresh_token: string;
};
export type PortalIssueTokenParamsByPasswordToken = Pick<
  IssueTokenParams,
  'grant_type' | 'loginId' | 'password'
>;

export type PortalIssueTokenParamsByAuthToken = Pick<
  IssueTokenParams,
  'grant_type' | 'provider' | 'access_token'
>;

export type PortalIssueTokenParamsByRefreshToken = Pick<
  IssueTokenParams,
  'grant_type' | 'refresh_token'
>;

export type PortalLoginType =
  | PortalIssueTokenParamsByPasswordToken
  | PortalIssueTokenParamsByAuthToken
  | PortalIssueTokenParamsByRefreshToken;

export type PortalToken = {
  data: {
    token_type: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    refresh_expires_in: number;
  };
};

export type EmpProofHistoryData = {
  cartNumber: number;
  businessDeptName: string;
  businessBumoonName: string;
  workStartDate: string;
  workEndDate: string;
  ouputReason: string;
  createDate: string;
  status: string;
};

export type CreateBoardData = {
  boardName: string;
  boardNo: string;
  category: string;
  subBoards?: CreateBoardData[];
};

export type SendMailData = {
  senderName: string;
  senderEmail: string;
  templateKey: string;
  receiverEmail: string;
  templateData: { name: string; message: string };
};

export type BookData = {
  bookId: string;
  title: string;
  isbn: string;
  author: string;
  recommendCount: number;
  readCount: number;
  rentCount: number;
  ceoRecommend: string;
  imageUrl: string;
  createdAt: string;
};

export type MyBook = BookData & {
  rentalStartDate: string;
  rentalEndDate: string;
  rentalReturnDate: string;
};

export type ReadData = {
  boardNo: string;
  documentNo: string;
};

export type BookPeriod = {
  title?: string;
  startDate?: string;
  endDate?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type JiraAccountData = {
  jiraAccountId: string;
};

export type JiraData = {
  summary: string;
  statusName: string;
  issue: {
    id: string;
    key: string;
    url: string;
  };
  assignee: {
    avatarUrls: string;
    displayName: string;
    emailAddress: string;
    accountId: string;
  };
};

export type CreateCommentData = {
  boardNo: string;
  documentNo: string;
  content: string;
  isAnonymous: number;
};

export type NotiHistoryData = {
  id: number;
  data: {
    mobileActionType: string;
    tabName: string;
  };
  title: string;
  body: string;
  link: string;
  senderId: string;
  templateKey: string;
  createdAt: string;
  watched: 0 | 1;
};

export type NotiWithMeta = {
  data: NotiHistoryData[];
  meta: {
    totalCount: number;
    listSize: number;
    currentPage: number;
    endPage: number;
    unreadHistoryCount: number;
  };
};

export type JardinReservation = {
  created_at: string;
  dept: string;
  email: string;
  empno: string;
  order_no: number;
  phone: string;
  qty: number;
  requests?: string;
  reserved_date: string;
  reserved_time: string;
  //종료|대기|노쇼|거절|확정 후 취소|확정 후 취소 패널티|확정 후 취소 종료|확정 후 취소 확인|확정 전 취소|확정
  status_code: JardinStatus;
  status_name: string;
  type: 'here' | 'togo';
  update_at: null;
  username: string;
};

export type JardinReservationData = {
  [date: string]: {
    [time: string]: JardinReservation[];
  };
};

export type ProfileResponse = {
  profileUrl: string;
};

export type MenuData = {
  name: string;
  link?: string;
  action?: 'layer' | 'modal' | 'newTab';
  gridNumber?: number;
  isDisplay: boolean;
  childData: MenuData[];
};

export type MenuResponse = {
  companyName: string;
  data: MenuData[];
};
export type QuickSearch = {
  passportId: string;
  name: string;
  positionName: string;
  organizationShortName: string;
  departmentName: string;
  divisionName: string;
  profileImageUrl: string;
};

export type SearchResponse = {
  meta: Meta;
  data: Passport[];
};

export type Passport = {
  passportId: string;
  detail: PassportDetail;
  jobDescription: JobDescription;
  job: string;
};

export type BirthdayPassPort = {
  passport: Passport;
  isSendingEmail: boolean;
};

export interface IBirthdayObj {
  [day: string]: BirthdayPassPort[];
}

export const Language = {
  default: '한국어',
  ko: '한국어',
  en: 'English',
  zh: '中文',
} as const;
type LanguageShape = typeof Language;
export type LanguageValue = keyof LanguageShape;
export type LanguageName = LanguageShape[LanguageValue];

export type PortalUserResponse = {
  id: string;
  loginId: string;
  personalization: {
    lang: LanguageValue;
  };
  defaultPassport: Passport;
  assignedPassports: Passport[];
};

export type UploadResponse = {
  uuid: string;
  fileName: string;
  fileHashName: string;
  downloadExpirationSeconds: number;
  fileSize: string;
  filePassword: string;
  createdAt: string;
};

export type DownLoadResponse = {
  url: string;
  type: string;
  expiredAt: string;
};

export type Notification = {
  templateKey: string;
  serviceName: string;
  activated: boolean;
};

export type Dday = {
  id: string;
  calendarId: string;
  summary: string;
  description: string;
  start: Day;
  end: Day;
  meta: {
    dDay: boolean;
    location: string;
    link: string;
  };
};

export type Day = {
  allDay: boolean;
  datetime: string;
  timezone: string;
};

export type NewBook = {
  newBookCount: number;
  books: BookData[];
};

export type JobDescription = {
  summary: string | null;
  content: string | null;
  updatedAt: string | null;
};

export type Job = {
  id: number;
  name_kor: string;
  name_eng: string;
  name_chn: string;
  name_jpn: string;
  createdAt: string;
  updatedAt: string;
};

export type SearchJobList = {
  meta: Meta;
  data: Job[];
};

export type MyJob = {
  job: string;
  summary: string;
  content: string;
};

export type Organizations = {
  meta: Meta;
  data: Organization[];
};

export type Organization = {
  orgCode: string;
  parentOrgCode: string;
  parentName: string;
  name: string;
  nameShort: string;
  description: string;
  totalPassportCount: number;
  children: Organization[];
};

export type PassportSearchParams = {
  keyword?: string;
  listSize?: number;
  currentPage?: number;
  sortType?: string;
  orderType?: 'desc' | 'asc';
  activated?: 1 | 0 | '';
  orgCode?: string;
  sort?: string;
};

export enum SortType {
  divisionName,
  name,
  positionCode,
  email,
  '',
}

export type ApprovalParams = {
  listSize?: number;
  currentPage?: number;
  sortType?: 'authoredAt';
  orderType?: 'desc' | 'asc';
  statuses?: string;
  isUnread?: boolean;
  approverType?: 1 | 2;
  myTurn?: boolean;
  keyword?: string;
};

export type Approval = {
  docId: string;
  type: {
    type: number;
    name: string;
    tag: string;
  };
  status: string;
  subject: string;
  author: {
    passportId: string;
    name: string;
    employeeNumber: string;
    companyName: string;
    companyCode: string;
    placeName: string;
    placeCode: string;
    departmentName: string;
    departmentCode: string;
    positionName: string;
    positionCode: string;
    profileImageUrl: string;
  };
  currentApprover: {
    passportId: string;
    type: number;
    order: number;
    name: string;
    placeCode: string;
    placeName: string;
    departmentCode: string;
    departmentName: string;
    positionCode: string;
    positionName: string;
    opinion: null;
    approvedAt: string;
    createdAt: string;
    updatedAt: string;
  };
  finalApprover: {
    passportId: string;
    type: number;
    order: number;
    name: string;
    placeCode: string;
    placeName: string;
    departmentCode: string;
    departmentName: string;
    positionCode: string;
    positionName: string;
    opinion: null;
    approvedAt: null;
    createdAt: string;
    updatedAt: string;
  };
  lastOpinionBy: {
    passportId: string;
    type: number;
    order: number;
    status: string;
    userId: string;
    employeeNumber: string;
    name: string;
    placeCode: string;
    placeName: string;
    departmentCode: string;
    departmentName: string;
    positionCode: string;
    positionName: string;
    profileImageUrl: string;
    opinion: string;
    approvedAt: string;
    createdAt: string;
    updatedAt: string;
  };
  opinionCount: number;
  commentCount?: number;
  authoredAt: string;
  latestApprovedAt: string;
  createdAt: string;
  updatedAt: string;
  link: string;
};

export type ApprovalType = {
  type: number;
  name: string;
  tag: string;
};

export type RecommendFilterResponse = {
  types: number[];
  authorPassports: Passport[];
  approverPassports: Passport[];
  currentApproverPassports: Passport[];
  coApproverPassports: Passport[];
};

export type ApprovalList = {
  meta: Meta;
  data: Approval[];
};

export type CategoryType = {
  id: string;
  name: string;
  description: string;
  groupId: string | null;
  group: string | null;
  groupSortNumber: number;
};

export type BoardContentDataArray = BoardContentData[];
export type BoardContentData = {
  id: string;
  subject: string;
  content: string;
  createdAt: string;
  thumbnails: Thumbnail[];
  displaySections: string[];
  commentCount: number;
  likeCount: number;
  recommendCount: number;
  isRead: boolean;
  isLike: boolean;
  isBookmark: boolean;
  isComment: boolean;
  isRecommend: boolean;
  isImportant: boolean;
  categories: string[];
  displayStartedAt: string;
  displayEndedAt: string | null;
  homeDisplayStartedAt: string;
  homeDisplayEndedAt: string | null;
  createdBy: User;
};

export type Thumbnail = {
  position: string;
  link: string;
};

export type BoardCreatedBy = {
  departmentName: string;
  divisionName: string;
  name: string;
  nickName: string | null;
  positionName: string;
};

export type BoardListItem = {
  categories: string[];
  commentCount: number;
  content: string;
  createdAt: string;
  createdBy: BoardCreatedBy;
  subject: string;
  id: string;
};

export type AncestorOrgCodes = Pick<
  Organization,
  'orgCode' | 'name' | 'description' | 'totalPassportCount'
>;

export enum Mode {
  Production,
  Stage,
  Development,
}

export type SalesData = {
  mainDate: Dayjs;
  mainSeason: string;
  rivalDate: Dayjs;
  analysisDate: Dayjs;
  analysisSeason: string;
};

export type Season = {
  season: string;
  sort: number;
};

export type SalesMainParams = {
  season: string;
  date: string;
};

export type Period = 'M' | 'D';

export type SalesBrandStoresParams = {
  brand: string;
  period: Period;
} & SalesMainParams;

export type GlobalAmt = {
  curr_amt: number;
  prev_amt: number;
  display_curr_amt: string;
  display_prev_amt: string;
  rate: number;
  currency: string;
};

export type BrandData = {
  brandCode: string;
  brandName: string;
  bizType: string;
  period: string;
  typeZone: string;
  currentAmount: number;
  previousAmount: number;
};

export type BrandDetail = {
  brand_code: string;
  title: string;
  d_link_shop_list: string;
  d_link_tr_type_list: string;
  d_link_sale_analytics: string[];
  d_total_curr_amt: number;
  d_total_prev_amt: number;
  d_total_display_curr_amt: string;
  d_total_display_prev_amt: string;
  d_total_rate: number;
  d_curr_amt: number;
  d_prev_amt: number;
  d_display_curr_amt: string;
  d_display_prev_amt: string;
  d_rate: number;
  d_dutyfree_curr_amt: number;
  d_dutyfree_prev_amt: number;
  d_dutyfree_display_curr_amt: string;
  d_dutyfree_display_prev_amt: string;
  d_dutyfree_rate: number;
  d_global_total: GlobalAmt[];
  d_global: GlobalAmt[];
  d_global_dutyfree: GlobalAmt[];
  m_link_shop_list: string;
  m_link_tr_type_list: string;
  m_link_sale_analytics: string[];
  m_total_curr_amt: number;
  m_total_prev_amt: number;
  m_total_display_curr_amt: string;
  m_total_display_prev_amt: string;
  m_rate: number;
  m_global_total: GlobalAmt[];
  m_global: GlobalAmt[];
  m_global_dutyfree: GlobalAmt[];
  m_curr_amt: number;
  m_prev_amt: number;
  m_display_curr_amt: string;
  m_display_prev_amt: string;
  m_total_rate: number;
  m_dutyfree_curr_amt: number;
  m_dutyfree_prev_amt: number;
  m_dutyfree_display_curr_amt: string;
  m_dutyfree_display_prev_amt: string;
  m_dutyfree_rate: number;
};

export type SalesMain = {
  result: BrandData[];
  display_rows: BrandDetail[];
  updated_at: string;
};

export type ShopData = {
  shopCode: string;
  shopName: string;
  currentAmount: string;
  previousAmount: string;
  period: string;
  openDate: string;
  brandTypeYN: string;
  customUseYN: string;
};

export type ShopDetailData = {
  brand_code: string;
  curr_amt: number;
  cust_useyn: string;
  display_curr_amt: string;
  display_prev_amt: string;
  dmflag: string;
  link_tr_type_list: string;
  local_currency: string;
  local_current_amount: string;
  local_display_current_amount: string;
  local_display_previous_amount: string;
  local_previous_amount: string;
  local_rate: 0;
  opendate: string;
  prev_amt: 0;
  rate: string;
  shop_code: string;
  shopinfo_yn: string;
  title: string;
};
export type BrandStore = {
  result: ShopData[];
  display_rows: ShopDetailData[];
  display_unit: string;
};

export type SalesTypeResponse = {
  saleType: string;
  sort: string;
  saleAmount: number;
  totalFlag: string;
  numberFlag: string;
};

export type BestItemType = {
  brandCode: string;
  season: string;
  productCode: string;
  productName: string;
  colorCode: string;
  sort: number;
  saleQuantity: number;
  title: string;
  subTitle: string;
  image: string;
  price: number;
};

export type ProductKindCode = 'L' | 'A';

export type ItemDetailParams = {
  season: string;
  brand: string;
  productCode?: string;
  shopCode: string;
};

export type SalesDetail = {
  brandCode: string;
  colorCode: string;
  productCode: string;
  productName: string;
  quantity: number;
  season: string;
  url: string;
};

export type SalesByStoreRanking = {
  season: string;
  brand: string;
  productCode?: string;
  colorCode?: string;
};

export type ItemSalesByStoreRanking = {
  brandCode: string;
  productCode: string;
  quantity: number;
  season: string;
  shopCode: string;
  shopName: string;
};

export type RivalRankResponse = {
  brandCode: string;
  brandName: string;
  sort: string;
  currentRank: string;
  previousRank: string;
  upDown: string;
};

export type RivalCompanyStandardResponse = {
  rivalCode: string;
  rivalName: string;
  sort: string;
  rivalSaleAmount: string;
  saleAmount: string;
  ratio: string;
};

export type RivalStoreResponse = {
  brandCode: string;
  shopCode: string;
  shopName: string;
  currentRank: string;
  previousRank: string;
  upDown: string;
};

export type AnalysisData = {
  brandCode: string;
  brandName: string;
  season: string;
  sort: string;
  unLink: string;
  saleAmountDay: number;
  saleAmountExiDay: number;
  saleAmountBeforeDay: number;
  saleAmountExiBeforeDay: number;
  saleAmountWeek: number;
  saleAmountExiWeek: number;
  saleAmountBeforeWeek: number;
  saleAmountExiBeforeWeek: number;
  saleAmountMonth: number;
  saleAmountExiMonth: number;
  saleAmountBeforeMonth: number;
  saleAmountExiBeforeMonth: number;
  saleAmountYear: number;
  saleAmountExiYear: number;
  saleAmountBeforeYear: number;
  saleAmountExiBeforeYear: number;
  saleRatioDay: number;
  saleRatioWeek: number;
  saleRatioMonth: number;
  saleRatioYear: number;
  saleRatioExiDay: number;
  saleRatioExiWeek: number;
  saleRatioExiMonth: number;
  saleRatioExiYear: number;
};

export type ChannelInfo = {
  groupId: string;
  totalType: string;
  shopTypeCode: string;
  shopTypeName: string;
  shopDetailTypeCode: string;
  shopDetailTypeName: string;
  locationCode: string;
  locationName: string;
  shopCount: number;
  saleAmountDay: number;
  saleRatioDay: string;
  saleAmountWeek: number;
  saleRatioWeek: string;
  saleAmountMonth: number;
  saleRatioMonth: string;
  saleGoalMonth: number;
  saleGoalRatioMonth: string;
  saleAmountYear: number;
  saleRatioYear: string;
};

export type CustomSalesDetail = {
  shopDetailTypeName: string;
  locationName: string;
  shopCount: number;
  amount: number;
  ratio: string;
  saleGoalRatioMonth?: string;
};

export type SeasonInfo = {
  season: string;
  saleAmountDay: number;
  saleRatioDay: string;
  saleAmountWeek: number;
  saleRatioWeek: string;
  saleGoalMonth: string;
  saleAmountMonth: number;
  saleGoalRatioMonth: string;
  saleRatioMonth: string;
  saleAmountYear: number;
  saleRatioYear: string;
};

export type ItemInfo = {
  season: string;
  sortSeason: string;
  productKindName: string;
  productKindDetailName: string;
  sortProductKind: string;
  sortProductKindDetail: string;
  saleAmountDay: number;
  saleRatioDay: string;
  saleRatioDayTotal: string;
  saleRatioBeforeDayTotal: string;
  saleAmountWeek: number;
  saleRatioWeek: string;
  saleRatioWeekTotal: string;
  saleRatioBeforeWeekTotal: string;
  saleAmountMonth: number;
  saleRatioMonth: string;
  saleRatioMonthTotal: string;
  saleRatioBeforeMonthTotal: string;
};

export type CalendarId = {
  id: string;
  summary: string;
  description: string;
  public: boolean;
};

export type JardinInfoParams = {
  startDate: string;
  endDate: string;
};

export type JardinStatus =
  | 'C'
  | 'N'
  | 'NS'
  | 'XA'
  | 'XCA'
  | 'XCAP'
  | 'XCAPX'
  | 'XCAX'
  | 'XCB'
  | 'Y';

export type JardinReservationUpdateParams = {
  orderNumber?: string;
  reservedDate: string;
  reservedTime: string | null;
  qty: string;
  type: string;
  requests?: string;
  currentStatus?: JardinStatus;
  updateStatus?: JardinStatus;
};

export type ReserveJardinParams = Pick<
  JardinReservationUpdateParams,
  'reservedDate' | 'reservedTime' | 'qty' | 'type' | 'requests'
>;

export type ItemParams = {
  season: string;
  brand: string;
  productCode?: string;
};

export type SaleByColorData = {
  brandCode: string;
  season: string;
  productCode: string;
  productName: string;
  colorCode: string;
  url: string;
  firstSaleDate: string;
  firstDeliveryDate: string;
  storeQuantity: number;
  saleRate: string;
  deliveryQuantity: number;
  saleQuantity: number;
  waitDeliveryQuantity: number;
  stockQuantity: number;
  wslDeliveryQuantity: number;
  saleQuantityWeek: number;
  wos: string;
};

export type ItemImageList = {
  brandCode: string;
  season: string;
  productCode: string;
  colorCode: string;
  seq: number;
  url: string;
  imageFrom: string;
  defaultYN: string;
  updateDate: string;
};
export type ItemDetail = {
  brandCode: string;
  season: string;
  productCode: string;
  productName: string;
  url: string;
  firstSaleDate: string;
  storeQuantity: number;
  saleRate: string;
  saleQuantity: number;
  stockQuantity: number;
  saleQuantityWeek: number;
  wos: string;
};

export type ItemDetailRow = {
  id: string;
  title: string;
  value: string;
};

export type ShopInfoParams = {
  season: string;
  brand: string;
  shopCode: string;
  date: string;
  period: string;
};

export type ShopInformationVO = {
  brandCode: string;
  shopCode: string;
  shopName: string;
  shopManagerCode: string;
  shopManagerName: string;
  phone: string;
  address: string;
  openDate: string;
  latitude: string;
  longitude: string;
  bizCode: string;
  locationCode: string;
  locationName: string;
  localBrand: string;
};

export type ShopCurrentSalesVO = {
  achieveRate: string;
  currencyUnit: string;
  custSaleAmount: number;
  custSaleRate: string;
  saleAmount: number;
  saleAmountBefore: number;
  saleGoalAmount: number;
  saleLocalAmount: number;
  saleLocalAmountBefore: number;
};

export type ShopSalesVOList = {
  flag: string;
  currencyUnit: string;
  totalSaleBefore2Year: string;
  totalSaleBefore1Year: string;
  totalSaleCurrentYear: string;
  totalSaleBefore2YearFromHalf: string;
  totalSaleBefore1YearFromHalf: string;
  totalSaleCurrentYearFromHalf: string;
  totalLocalSaleBefore2Year: string;
  totalLocalSaleBefore1Year: string;
  totalLocalSaleCurrentYear: string;
  totalLocalSaleBefore2YearFromHalf: string;
  totalLocalSaleBefore1YearFromHalf: string;
  totalLocalSaleCurrentYearFromHalf: string;
};
export type ShopSalesPatternVOList = {
  flag: string;
  year: string;
  month: string;
  saleAmount: number;
};
export type ShopSalesRatioVOList = {
  sort: string;
  month: string;
  shopSaleRatio: string;
  saleRatio: string;
  brandSaleRatio: string;
};
export type ShopRivalRankVO = {
  currentMonthRank: string;
  previousMonthRank: string;
  previousYearRank: string;
};

export type ShopInfo = {
  shopInformationVO: ShopInformationVO;
  shopCurrentSalesVO: ShopCurrentSalesVO;
  shopRivalRankVO: ShopRivalRankVO;
  shopSalesVOList: ShopSalesVOList[];
  shopSalesPatternVOList: ShopSalesPatternVOList[];
  shopSalesRatioVOList: ShopSalesRatioVOList[];
};

export type Weather = {
  id: string;
  calendarId: string;
  summary: string;
  description: string;
  start: Time;
  end: Time;
  meta: {
    dDay: boolean;
    lowTemperature: number;
    highTemperature: number;
  };
};

export type Time = {
  allDay: boolean;
  datetime: string;
  timezone: string;
};

export type VersionInfo = {
  isTestFlight: boolean;
  isNeeded: boolean;
  currentVersion: string;
  latestVersion: string;
  storeUrl: string;
};

export type AppConfig = {
  alarmNotice: boolean;
  dayFind: boolean;
  language: string;
  seasonFind: boolean;
  server: string;
};

export type NativeMessage<T> = {
  type: string;
  data: T;
};

export type OpenState = {
  main: OpenParams;
  shopCode: string;
};

export type OpenParams = {
  mainIndex: number;
  subIndex: number;
};

export type VacationStatus = {
  annualLeave: Leave;
  monthlyLeave: Leave;
  overage: number;
};

export type Leave = {
  granted: number;
  used: number;
};

export type SocialState = {
  categoryData: SideCategoryItem[];
  isReserve: boolean;
  isBookmark: boolean;
  categoryId: string;
  groupId: string;
  currentDate: string;
};

export type SocialLayoutState = {
  isRender: boolean;
  columns: number;
  isExpanded: boolean;
  stickyFlexDirection: ResponsiveStyleValue<'row' | 'column'>;
};
