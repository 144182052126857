import React from 'react';
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

type Option<ValueType> = {
  readonly id: ValueType;
  readonly image: string | React.ReactNode;
  readonly selected_image: string | React.ReactNode;
  readonly label: string;
};

export type ImageOptionSelectorProps<ValueType> = {
  readonly field: ControllerRenderProps<FieldValues, string>;
  readonly options: Option<ValueType>[];
};

function ImageOptionSelector<ValueType>({
  options,
  field,
}: ImageOptionSelectorProps<ValueType>) {
  return (
    <RadioGroup
      {...field}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        '.MuiFormControlLabel-root': {
          padding: '0',
          margin: 0,
        },
      }}
    >
      {options.map(({ label, id, image, selected_image }) => (
        <FormControlLabel
          key={`${id}`}
          value={id}
          control={<Radio sx={{ display: 'none' }} />}
          label={
            <Box
              sx={{
                cursor: 'pointer',
                textAlign: 'center',
              }}
            >
              {renderImage(id === field?.value ? selected_image : image, label)}
              <Typography
                variant="body1"
                sx={{
                  mt: '8px',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}
              >
                {label}
              </Typography>
            </Box>
          }
        />
      ))}
    </RadioGroup>
  );
}

export default ImageOptionSelector;

const renderImage = (image: string | React.ReactNode, label: string) => {
  if (typeof image === 'string') {
    return (
      <Box
        component="img"
        src={image}
        alt={`${label} 이미지`}
        sx={{
          display: 'block',
        }}
      />
    );
  }

  return image;
};
