import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Image from 'next/image';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { IconButtonProps } from '@mui/material';

type P = {
  scrollableDivRef?: React.RefObject<HTMLElement | null>;
} & IconButtonProps;

const TopToScroll = ({ scrollableDivRef, ...other }: P) => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollableDivElement = scrollableDivRef?.current;

  useEffect(() => {
    const handleScroll = debounce((event: Event) => {
      const scrollTop = scrollableDivElement
        ? scrollableDivElement.scrollTop
        : Math.max(document.documentElement.scrollTop, document.body.scrollTop);

      if (scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, 100);

    if (typeof window !== 'undefined') {
      const target = scrollableDivElement ?? window;
      target?.addEventListener?.('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        const target = scrollableDivElement ?? window;
        target?.removeEventListener?.('scroll', handleScroll);
      }
    };
  }, [scrollableDivElement]);

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      const target = scrollableDivElement ?? window;
      target.scrollTo({
        top: 0,
        behavior: 'smooth', // 부드러운 스크롤 효과
      });
    }
  };
  return (
    <TopToWrapper
      onClick={scrollToTop}
      style={{ opacity: isVisible ? 1 : 0 }}
      {...other}
    >
      <Image
        src={'/images/arrowUp3.svg'}
        alt={'scroll on Top'}
        width={18}
        height={18}
      />
    </TopToWrapper>
  );
};

export default TopToScroll;

const TopToWrapper = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: '24px',
  right: '16px',
  zIndex: 1000,
  background: 'white',
  borderRadius: '24px',
  width: '48px',
  height: '48px',
  boxShadow:
    '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 16px 16px 0px rgba(0, 0, 0, 0.12)',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.2s ease-in',
}));
