import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import HeaderV1 from 'layout/Version1/Header';

const Version1Layout = ({ children }: PropsWithChildren) => {
  return (
    <StyledLayout>
      <HeaderV1 />
      {children}
    </StyledLayout>
  );
};

export default Version1Layout;

const StyledLayout = styled.div`
  padding-top: 65px;

  @media (max-width: 50em) {
    padding-top: 50px;
  }

  min-width: 375px;
  min-height: 100vh;
  height: 100vh;
  background-color: 'var(--background-color)';
  overflow: 'hidden';
`;
