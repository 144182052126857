import React from 'react';
import { Box } from '@mui/material';
import { getHours, getMinutes, set } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import IntranetLibraryDatePicker from '@/components/@IntranetLibrary/DatePicker';
import IntranetLibrarySelect from '@/components/@IntranetLibrary/Select';

const generateTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const timeString = `${String(hour).padStart(2, '0')}:${String(
        minute,
      ).padStart(2, '0')}`;
      options.push(timeString);
    }
  }

  return options.map((timeStr) => ({
    label: timeStr,
    value: timeStr,
  }));
};

type Props = {
  readonly defaultValue?: Date;
  readonly name: string;
  readonly triggerField?: string;
};

const RHFDateTimePicker: React.FC<Props> = ({
  defaultValue,
  name,
  triggerField,
}) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  return (
    <Box display="flex" flexDirection={'row'} gap="12px">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const handleDateChange = (newDate: Date | null) => {
            if (newDate && !isNaN(newDate.getTime())) {
              field.onChange(newDate);
            }

            if (triggerField) {
              trigger(triggerField);
            }
          };

          const handleTimeChange = (timeStr: string) => {
            const date = field.value ? new Date(field.value) : new Date();
            const [hours, minutes] = timeStr.split(':').map(Number);

            field.onChange(
              set(date, {
                year: date.getFullYear(),
                month: date.getMonth(),
                date: date.getDate(),
                hours,
                minutes,
                seconds: 0,
              }),
            );

            if (triggerField) {
              trigger(triggerField);
            }
          };

          const timeValue = field.value
            ? `${getHours(field.value)
                .toString()
                .padStart(2, '0')}:${getMinutes(field.value)
                .toString()
                .padStart(2, '0')}`
            : '';

          const { value } = field;

          return (
            <>
              <IntranetLibraryDatePicker
                value={value}
                onChange={handleDateChange}
                defaultValue={defaultValue}
                placeholder={'날짜 선택'}
                format="yyyy-MM-dd"
                sx={{
                  width: '200px',
                }}
                error={error}
              />
              <IntranetLibrarySelect
                disabled={!value}
                placeholder={'시간 선택'}
                options={generateTimeOptions()}
                value={field.value ? timeValue : ''}
                onChange={(event) => {
                  handleTimeChange(`${event.target.value}`);
                }}
                error={Boolean(error)}
                sx={{
                  width: '140px',
                }}
              />
            </>
          );
        }}
      />
    </Box>
  );
};

export default RHFDateTimePicker;
