import React, { createContext, ReactNode, useState } from 'react';
import { useSWRConfig } from 'swr';
import { useRouter } from 'next/router';
import { RevalidateApi, revalidateApiList } from '@/components/LinkableDialog';
import { CardMedia } from '@mui/material';
import { setJwtToken, setPortalKeyToken } from '@/utils/tokens';
import LoadingLottie from '@/components/LoadingLottie';
import BottomSheet from './';

type OpenBottomSheetPayload = {
  isRn: boolean;
  url: string;
  iframeId?: string;
  revalidateTarget?: RevalidateApi;
  isFullHeight?: boolean;
};

export interface BottomSheetIframeContextType {
  openBottomSheet: (payload: OpenBottomSheetPayload) => void;
  closeBottomSheet: () => void;
}

export const BottomSheetIframeContext =
  createContext<BottomSheetIframeContextType | null>(null);
export const BottomSheetIframeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { mutate } = useSWRConfig();
  const router = useRouter();

  const [isLoad, setIsLoad] = useState(false);
  const [isFullHeight, setIsFullHeight] = useState(false);

  const [data, setData] = useState<{
    isRn: boolean;
    url: string;
    iframeFlag?: string;
    revalidateTarget?: RevalidateApi;
  }>();

  const isPCOpen =
    typeof window === 'undefined'
      ? false
      : decodeURIComponent(location.hash).replace(/[/s|\\/]/g, '_') ===
        `#${data?.iframeFlag}`;

  const isOpen = data ? (!data.isRn ? isPCOpen : true) : false;

  const openBottomSheet = ({
    isRn,
    url,
    iframeId,
    revalidateTarget,
    isFullHeight,
  }: OpenBottomSheetPayload) => {
    if (url.includes('pop.fnf.co.kr/sales')) {
      alert('Sales 데이터는 2024년 5월 1일부터 밀키웨이에서 제공됩니다.');
    }

    if (isRn) {
      if (url.includes('/organization')) {
        if (window.isRNWebview) {
          const sendData = JSON.stringify({
            actionType: 'MODAL',
            url: url,
          });
          return window?.ReactNativeWebView?.postMessage(sendData);
        } else {
          return window.open(url, '_black', 'noopener, noreferrer');
        }
      }

      setData({
        url,
        isRn,
      });
    } else {
      const flag = iframeId
        ? `${iframeId.replaceAll('/', '_')}_open`
        : undefined;

      setData({
        url,
        isRn,
        iframeFlag: flag,
        revalidateTarget,
      });
      if (flag) {
        router.push({
          pathname: router.pathname,
          query: router.query,
          hash: flag,
        });
      } else {
        history.pushState({}, '', null);
      }
    }

    setIsFullHeight(Boolean(isFullHeight));
  };

  const closeBottomSheet = () => {
    if (data?.isRn) {
      setIsLoad(false);
      setData(undefined);
    } else {
      if (data?.revalidateTarget) {
        revalidateApiList
          .get(data?.revalidateTarget)
          ?.forEach((apiUrl) =>
            mutate(apiUrl, undefined, { revalidate: true }),
          );
      }

      setData(undefined);
      setIsFullHeight(false);

      router.replace({
        pathname: router.pathname,
        query: router.query,
      });
    }
  };

  return (
    <BottomSheetIframeContext.Provider
      value={{ openBottomSheet, closeBottomSheet }}
    >
      {children}
      <BottomSheet
        isOpen={isOpen}
        handleClose={closeBottomSheet}
        isFullHeight={isFullHeight}
      >
        {data && (
          <>
            {data.isRn && !isLoad && (
              <div style={{ position: 'relative' }}>
                <LoadingLottie opacity={1} loop={true} />
              </div>
            )}
            <CardMedia
              id={'full-page-iframe'}
              style={{
                height: '100%',
                border: 'none',
              }}
              component={'iframe'}
              onLoad={() => setIsLoad(true)}
              image={
                data?.isRn ? setPortalKeyToken(data.url) : setJwtToken(data.url)
              }
            />
          </>
        )}
      </BottomSheet>
    </BottomSheetIframeContext.Provider>
  );
};
