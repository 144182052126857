import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import MultiFileUploader from '@/components/Upload/MultiFileUploader';
import { Controller, useFormContext } from 'react-hook-form';
import Tooltip from '@/components/Board/BoardForm/Tooltip';

const ImageSlide: React.FC = () => {
  const { control, watch } = useFormContext();
  const useImageSlides = watch('useImageSlides');

  return (
    <Box sx={{ mt: '8px' }}>
      <Controller
        name={'useImageSlides'}
        control={control}
        render={({ field }) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                {...field}
                checked={!!field.value}
                sx={{
                  '& .MuiSvgIcon-root': { fontSize: 23 },
                  color: '#B7BBC2',
                  '&.Mui-checked': {
                    color: 'black',
                  },
                }}
              />
              이미지 슬라이드 사용
              <Tooltip
                title={
                  <Typography
                    sx={{
                      color: '#888D96',
                      fontSize: '12px',
                      lineHeight: '18px',
                      fontWeight: 400,
                    }}
                  >
                    이미지 슬라이드는 작성한 내용 아래에 보여요.
                  </Typography>
                }
              />
            </Box>
          );
        }}
      />
      {useImageSlides && (
        <Controller
          name={'imageSlides'}
          control={control}
          render={({ field }) => (
            <Box sx={{ mt: '20px' }}>
              <MultiFileUploader
                maxLength={20}
                value={field.value}
                setValue={field.onChange}
                previewType={'imageView'}
                useAddButton={true}
                upload={true}
                placeHolder={
                  <>
                    이미지를 이곳에 끌어다 놓거나 아래 버튼을 눌러 추가해
                    주세요.
                    <br />
                    최대 20장까지 추가할 수 있어요.
                  </>
                }
                sortable={true}
              />
            </Box>
          )}
        />
      )}
    </Box>
  );
};

export default ImageSlide;
