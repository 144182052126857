import React, { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import ImageWithErrorHandling from '@/components/ImageWithErrorHandling';
import { imgUrlChk, RnMessage, sendToRN } from '@/utils/function';
import {
  LogoutButton,
  MypageContentSx,
  ProfileAccordion,
  ProfileItem,
  SubMenuButton,
  SubMenuButtonProps,
} from '@/components/MenuDrawer/MyMenuDrawer/V2/Main/style';
import Button from '@mui/material/Button';
import { MyInfo } from '@/types/api';
import { MyMenuContentWrapper } from '@/components/MenuDrawer/MyMenuDrawer/V2/styles';
import { useChangePassport, usePassport } from '@/hooks/apiHooks';
import Loading from '@/components/MobileOrganization/Content/Loading';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import MyPageProfile from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/Profile';

export type MyPageMainProps = {
  info: {
    department: ReactNode;
    isJobDescriptionLoading: boolean;
    hasJobDescription: boolean;
    jobDescription: string;
    jobDescriptionSummary: string;
    jobDescriptionContent: string;
  } & Pick<
    MyInfo,
    'name' | 'nickname' | 'employeeNumber' | 'profileImageUrl' | 'companyName'
  >;
  logoutButtonOnClick: () => void;
  editButtonOnClick: () => void;

  menuData: MyMenuMenu[];
};

export type MyMenuMenu = {
  readonly title: string;
  readonly subMenu: SubMenuButtonProps[];
};

const MyPageMain = (props: MyPageMainProps) => {
  const {
    info: {
      name,
      nickname,
      employeeNumber,
      profileImageUrl,
      companyName,
      department,
      hasJobDescription,
      isJobDescriptionLoading,
      jobDescriptionSummary,
      jobDescriptionContent,
      jobDescription,
    },
    menuData,
    editButtonOnClick,
    logoutButtonOnClick,
  } = props;
  const { data, isLoading } = usePassport();
  const router = useRouter();

  const onSelectProfile = (passportId: string) => {
    useChangePassport(passportId).then((_) => {
      window.isRNWebview ? sendToRN(RnMessage.RESTART) : router.reload();
    });
  };

  return (
    <MyMenuContentWrapper paddingBottom={'48px'}>
      <Box className={'mypage-content'} sx={MypageContentSx}>
        {/* 프로필 */}
        <MyPageProfile profileImageUrl={profileImageUrl}>
          <Stack
            direction={'column'}
            alignItems={'center'}
            spacing={'4px'}
            className={'profile'}
          >
            <Stack
              component={'p'}
              direction={'row'}
              alignItems={'center'}
              spacing={'8px'}
              divider={<Box className={'dot'} />}
            >
              <span className={'name'}>{name}</span>
              {nickname && <span className={'nickname'}>{nickname}</span>}
            </Stack>
            <p className={'companyName'}>{companyName}</p>
            <p className={'ellipsis-line2'}>{department}</p>
            {employeeNumber && (
              <p className={'employeeNumber'}>{employeeNumber}</p>
            )}
          </Stack>
        </MyPageProfile>

        {/* 업무소개 */}
        <Box className={'jobWrap'}>
          {isJobDescriptionLoading && '불러오는 중'}
          {hasJobDescription ? (
            <>
              <p className={'job'}>{jobDescription}</p>
              <p className={'summary'}>{jobDescriptionSummary}</p>
              <p className={'content ellipsis-line2'}>
                {jobDescriptionContent}
              </p>
            </>
          ) : (
            <p className={'empty'}>
              아래의 ‘프로필 편집’을 통해 업무 소개를 등록해주세요.
              <br />
              등록된 소개는 내 임직원 카드에 노출됩니다.
            </p>
          )}
        </Box>

        {/* 프로필 편집 */}
        <Button className={'edit-profile'} onClick={editButtonOnClick}>
          프로필 편집
        </Button>

        {/* 프로필 전환 */}
        <ProfileAccordion
          profileList={data?.assignedPassports}
          sx={{ margin: '27px 0 0' }}
        >
          {isLoading || !data ? (
            <Box sx={{ textAlign: 'center' }}>
              <Loading />
            </Box>
          ) : (
            data.assignedPassports.map((item, index) => (
              <ProfileItem
                key={`profile-${index}`}
                profile={item}
                className={classNames({
                  selected: data.defaultPassport.passportId === item.passportId,
                })}
                onClick={() => onSelectProfile(item.passportId)}
              />
            ))
          )}
        </ProfileAccordion>

        {menuData.map(({ title, subMenu }) => {
          return (
            <Stack
              key={title}
              component={'section'}
              direction={'column'}
              spacing={'12px'}
            >
              <h2>{title}</h2>
              {subMenu.map((sub) => {
                return <SubMenuButton key={sub.menu} {...sub} />;
              })}
            </Stack>
          );
        })}

        <LogoutButton
          onClick={logoutButtonOnClick}
          sx={{ width: 'calc(100% - 40px)', margin: '28px 20px' }}
        >
          로그아웃
        </LogoutButton>
      </Box>
    </MyMenuContentWrapper>
  );
};

export default MyPageMain;
