import React, { CSSProperties, ReactNode } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { StyledTitle } from '@/components/LinkableDialog/styles';
import styled from '@emotion/styled';
import {
  DialogBackButton,
  DialogCloseButton,
  DialogIconContainer,
} from '@/styles/styles';
import { useRouter } from 'next/router';
import { RevalidateApi, revalidateApiList } from '@/components/LinkableDialog';
import { useSWRConfig } from 'swr';
import { FullHeightHeightIframeWithLoading } from '@/components/IframeWithLoading';

const StyledButton = styled.span`
  color: var(--text-primary-color);
  font-weight: 600;
  display: flex;
  cursor: pointer;
  align-items: center;
  .MuiSvgIcon-root {
    color: var(--icon-color);
  }
`;

type P = {
  iframeId: string;
  trigger: ReactNode;
  dialogTitle: ReactNode;
  url: string;
  style?: CSSProperties;
  onClose?: () => void;
  revalidateTarget?: RevalidateApi;
  isMenuLayer?: boolean;
  backButton?: boolean;
};

const FullPageIframeModal = ({
  iframeId,
  trigger,
  dialogTitle,
  url,
  style,
  onClose,
  revalidateTarget,
  isMenuLayer,
  backButton = true,
}: P) => {
  const { mutate } = useSWRConfig();
  const router = useRouter();

  const iframeFlag = iframeId ? `${iframeId.replaceAll('/', '_')}_open` : null;

  const isOpen =
    decodeURIComponent(location.hash).replace('/[/s|\\/]/g', '_') ===
    `#${iframeFlag}`;

  const handleOpen = () => {
    if (url.includes('pop.fnf.co.kr/sales')) {
      alert('Sales 데이터는 2024년 5월 1일부터 밀키웨이에서 제공됩니다.');
    }

    if (iframeFlag) {
      router.push({
        pathname: router.pathname,
        query: router.query,
        hash: iframeFlag,
      });
    } else {
      history.pushState({}, '', null);
    }
  };

  const handleBack = () => {
    if (isMenuLayer) {
      router.replace({
        pathname: router.pathname,
        query: router.query,
      });
    } else {
      router.back();
    }
  };

  const handleClose = () => {
    if (revalidateTarget) {
      revalidateApiList
        .get(revalidateTarget)
        ?.forEach((apiUrl) => mutate(apiUrl, undefined, { revalidate: true }));
    }

    onClose?.();
    router.replace({
      pathname: router.pathname,
      query: router.query,
    });
  };

  return (
    <>
      <StyledButton style={style} onClick={handleOpen}>
        {trigger}
      </StyledButton>
      <Dialog open={isOpen} onClose={handleClose} fullScreen>
        <StyledTitle>
          {dialogTitle}
          <DialogIconContainer>
            {backButton && <DialogBackButton onClick={handleBack} />}
            <DialogCloseButton onClick={handleClose} />
          </DialogIconContainer>
        </StyledTitle>
        <DialogContent style={{ padding: '0' }} dividers>
          <FullHeightHeightIframeWithLoading
            id={'full-page-iframe'}
            url={url}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FullPageIframeModal;
