import React from 'react';
import MultiFileUploader from '@/components/Upload/MultiFileUploader';
import { Controller, useFormContext } from 'react-hook-form';

const FileAttachment: React.FC<{
  name: string;
}> = ({ name }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MultiFileUploader
          value={field.value}
          setValue={field.onChange}
          isOnlyImage={false}
          previewType={'filename'}
          useAddButton={false}
          upload={true}
          maxSize={500 * 1024 * 1024}
          placeHolder={
            '파일을 이곳에 끌어다 놓거나 아래 버튼을 눌러 추가해 주세요.'
          }
          sortable={false}
        />
      )}
    />
  );
};

export default FileAttachment;
