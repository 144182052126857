import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

type IntranetLibrarySpinnerProps = {
  readonly theme: 'white' | 'black';
  readonly size?: string;
};

const IntranetLibrarySpinner: React.FC<IntranetLibrarySpinnerProps> = ({
  theme,
  size,
}) => {
  return (
    <Spinner
      background={theme === 'white' ? '#fff' : '#333'}
      width={size || '30px'}
    />
  );
};

export default IntranetLibrarySpinner;

const Spinner = styled.div<{ background: string; width: string }>`
  width: ${({ width }) => width};
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l13 1s infinite linear;
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);

    background:  ${({
      background,
    }) => `radial-gradient(farthest-side, ${background} 94%, #0000)
          top/3px 3px no-repeat,
        conic-gradient(#0000 30%, ${background});
    `};
  }

  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
