import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import SvgImage from '@/components/Module/SvgImage';
import { t } from 'i18next';
import {
  StyledTitle,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListItem,
  StyledListTitle,
  StyledListSubTitle,
  LayoutImage,
} from '@/components/CustomComboBox/styles';
import { imgUrlChk } from '@/utils/function';

type DropdownItem<T> = {
  value: T;
  title: string;
  subTitle?: string;
  image?: string;
};

type CustomDropdownProps<T> = {
  defaultValue?: DropdownItem<T>;
  list: DropdownItem<T>[];
  handleSelected: (value: T) => void;
  placeholder?: string;
  style?: CSSProperties;
};

const CustomComboBox = <T,>({
  defaultValue,
  list,
  handleSelected,
  placeholder = 'Select an option',
  style,
}: CustomDropdownProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<DropdownItem<T> | null>(
    defaultValue ? defaultValue : list[0],
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen((prev) => !prev);

  const handleSelect = (item: DropdownItem<T>) => {
    if (selected?.value !== item.value) {
      setSelected(item);
      handleSelected(item.value);
      setIsOpen(false);
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef} style={style}>
      <DropdownHeader onClick={handleToggle} isOpen={isOpen}>
        <StyledTitle>{selected ? t(selected.title) : placeholder}</StyledTitle>
        <SvgImage name={isOpen ? 'comboBox_open' : 'comboBox_close'} />
      </DropdownHeader>
      <DropdownList isFocus={isOpen}>
        {list.map((item, index) => (
          <DropdownListItem
            key={index}
            onClick={() => handleSelect(item)}
            isSelected={selected?.value === item.value}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {item.image && (
                <LayoutImage
                  isSelected={selected?.value === item.value}
                  width={32}
                  height={32}
                  src={imgUrlChk(item.image)}
                  alt={`passportProfile-${item.value}`}
                />
              )}
              <div>
                <StyledListTitle>{t(item.title)}</StyledListTitle>
                {item.subTitle && (
                  <StyledListSubTitle>{item.subTitle}</StyledListSubTitle>
                )}
              </div>
            </div>
            <SvgImage
              name={
                selected?.value === item.value
                  ? 'comboBox_checked'
                  : 'comboBox_check'
              }
            />
          </DropdownListItem>
        ))}
      </DropdownList>
    </DropdownContainer>
  );
};

export default CustomComboBox;
