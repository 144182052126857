import { Skeleton, Stack } from '@mui/material';
import React from 'react';
import { ModalBody } from '@/components/Board/BoardForm/style';

const BoardFormLoading = () => {
  return (
    <ModalBody
      sx={{
        padding: '24px 0',
      }}
    >
      <Stack sx={{ gap: '8px' }}>
        <Skeleton
          variant={'rounded'}
          width={'140px'}
          height={'36px'}
          animation={'wave'}
        />
        <Skeleton
          variant={'rounded'}
          width={'100%'}
          height={'36px'}
          animation={'wave'}
        />
        <Skeleton
          variant={'rounded'}
          width={'80%'}
          height={'36px'}
          animation={'wave'}
        />
      </Stack>
      <Stack sx={{ gap: '8px', marginTop: '24px' }}>
        <Skeleton
          variant={'rounded'}
          width={'100%'}
          height={'300px'}
          animation={'wave'}
        />
        <Skeleton
          variant={'rounded'}
          width={'100%'}
          height={'36px'}
          animation={'wave'}
        />
        <Skeleton
          variant={'rounded'}
          width={'80%'}
          height={'36px'}
          animation={'wave'}
        />
      </Stack>
      <Stack sx={{ gap: '8px', marginTop: '24px' }}>
        <Skeleton
          variant={'rounded'}
          width={'200px'}
          height={'36px'}
          animation={'wave'}
        />
        <Skeleton
          variant={'rounded'}
          width={'100%'}
          height={'36px'}
          animation={'wave'}
        />
        <Skeleton
          variant={'rounded'}
          width={'80%'}
          height={'36px'}
          animation={'wave'}
        />
      </Stack>
    </ModalBody>
  );
};

export default BoardFormLoading;
