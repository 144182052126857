import { MyMenuContent } from '@/constant/mymenu';
import { useResetRecoilState } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { MyMenuDrawerProps } from '@/components/MenuDrawer/MyMenuDrawer/V2';
import { useFindPassport, useGetVacationStatus } from '@/hooks/apiHooks';
import { useRouter } from 'next/router';
import { disableMessage } from '@/utils/firebase/initialize';
import { TOKEN_KEY_SET } from '@/const/tokens';
import { removeCookie } from '@/utils/tokens';
import { Language, LanguageValue, MyInfo } from '@/types/api';
import { MyPageMainProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/Main';
import { MyMenuDrawerSettings } from '@/components/MenuDrawer/MyMenuDrawer/V2/@hooks/useMyMenuDrawerProps';
import { useMemo } from 'react';

const useMainProps = ({
  myInfo,
  onClose,
  openSubMenu,
}: {
  myInfo: MyInfo;
  openSubMenu: MyMenuDrawerProps['openSubMenu'];
  onClose: MyMenuDrawerSettings['onClose'];
}): MyPageMainProps => {
  const { passportId, departmentName, divisionName, language } = myInfo;

  const { data: passportData, isLoading } = useFindPassport(passportId);

  const { data: vacationData } = useGetVacationStatus(passportId);

  const remainingVacation = useMemo(() => {
    if (!vacationData) {
      return '조회 안됨';
    }

    const monthlyLeaveGranted = vacationData.monthlyLeave?.granted ?? 0;
    const annualLeaveGranted = vacationData.annualLeave?.granted ?? 0;
    const monthlyLeaveUsed = vacationData.monthlyLeave?.used ?? 0;
    const annualLeaveUsed = vacationData.annualLeave?.used ?? 0;
    const overage = vacationData.overage ?? 0;

    const remainingVacation =
      monthlyLeaveGranted +
      annualLeaveGranted -
      (monthlyLeaveUsed + annualLeaveUsed + overage);

    const isExecutive = monthlyLeaveGranted === 0 && annualLeaveGranted === 0;

    return isExecutive ? '' : `잔여 ${remainingVacation}일`;
  }, [vacationData]);

  return {
    menuData: [
      {
        title: '계정',
        subMenu: [
          MyMenuContent.PERSONAL_INFO,
          MyMenuContent.MAIL_SIGNATURE,
          MyMenuContent.LANGUAGE_SETTING,
          MyMenuContent.ALARM_SETTING,
          MyMenuContent.PASSWORD_SETTING,
        ].map((menu) => ({
          menu,
          onClick: () => openSubMenu(menu),
          content: (() => {
            if (menu === MyMenuContent.LANGUAGE_SETTING) {
              return Language[language as LanguageValue];
            }
          })(),
        })),
      },
      {
        title: '직원 서비스',
        subMenu: [
          MyMenuContent.CHECK_VACATION,
          MyMenuContent.PROOF_EMPLOYMENT,
          MyMenuContent.PAY_STUB,
          MyMenuContent.CERTIFY_MALL_ID,
          MyMenuContent.WIFI,
        ].map((menu) => ({
          menu,
          onClick: () => openSubMenu(menu),
          content: (() => {
            if (menu === MyMenuContent.CHECK_VACATION) {
              return remainingVacation;
            }
          })(),
        })),
      },
    ],
    info: {
      ...myInfo,
      department: `${divisionName} • ${departmentName}`,
      isJobDescriptionLoading: isLoading,
      hasJobDescription: Boolean(
        passportData?.jobDescription?.summary &&
          passportData?.jobDescription?.content,
      ),
      jobDescription: passportData?.job ?? '',
      jobDescriptionSummary: passportData?.jobDescription?.summary || '-',
      jobDescriptionContent: passportData?.jobDescription?.content || '-',
    },
    logoutButtonOnClick: useLogout(onClose),
    editButtonOnClick: () => openSubMenu(MyMenuContent.EDIT_PROFILE),
  };
};

export default useMainProps;

const useLogout = (onClose: () => void) => {
  const router = useRouter();
  const reset = useResetRecoilState(MyInfoState);

  return async () => {
    disableMessage();

    reset();
    TOKEN_KEY_SET.forEach(removeCookie);
    setTimeout(
      () =>
        router
          .push(
            `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${process.env.NEXT_PUBLIC_ORIGIN_URL} `,
          )
          .then((_) => console.log('로그아웃 완료')),
      300,
    );

    onClose();
  };
};
