import React, { useEffect, useRef, useState } from 'react';
import { setJwtToken } from '@/utils/tokens';
import { Box, CardMedia, CardProps, CircularProgress } from '@mui/material';

type IframeWithLoadingProps = {
  readonly url: string;
  readonly isFullHeight?: boolean;
  readonly showSpinner?: boolean;
  readonly loading?: JSX.Element;
} & Omit<CardProps<'iframe'>, 'component'>;

const IframeWithLoading = ({
  url,
  isFullHeight,
  showSpinner = true,
  loading,
  ...props
}: IframeWithLoadingProps) => {
  const cardRef = useRef<HTMLIFrameElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadingIconVisible, setIsLoadingIconVisible] = useState(false);

  useEffect(() => {
    setIsLoadingIconVisible(Boolean(showSpinner && !isLoaded));
  }, [showSpinner, isLoaded]);

  return (
    <>
      {isLoadingIconVisible && (
        <Box
          sx={{
            width: cardRef.current?.clientWidth ?? '100px',
            height: cardRef.current?.clientHeight ?? '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading || <CircularProgress color="inherit" />}
        </Box>
      )}
      <CardMedia
        ref={cardRef}
        component={'iframe'}
        image={setJwtToken(`${url}`)}
        onLoad={(e) => {
          setIsLoaded(true);
          props?.onLoad?.(e);
        }}
        {...props}
      />
    </>
  );
};

export default IframeWithLoading;

export const FullHeightHeightIframeWithLoading = ({
  sx,
  ...props
}: IframeWithLoadingProps) => {
  return (
    <IframeWithLoading
      {...props}
      sx={{ height: '100%', borderTop: 'none', ...sx }}
    />
  );
};

export const NinetyViewPortHeightIframeWithLoading = ({
  sx,
  ...props
}: IframeWithLoadingProps) => {
  return (
    <IframeWithLoading
      {...props}
      sx={{ height: '90vh', borderTop: 'none', ...sx }}
    />
  );
};

export const FullViewPortHeightIframeWithLoading = ({
  sx,
  ...props
}: IframeWithLoadingProps) => {
  return (
    <IframeWithLoading
      {...props}
      sx={{ height: '100vh', borderTop: 'none', ...sx }}
    />
  );
};
