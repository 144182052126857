import { css, Global, Theme, useTheme } from '@emotion/react';
import { maxBreakpoints, minBreakpoints } from '@/utils/mediaQuery';
import styled from '@emotion/styled';

const globalStyles = (theme: Theme) => css`
  :root {
    --font-NotoSans: 'Noto Sans', sans-serif;
    --font-Poppins: 'Poppins', sans-serif;
    --font-AppleGothicNeoSD: 'AppleGothicNeoSD', sans-serif;
    --font-Pretendard: 'Pretendard', sans-serif;
    --max-main-content: 2050px;
  }

  * {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: var(--font-Pretendard), var(--font-NotoSans), 'Noto Sans KR',
      var(--font-AppleGothicNeoSD), Apple SD 산돌고딕 Neo, -apple-system,
      BlinkMacSystemFont, sans-serif;
    background: var(--body-color);
    color: var(--text-primary-color);
  }

  body {
    color: var(--text-color);
    line-height: 1.5;
  }

  button {
    border: none;
    font-family: inherit;
    color: var(--text-primary-color);
    background-color: transparent;
    cursor: pointer;
  }

  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  *:not(.visible-scroll)::-webkit-scrollbar {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 600;
  }

  @media print {
    #board-print-content {
      @page {
        margin: 25mm 25mm 25mm 25mm;
      }
    }
  }

  @media screen and (${minBreakpoints.md}) {
    html,
    body {
      font-size: 14px;
    }
  }
  @media screen and (${maxBreakpoints.md}) {
    html,
    body {
      font-size: 13px;
    }
  }

  @keyframes typing {
    0% {
      width: 0;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }

  .MuiCardMedia-root {
    border: none;
    border-top: 1px solid #ebedf3;
  }

  .icon-help {
    padding: 0.1rem !important;
    color: var(--icon-color-light) !important;
  }
  .icon-right-arrow {
    padding: 0.15rem !important;
    color: var(--text-primary-color) !important;
  }
  .icon-color-light {
    color: var(--icon-color-light) !important;
  }
  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: transparent;
  }

  *.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
  }
  *.ellipsis-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
`;

const GlobalStyles = () => {
  const theme = useTheme();
  return <Global styles={globalStyles(theme)} />;
};

export const StyledSVGButton = styled.button`
  padding: 0;
  > svg {
    vertical-align: middle;
  }
`;

export default GlobalStyles;
