import React from 'react';
import { Button, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import LikeOnIcon from 'public/images/icon/like_on_20.svg';
import LikeOffIcon from 'public/images/icon/like_off_20.svg';

type BoardLikeButtonProps = {
  isLike: boolean;
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};
const BoardLikeButton = ({
  isLike,
  onClick,
  disabled,
  sx,
}: BoardLikeButtonProps) => {
  return (
    <Button
      variant={'outlined'}
      size={'large'}
      sx={{
        width: '148px',
        fontSize: '16px',
        borderColor: isLike ? 'black' : '#E7E9EC',
        color: 'black',
        ...sx,
      }}
      onClick={disabled ? undefined : onClick} //style 때문에 undefined 처리
    >
      {isLike ? <LikeOnIcon /> : <LikeOffIcon />}
      &nbsp;좋아요
    </Button>
  );
};

export default BoardLikeButton;
