import React, { PropsWithChildren, ReactNode } from 'react';
import { Alert, AlertProps, Box, Snackbar, SnackbarProps } from '@mui/material';
import Link from 'next/link';

type CustomToastProps = PropsWithChildren<{
  readonly message?: ReactNode;
  readonly link?: string;
  readonly snackbarProps?: SnackbarProps;
  readonly iconProps?: AlertProps;
}>;

const CustomToast: React.FC<CustomToastProps> = ({
  children,
  snackbarProps,
  iconProps,
  message,
  link,
}) => {
  return (
    <>
      {children}
      <Snackbar {...snackbarProps}>
        <Alert
          {...iconProps}
          sx={{
            width: '100%',
            '&.MuiPaper-root': {
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '12px',
            },
            '& .MuiAlert-icon': {
              margin: 0,
            },
            '& .MuiAlert-message': {
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
            },
            '.MuiAlert-action': {
              padding: 0,
            },
            '.alert-link': {
              textDecoration: 'underline',
            },
          }}
        >
          {message}
          {link && (
            <Box>
              <Link href={link} target={'_blank'} className={'alert-link'}>
                보러가기 →
              </Link>
            </Box>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomToast;
