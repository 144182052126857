import { styled } from '@mui/material/styles';
import { BoxButtonProps } from '@/components/@IntranetLibrary/BoxButton/index';
import { Button as MuiButton } from '@mui/material';

export const Button = styled(
  ({ variant, loading, size, ...props }: BoxButtonProps) => (
    <MuiButton disableRipple {...props} />
  ),
)(({ variant, size = 'large', loading }) => {
  //TODO: loading 버튼 처리
  const fontSize = size === 'small' ? '14px' : '16px';
  const lineHeight = size === 'small' ? '20px' : '24px';

  const commonStyle = {
    height: '48px',
    padding: '14px',
    borderWidth: '1px',
    borderStyle: 'solid',

    fontStyle: 'normal',
    fontWeight: '400',
    fontSize,
    lineHeight,
  };

  switch (variant) {
    case 'primary': {
      return {
        ...commonStyle,
        borderRadius: '6px',

        background: '#000',
        borderColor: '#000',
        color: '#FFF',

        '&:hover': {
          background: '#000',
          borderColor: '#000',
          color: '#FFF',
        },
        '&:focus': {
          background: '#000',
          borderColor: '#000',
          color: '#FFF',
        },
        '&.Mui-disabled': {
          backgroundColor: '#E7E9EC',
          borderColor: '#E7E9EC',
          color: '#fff',
        },
      };
    }
    case 'secondary': {
      return {
        ...commonStyle,
        borderRadius: '4px',

        borderColor: '#E7E9EC',
        background: '#fff',
        color: '#000',

        '&:focus': {
          borderColor: '#000',
          color: '#000',
        },
        '&:hover': {
          borderColor: '#E7E9EC',
          background: '#fff',
          color: '#000',
        },
        '&.Mui-disabled': {
          background: '#F9FAFB',
          color: '#D1D8E2',
        },
      };
    }
  }
});
