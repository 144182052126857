import * as React from 'react';
import {
  Autocomplete,
  Checkbox,
  Chip,
  ListItem,
  TextField,
} from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  CheckboxCheckedIcon,
  CheckboxIcon,
  CircleDeleteIcon,
  SelectArrow,
  WhiteClose,
} from '@/theme/overrides/CustomIcons';
import {
  AutocompleteHighlightColor,
  AutocompleteListItemStyle,
  AutocompletePaperStyle,
  AutocompleteSelectedText,
  AutocompleteSelectStyle,
  MuitlAutocompleteTextFieldStyle,
} from '@/components/AutoCompleteHighLightsSelect/styles';
import { WrappedAutoCompleteProps } from '@/components/AutoCompleteHighLightsSelect/common';

export default function MultiAutoCompleteHighLightsSelect<
  TOption,
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  value,
  options,
  labelKey,
  valueKey,
  placeholder,
  helperText,
  error,
  ...props
}: WrappedAutoCompleteProps<TOption, Multiple, DisableClearable, FreeSolo>) {
  const optionValue = (option: string | TOption) =>
    typeof option === 'string' ? option : valueKey ? option[valueKey] + '' : '';
  const optionLabel = (option: string | TOption) =>
    typeof option === 'string' ? option : labelKey ? option[labelKey] + '' : '';

  return (
    <Autocomplete
      multiple={true as Multiple}
      disableCloseOnSelect={
        props.disableCloseOnSelect !== undefined
          ? props.disableCloseOnSelect
          : true
      }
      clearIcon={<CircleDeleteIcon />}
      options={options}
      value={value}
      popupIcon={
        <SelectArrow
          style={{
            height: '20px',
            width: '20px',
          }}
          stroke={props.disabled ? '#D1D8E2' : 'black'}
        />
      }
      slotProps={{
        paper: {
          elevation: 0,
          style: AutocompletePaperStyle,
        },
      }}
      sx={{ ...AutocompleteSelectStyle, ...props.sx }}
      getOptionLabel={(option) => optionLabel(option)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              {...tagProps}
              label={optionLabel(option)}
              key={key}
              sx={AutocompleteSelectedText}
              deleteIcon={<WhiteClose width={8} height={8} />}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error ? error?.message : helperText ?? ''}
          placeholder={placeholder}
          margin="none"
          InputProps={{
            ...params.InputProps,
            sx: {
              ...MuitlAutocompleteTextFieldStyle,
              '&.MuiOutlinedInput-root .MuiAutocomplete-input::placeholder': {
                color:
                  Array.isArray(value) && value.length > 0
                    ? 'transparent'
                    : 'inherit',
              },
              '&.MuiOutlinedInput-root .MuiAutocomplete-input': {
                width: Array.isArray(value) && value.length > 0 ? 0 : 'auto',
              },
            },
          }}
        />
      )}
      renderOption={(props, option, { inputValue, selected }, data) => {
        const { key, ...optionProps } = props;
        const { value, focused } = data;
        const realValue =
          typeof value === 'string'
            ? value
            : valueKey && value
            ? String((value as Record<typeof valueKey, string>)[valueKey])
            : '';
        const matches = match(optionLabel(option), inputValue ?? '', {
          insideWords: true,
        });
        const convertedOption = optionValue(option);
        const isSelected = realValue === convertedOption;
        const parts = parse(optionLabel(option), matches);
        return (
          <ListItem
            key={convertedOption}
            sx={AutocompleteListItemStyle}
            {...optionProps}
          >
            <Checkbox
              icon={<CheckboxIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  display: 'inline-block', // 공백을 유지하도록 인라인 블록 설정
                  whiteSpace: 'pre', // 공백을 그대로 렌더링
                  color:
                    selected && part.highlight
                      ? AutocompleteHighlightColor
                      : selected
                      ? 'black'
                      : part.highlight
                      ? AutocompleteHighlightColor
                      : 'black',
                }}
              >
                {part.text}
              </span>
            ))}
          </ListItem>
        );
      }}
      {...props}
    />
  );
}
