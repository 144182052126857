import { Slogan } from '@/components/Profile/SecondItem/MailSignature/SloganItem';
import { Stack } from '@mui/material';
import React from 'react';
import CardHeader from '../CardHeader';
import { SignatureCardProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/MailSignature/SignatureCard';

type SloganCardProps = {
  marginTop: string;
} & Pick<SignatureCardProps, 'copyButtonOnClick'>;

const SloganCard = ({ marginTop, copyButtonOnClick }: SloganCardProps) => {
  const id = 'slogan-hello-beautiful';

  return (
    <Stack
      sx={{
        marginTop,
      }}
    >
      <CardHeader
        title={'상단 배너'}
        copyButtonOnClick={copyButtonOnClick(id)}
      />
      <div id={id}>
        <Slogan />
      </div>
    </Stack>
  );
};

export default SloganCard;
