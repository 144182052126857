import { MyMenuContent } from '@/constant/mymenu';
import EditProfileContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/EditProfile';
import { PersonalInfoContent } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PersonalInfoContent';
import MailSignatureContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/MailSignature';
import LanguageSettingContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSettingContent';
import AlarmSettingContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/AlarmSettingContent';
import PasswordSettingContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PasswordSettingContent';
import ProofEmploymentContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/ProofEmploymentContent';
import { PayStubContent } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PayStubContent';
import CertifyMallIdContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/CertifyMallIdContent';
import WifiContent from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/WifiContent';
import Vacation from '@/components/Profile/SecondItem/Vacation';

export const MyMenuContentMap = new Map<MyMenuContent, any>([
  [MyMenuContent.EDIT_PROFILE, EditProfileContent],
  [MyMenuContent.PERSONAL_INFO, PersonalInfoContent],
  [MyMenuContent.MAIL_SIGNATURE, MailSignatureContent],
  [MyMenuContent.LANGUAGE_SETTING, LanguageSettingContent],
  [MyMenuContent.ALARM_SETTING, AlarmSettingContent],
  [MyMenuContent.PASSWORD_SETTING, PasswordSettingContent],
  [MyMenuContent.CHECK_VACATION, Vacation],
  [MyMenuContent.PROOF_EMPLOYMENT, ProofEmploymentContent],
  [MyMenuContent.PAY_STUB, PayStubContent],
  [MyMenuContent.CERTIFY_MALL_ID, CertifyMallIdContent],
  [MyMenuContent.WIFI, WifiContent],
]);
