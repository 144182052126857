import React from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

export const Wrapper = styled(({ children, ...props }: BoxProps) => (
  <Box {...props} component="div">
    {children}
  </Box>
))(() => ({
  display: 'flex',
  borderRadius: '6px',
  padding: '8px 16px',
  border: '1px solid #E7E9EC',
  background: '#F9FAFB',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
}));

export const Content = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>{children}</Box>
))(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
}));

export const FileName = styled(({ children, ...props }: TypographyProps) => (
  <Typography variant={'body2'} {...props}>
    {children}
  </Typography>
))(() => ({
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  userSelect: 'none',
  pointerEvents: 'none',
  touchAction: 'none',
  whiteSpace: 'nowrap',
}));

export const ActionButton = styled(
  ({ children, ...props }: IconButtonProps) => (
    <IconButton disableRipple {...props}>
      {children}
    </IconButton>
  ),
)(() => ({
  p: 1,
  '&:hover': {
    background: 'transparent',
  },
}));
