import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormHelperText,
  Stack,
  TextareaAutosizeProps,
  Typography,
} from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { ErrorHelperTextStyle } from '@/components/HookForm/RHFTextField';
import { TextareaAutosize } from './style';

export type RHFTextAreaProps = TextareaAutosizeProps & {
  readonly name: string;
} & Pick<TextFieldProps, 'helperText'>;

export default function RHFTextArea({
  name,
  helperText,
  maxLength,
  ...otherProps
}: RHFTextAreaProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack>
          <TextareaAutosize
            {...field}
            {...otherProps}
            className={error ? 'error' : undefined}
          />
          <Box sx={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
            {error && (
              <FormHelperText
                sx={{
                  ...ErrorHelperTextStyle,
                  justifyContent: 'initial',
                  flex: 1,
                  margin: 0,
                  padding: 0,
                }}
              >
                {error.message ?? ''}
              </FormHelperText>
            )}
            {maxLength !== undefined && (
              <Typography
                align={'right'}
                sx={{
                  fontSize: '14px',
                  marginLeft: 'auto',
                  flex: 'none',
                }}
              >
                {field.value?.length ?? 0}{' '}
                <Typography
                  component={'span'}
                  variant={'inherit'}
                  color={'#D4D7DB'}
                >
                  / {maxLength}
                </Typography>
              </Typography>
            )}
          </Box>
        </Stack>
      )}
    />
  );
}
