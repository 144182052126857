import { LexicalComposer } from '@lexical/react/LexicalComposer';
import {
  SettingsContext,
  useSettings,
} from '@/components/LexicalEditor/context/SettingsContext';
import { SharedAutocompleteContext } from '@/components/LexicalEditor/context/SharedAutocompleteContext';
import { SharedHistoryContext } from '@/components/LexicalEditor/context/SharedHistoryContext';
import PlaygroundNodes from '@/components/LexicalEditor/nodes/PlaygroundNodes';
import { TableContext } from '@/components/LexicalEditor/plugins/TablePlugin';
import PlaygroundEditorTheme from '@/components/LexicalEditor/themes/PlaygroundEditorTheme';
import Editor from '@/components/LexicalEditor/Editor';
import LoadInitialContent from '@/components/LexicalEditor/LoadInitialContent';
import {
  EditorState,
  LexicalEditor as EditorComponent,
  TextNode,
} from 'lexical';
import dynamic from 'next/dynamic';
import { Controller, useFormContext } from 'react-hook-form';
import { $generateHtmlFromNodes } from '@lexical/html';
import { ExtendedTextNode } from '@/components/LexicalEditor/nodes/ExtendedTextNode';
import { useEffect, useState } from 'react';

type P = {
  value?: string;
  autoFocus?: boolean;
  onChange: (
    editorState: EditorState,
    editor: EditorComponent,
    tags: Set<string>,
  ) => void;
};

export default function LexicalEditorComponent(props: P) {
  const { value, autoFocus, onChange } = props;
  const {
    settings: { emptyEditor },
  } = useSettings();

  const initialConfig = {
    editorState: undefined,
    namespace: 'Playground',
    nodes: [
      ExtendedTextNode,
      {
        replace: TextNode,
        with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key),
        withKlass: ExtendedTextNode,
      },
      ...PlaygroundNodes,
    ],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <SettingsContext>
      <LexicalComposer initialConfig={initialConfig}>
        <LoadInitialContent initialContent={value ?? ''} />
        <SharedHistoryContext>
          <TableContext>
            <SharedAutocompleteContext>
              <div className="editor-shell">
                <Editor onChange={onChange} />
              </div>
            </SharedAutocompleteContext>
          </TableContext>
        </SharedHistoryContext>
      </LexicalComposer>
    </SettingsContext>
  );
}

const LexicalEditor = dynamic(
  () => import('@/components/LexicalEditor/LexicalEditor'),
  { ssr: false },
);

type RHFP = {
  name: string;
  required?: boolean;
};

const RHFLexicalEditor = (props: RHFP) => {
  const { name, required } = props;
  const { control, watch } = useFormContext();
  const id = watch('id');

  return (
    <>
      <Controller
        key={id} // id가 변경될 때마다 새로 렌더링
        render={({ field }) => (
          <LexicalEditor
            onChange={(editorState, editor) => {
              editor.update(() => {
                const htmlString = $generateHtmlFromNodes(editor, null);
                field.onChange(htmlString);
              });
            }}
            value={field.value}
          />
        )}
        name={name}
        control={control}
        defaultValue=""
        rules={{
          required: required ?? false,
        }}
      />
    </>
  );
};

export { LexicalEditor, RHFLexicalEditor };
