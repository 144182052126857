import styled from '@emotion/styled';
import Image from 'next/image';

const StyledTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
`;
const StyledListTitle = styled.div`
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const StyledListSubTitle = styled.div`
  font-size: 12px;
  color: #888d96;
  line-height: 20px;
  font-weight: 400;
`;

const LayoutImage = styled(Image)<{ isSelected: boolean }>`
  border-radius: 50%;
  object-fit: cover;
  border: ${({ isSelected }) => (isSelected ? '1px solid #0066cc' : '')};
  padding: 4px;
  box-sizing: content-box;
`;

const DropdownContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const DropdownHeader = styled.div<{ isOpen: boolean }>`
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #e7e9ec;
  border-radius: 6px;
  padding: 10px 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: ${({ isOpen }) => (isOpen ? 'black' : '#e7e9ec')};
  &:hover {
    border-color: black;
  }
`;

const DropdownList = styled.ul<{ isFocus: boolean }>`
  margin: 0;
  padding: 12px 8px;
  list-style: none;
  border: 1px solid black;
  border-radius: 6px;
  background: #ffffff;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 4px;
  visibility: ${({ isFocus }) => (isFocus ? 'visible' : 'hidden')};
  opacity: ${({ isFocus }) => (isFocus ? '1' : '0')};
  transition: all 0.2s ease-in;
`;

const DropdownListItem = styled.li<{ isSelected: boolean }>`
  padding: 8px 4px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#F9FAFB' : '#ffffff')};
  color: black;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  &:hover {
    background-color: #f9fafb;
  }
`;

export {
  LayoutImage,
  StyledTitle,
  StyledListTitle,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListItem,
  StyledListSubTitle,
};
