import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const Title = styled(Typography)(({ theme }) => ({
  color: '#000',
  margin: 0,
  padding: 0,

  '&.required::after': {
    content: '""',
    display: 'inline-block',
    width: '4px',
    height: '4px',
    borderRadius: '2px',
    background: '#F55060',
    marginLeft: '4px',
    marginTop: '4px',
    marginRight: '4px',
    verticalAlign: 'top',
  },
}));

const Description = styled(Typography)(() => ({
  color: '#888D96',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
  mt: '4px',
}));

export { Title, Description };
