import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import {
  updateProfileImage,
  useFindPassport,
  useMyInfo,
} from '@/hooks/apiHooks/index';
import { useCallback, useState } from 'react';
import { MyInfo, Passport } from '@/types/api';

const useMutateMyInfo = (passportId: string) => {
  const setMyInfoState = useSetRecoilState(MyInfoState);
  const { mutate: updatePassportData } = useFindPassport(passportId);
  const [isLoading, setIsLoading] = useState(false);

  const update = useCallback(async (updateData: MyInfo) => {
    try {
      setIsLoading(true);
      setMyInfoState({ ...updateData });

      await updatePassportData<Passport>();
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    update,
  };
};

export default useMutateMyInfo;
