import { FormProvider as Form, UseFormReturn } from 'react-hook-form';
import { PropsWithChildren } from 'react';

export type FormProviderProps = PropsWithChildren<{
  methods: UseFormReturn<any>;
  onSubmit?: (e: React.FormEvent) => void;
  id?: string;
}>;

export default function FormProvider({
  children,
  onSubmit,
  methods,
  id,
}: FormProviderProps) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} id={id}>
        {children}
      </form>
    </Form>
  );
}
