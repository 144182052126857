import { Crop } from 'react-image-crop';

const createCanvas = (width: number, height: number) => {
  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;

  return canvas;
};

export const fileToImage = (file?: File) =>
  file ? URL.createObjectURL(new Blob([file], { type: file.type })) : '';

export const imgSrcToImage = (imgSrc: string): HTMLImageElement => {
  const image = new Image();
  image.src = imgSrc;

  return image;
};

export const resizeImage = (
  imgSrc: HTMLImageElement | string,
  scale: number,
  format = 'image/jpeg',
) => {
  const image = typeof imgSrc === 'string' ? imgSrcToImage(imgSrc) : imgSrc;

  const canvas = createCanvas(
    parseInt(`${image.width * scale}`),
    parseInt(`${image.height * scale}`),
  );

  const ctx = canvas.getContext('2d');
  ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL(format);
};

export const getCroppedImgFromOriginal = (
  imageSrc: string | HTMLImageElement,
  crop: Crop,
  scale: number,
  format = 'image/jpeg',
) => {
  const croppedImageWidth = crop.width / scale;
  const croppedImageHeight = crop.height / scale;
  const croppedImage = createCanvas(croppedImageWidth, croppedImageHeight);
  const sourceImage =
    typeof imageSrc === 'string' ? imgSrcToImage(imageSrc) : imageSrc;
  const startCoordinateX = crop.x / scale;
  const startCoordinateY = crop.y / scale;

  croppedImage
    .getContext('2d')
    ?.drawImage(
      sourceImage,
      startCoordinateX,
      startCoordinateY,
      croppedImageWidth,
      croppedImageHeight,
      0,
      0,
      croppedImageWidth,
      croppedImageHeight,
    );

  return croppedImage.toDataURL(format);
};

export const calculateCenteredCrop = (
  imageWidth: number,
  imageHeight: number,
  aspectRatio: number,
): {
  x: number;
  y: number;
  width: number;
  height: number;
} => {
  const imageAspectRatio = imageWidth / imageHeight;

  let cropWidth: number;
  let cropHeight: number;

  if (imageAspectRatio > aspectRatio) {
    cropHeight = imageHeight;
    cropWidth = cropHeight * aspectRatio;
  } else {
    cropWidth = imageWidth;
    cropHeight = cropWidth / aspectRatio;
  }

  const cropX = (imageWidth - cropWidth) / 2;
  const cropY = (imageHeight - cropHeight) / 2;

  return {
    x: cropX,
    y: cropY,
    width: cropWidth,
    height: cropHeight,
  };
};
