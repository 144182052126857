import { WrappedAutoCompleteProps } from '@/components/AutoCompleteHighLightsSelect/common';
import { FieldError, useFormContext } from 'react-hook-form';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { AutocompleteValue } from '@mui/material';
import AutoCompleteHighLightsSelect from '@/components/AutoCompleteHighLightsSelect';

export default function RHFAutoCompleteHighLightsSelect<
  TOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: {
    name: string;
    helperText?: string;
  } & WrappedAutoCompleteProps<TOption, Multiple, DisableClearable, FreeSolo>,
) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const { name, helperText, ...other } = props;
  const { valueKey } = other;
  const [error, setError] = useState<FieldError>();
  const optionValue = (option: string | TOption) =>
    typeof option === 'string' ? option : valueKey ? option[valueKey] + '' : '';

  useEffect(() => {
    setError(errors[name] as FieldError);
  }, [errors[name]]);

  const handleChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<
      string | TOption,
      Multiple,
      DisableClearable,
      FreeSolo
    >,
  ) => {
    if (!Array.isArray(value)) {
      if (value) {
        setValue(name, optionValue(value as string | TOption), {
          shouldValidate: true,
        });
      } else {
        setValue(name, '', {
          shouldValidate: true,
        });
      }
    }
  };

  return (
    <AutoCompleteHighLightsSelect
      {...other}
      onChange={handleChange}
      error={error}
      helperText={helperText}
    />
  );
}
