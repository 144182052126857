import {
  HeaderLayout,
  Layout,
  RowLayout,
  StyledContent,
  StyledLink,
} from '@/components/Profile/SecondItem/MailSignature/styles';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import React from 'react';
import { SignatureItem } from '@/components/Profile/SecondItem/MailSignature/SignatureItem';
import SloganItem from '@/components/Profile/SecondItem/MailSignature/SloganItem';

import { SignatureCard } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/MailSignature/@hooks/constants';

enum Color {
  Pink = '#f284ae',
  Green = '#527f6a',
  Blue = '#18345b',
}

const SignatureFNFCards: SignatureCard[] = [
  {
    title: 'Type 1. 로고 있는 서명',
    color: Color.Blue,
    hasLogo: true,
  },
  { title: 'Type 2. 로고 없는 서명', color: Color.Blue, hasLogo: false },
];

const SignatureFNCoCards: SignatureCard[] = [
  {
    title: 'Type 1-1. 로고 있는 서명 (분홍)',
    color: Color.Pink,
    hasLogo: true,
  },
  {
    title: 'Type 1-2. 로고 있는 서명 (초록)',
    color: Color.Green,
    hasLogo: true,
  },
  {
    title: 'Type 2-1. 로고 없는 서명 (분홍)',
    color: Color.Pink,
    hasLogo: false,
  },
  {
    title: 'Type 2-2. 로고 없는 서명 (초록)',
    color: Color.Green,
    hasLogo: false,
  },
];

const MailSignature = () => {
  const { isFnCo } = useRecoilValue(MyInfoState);
  const signatureCards = isFnCo ? (
    <>
      {SignatureFNCoCards.map((card, idx) => (
        <SignatureItem key={`signature-fnco-${idx}`} {...card} />
      ))}
      <SloganItem />
    </>
  ) : (
    <>
      {SignatureFNFCards.map((card, idx) => (
        <SignatureItem key={`signature-fnf-${idx}`} {...card} />
      ))}
    </>
  );

  return (
    <Layout>
      <HeaderLayout>
        <div></div>
        <StyledLink>서명 설정하러 가기</StyledLink>
      </HeaderLayout>
      <StyledContent>
        원하시는 서명을 복사해 Outlook에서 사용하세요.
      </StyledContent>
      <RowLayout>{signatureCards}</RowLayout>
    </Layout>
  );
};

export default MailSignature;
