import React from 'react';
import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import LinkMenuIcon from '@/public/icons/link_menu.svg';
import BlackButton from '@/components/Button/BlackButton';
import { LoginInfoBoxSx } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/PayStubContent/style';
import { DataListGrid, DataListGridItem } from '@/components/DataListGrid';
import { SubContentProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/LanguageSettingContent';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';

const CompanyCodeList = [
  { name: 'F&F', code: 'Q623' },
  { name: 'F&F 홀딩스', code: 'Q526' },
  { name: 'F&F 로지스틱스', code: 'Q739' },
  { name: 'F&F 파트너스', code: 'Q597' },
  { name: 'F&F 엔터테인먼트', code: 'Q734' },
];

export const PayStubContent = ({ myInfo }: SubContentProps) => {
  const { employeeNumber, phoneMobile } = myInfo;
  return (
    <Box padding={'28px 20px'}>
      <ContentDescription>
        iPayView를 통해 급여명세서 조회 및 연말정산이 가능합니다.
        <br />
        *2019년 12월 이전 급여명세서 조회: HR팀 문의
      </ContentDescription>

      <Box component={'section'} sx={LoginInfoBoxSx}>
        <h1>iPayView 로그인 정보</h1>
        <Table className={'companyCode'}>
          <TableHead>
            <TableRow>
              <TableCell>대상 회사명</TableCell>
              <TableCell align="right">코드</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {CompanyCodeList.map((company, index) => (
              <TableRow key={`company-${index}`}>
                <TableCell>{company.name}</TableCell>
                <TableCell align="right">{company.code}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DataListGrid
          rowGap={'8px'}
          columnGap={'12px'}
          gridTemplateColumns={'80px 1fr'}
          sx={{
            marginTop: '24px',
          }}
        >
          <DataListGridItem label={'사번'}>
            {employeeNumber || '포탈 마이페이지에서 확인'}
          </DataListGridItem>
          <DataListGridItem label={'휴대폰'}>{phoneMobile}</DataListGridItem>
          <DataListGridItem label={'비밀번호'}>
            iPayView에서 설정한 비밀번호
          </DataListGridItem>
        </DataListGrid>
      </Box>

      <BlackButton
        href={'https://www.ipayview.com/'}
        target={'_blank'}
        rel={'noreferrer'}
        sx={{ marginTop: '24px' }}
      >
        <SvgIcon
          sx={{
            width: '20px',
            height: '20px',
            marginRight: '4px',
            path: { stroke: 'white' },
          }}
        >
          <LinkMenuIcon />
        </SvgIcon>
        iPayView 열기
      </BlackButton>
    </Box>
  );
};
