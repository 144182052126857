export const isNativeApp = () => {
  if (!window) return;

  return !!window.isRNWebview;
};

export const isAndroid = (userAgent: string) => {
  return userAgent.toLowerCase().indexOf('android') > -1;
};

export const isIOS = (_userAgent: string) => {
  const userAgent = _userAgent.toLowerCase();
  return (
    userAgent.indexOf('iphone') > -1 ||
    userAgent.indexOf('ipad') > -1 ||
    userAgent.indexOf('ipod') > -1
  );
};
