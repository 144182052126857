import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { styled, Theme } from '@mui/material/styles';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { ExtendFile } from '@/components/file-thumbnail/utils';
import { dashhed4 } from '@/theme/palette';
import IntranetLibraryBoxButton from '@/components/@IntranetLibrary/BoxButton';
import MultiFileDragDrop, {
  MultiFileDragDropProps,
} from '@/components/Upload/MultiFileDragDrop';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  padding: theme.spacing(1, 1),
  color: '#888D96',
  borderRadius: '6px',
  backgroundImage: dashhed4,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

export type UploadMultiFileProps = {
  error: boolean;
  sx?: SxProps<Theme>;
  dragPreviewProps?: {
    onDragStart: (e: DragStartEvent) => void;
    onDragEnd: (e: DragEndEvent) => void;
    onDragCancel: () => void;
    onRemove: (file: ExtendFile) => void;
    activeFile: ExtendFile | null;
  };
  placeHolder?: React.ReactNode;
  sortable?: boolean;
} & DropzoneOptions &
  Pick<
    MultiFileDragDropProps,
    'previewType' | 'files' | 'useAddButton' | 'disabled' | 'loading'
  >;

export default function UploadMultiFile({
  files,
  error,
  sx,
  dragPreviewProps,
  placeHolder,
  disabled,
  loading,
  ...other
}: UploadMultiFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      ...other,
    });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} disabled={disabled || loading} />
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction={'column'}
          sx={{ width: 1, textAlign: 'center', padding: '30px' }}
        >
          <Typography
            gutterBottom
            sx={{ fontSize: 12, fontWeight: 400, color: '#888D96' }}
          >
            {placeHolder}
          </Typography>
          <IntranetLibraryBoxButton
            variant={'secondary'}
            sx={{
              width: '140px',
            }}
            disabled={disabled}
            loading={loading}
          >
            파일 선택
          </IntranetLibraryBoxButton>
        </Stack>
      </DropZoneStyle>
      {files.length > 0 && dragPreviewProps !== undefined && (
        <MultiFileDragDrop
          files={files}
          disabled={disabled}
          loading={loading}
          onFileOpen={open}
          {...dragPreviewProps}
          {...other}
        />
      )}
    </Box>
  );
}
