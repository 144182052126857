import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  ButtonSection,
  DetailSection,
  LeaveHistoryTable,
  PolicySection,
  StyledDivider,
  SummarySubTitle,
} from '@/components/Profile/SecondItem/Vacation/styles';
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import useVacationProps from './@hooks/useVacationProps';
import classNames from 'classnames';
import IntranetLibrarySelect from '@/components/@IntranetLibrary/Select';
import React from 'react';
import IntranetLibraryBoxButton from '@/components/@IntranetLibrary/BoxButton';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';

const errorMessage = '에러가 발생했어요.';

const Vacation = () => {
  const {
    status,
    applyButtonOnClick,
    prevYear,
    prevYearData,
    yearOptions,
    ...props
  } = useVacationProps();

  return (
    <>
      {status.status === 'loading' && (
        <Accordion>
          <AccordionHeader>
            <Skeleton animation={'wave'} width="80%" />
          </AccordionHeader>
          <AccordionBody>
            <ContentDescription
              sx={{
                gap: '4px',
              }}
            >
              <Skeleton animation={'wave'} width={50} height={30} />
              <Skeleton animation={'wave'} height={30} />
              <Skeleton animation={'wave'} height={30} />
              <Skeleton animation={'wave'} width={50} height={30} />
              <Skeleton animation={'wave'} height={30} />
              <Skeleton animation={'wave'} height={30} />
            </ContentDescription>
          </AccordionBody>
        </Accordion>
      )}
      {status.status === 'error' && (
        <ContentDescription>
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            {errorMessage}
          </Typography>
          <IntranetLibraryBoxButton
            variant={'secondary'}
            size={'small'}
            onClick={status.retry}
            type={'button'}
            sx={{
              height: '36px',
              width: '100px',
              margin: '0 auto',
            }}
          >
            재시도
          </IntranetLibraryBoxButton>
        </ContentDescription>
      )}
      {status.status === 'success' && (
        <Accordion>
          <AccordionHeader>
            현재 잔여 휴가는{' '}
            <span className={'primary'}>{status.data.remainingDays}</span>일
            입니다.
          </AccordionHeader>
          <AccordionBody>
            <ContentDescription
              divider={<StyledDivider orientation="horizontal" flexItem />}
            >
              {status.data.monthly && (
                <>
                  <SummarySubTitle>월차</SummarySubTitle>
                  <ListItem
                    items={[
                      {
                        title: '부여',
                        content: `+${status.data.monthly.granted}${t('일')}`,
                      },
                      {
                        title: '사용',
                        content: `-${status.data.monthly.used}${t('일')}`,
                        isRed: true,
                      },
                    ]}
                  />
                </>
              )}
              {status.data.annual && (
                <>
                  <SummarySubTitle>연차</SummarySubTitle>
                  <ListItem
                    items={[
                      {
                        title: '부여',
                        content: `+${status.data.annual.granted}${t('일')}`,
                      },
                      {
                        title: '사용',
                        content: `-${status.data.annual.used}${t('일')}`,
                        isRed: true,
                      },
                    ]}
                  />
                </>
              )}
              {status.data.overages > 0 && (
                <ListItem
                  items={[
                    {
                      title: '전년 초과 사용',
                      content: `-${status.data.overages}${t('일')}`,
                      isRed: true,
                    },
                  ]}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '8px',
                  borderTop: '1px solid #000',
                  paddingTop: '12px',
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: 700,
                    color: '#000',
                  }}
                >
                  잔여휴가
                </Typography>
                <Typography
                  sx={{
                    flex: 'none',
                    fontSize: '20px',
                    lineHeight: '28px',
                    fontWeight: 700,
                    color: '#0066CC',
                  }}
                >
                  {status.data.remainingDays}
                  {t('일')}
                </Typography>
              </Box>
            </ContentDescription>
          </AccordionBody>
        </Accordion>
      )}

      <ButtonSection>
        <IntranetLibraryBoxButton
          variant={'primary'}
          size={'large'}
          onClick={applyButtonOnClick}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="20 / Link">
              <path
                id="Vector 637"
                d="M8.75 5H5V15H15V11.25"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector 638"
                d="M11.25 5L15 5L15 8.75"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector 639"
                d="M14.714 5L9.99994 9.71405"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>

          {t('연차 신청')}
        </IntranetLibraryBoxButton>
      </ButtonSection>

      <DetailSection>
        <Divider sx={{ mb: '24px' }} />
        <Box sx={{ display: 'flex', width: '100%', mb: '8px' }}>
          <Stack flex={1} gap={'4px'}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '20px',
              }}
            >
              {prevYear}년 휴가사용 상세내역
            </Typography>
            <Typography
              sx={{
                color: '#888D96',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '18px',
              }}
            >
              연도별 기준 휴가 발급 및 사용내역
            </Typography>
          </Stack>
          <IntranetLibrarySelect
            options={yearOptions}
            value={`${prevYear}`}
            onChange={props.yearOnChange}
            disablePortal={true}
            sx={{
              minWidth: '100px',
            }}
          />
        </Box>
        <LeaveHistoryTable>
          <thead>
            <tr className={'right'}>
              <th>사용일자</th>
              <th>분류</th>
              <th>수</th>
            </tr>
          </thead>
          {prevYearData.status === 'error' && (
            <tbody>
              <tr>
                <td colSpan={3} style={{ padding: '50px 0' }}>
                  {errorMessage}
                  <Box sx={{ marginTop: '10px' }}>
                    <IntranetLibraryBoxButton
                      variant={'secondary'}
                      size={'small'}
                      onClick={prevYearData.retry}
                      type={'button'}
                      sx={{
                        height: '36px',
                      }}
                    >
                      재시도
                    </IntranetLibraryBoxButton>
                  </Box>
                </td>
              </tr>
            </tbody>
          )}
          {prevYearData.status === 'loading' && (
            <tbody>
              {[0, 1, 2, 3, 4, 5].map((item) => (
                <tr key={item}>
                  <td colSpan={3}>
                    <Skeleton animation={'wave'} />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {prevYearData.status === 'success' && (
            <>
              <tbody>
                {prevYearData.data.length > 0 ? (
                  prevYearData.data.map(
                    (
                      { onClick, hasLink, date, title, detail, isRed },
                      index,
                    ) => (
                      <tr
                        key={index}
                        className={classNames({
                          red: isRed,
                          right: true,
                        })}
                      >
                        <td
                          onClick={onClick}
                          className={classNames({
                            link: hasLink,
                          })}
                        >
                          {date}
                        </td>
                        <td>{title}</td>
                        <td>{detail}</td>
                      </tr>
                    ),
                  )
                ) : (
                  <tr>
                    <td colSpan={3}>발급된 휴가가 없습니다.</td>
                  </tr>
                )}
              </tbody>
              {prevYearData.granted && (
                <tfoot>
                  {prevYearData.granted.annual > 0 && (
                    <tr>
                      <th colSpan={2}>연차 부여</th>
                      <th>
                        +{prevYearData.granted.annual}
                        {t('일')}
                      </th>
                    </tr>
                  )}
                  {prevYearData.granted.monthly > 0 && (
                    <tr>
                      <th colSpan={2}>월차 부여</th>
                      <th>
                        +{prevYearData.granted.monthly}
                        {t('일')}
                      </th>
                    </tr>
                  )}
                </tfoot>
              )}
            </>
          )}
        </LeaveHistoryTable>
        <Divider sx={{ mt: '32px' }} />
      </DetailSection>

      <PolicySection>
        <Accordion>
          <AccordionHeader>
            근무 시간대별 반차・반반차 사용 안내
          </AccordionHeader>
          <AccordionBody>
            <Typography
              sx={{
                color: '#888D96',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '18px',

                mb: '24px',
              }}
            >
              12~13시 휴게시간이 적용된 시간표입니다.
            </Typography>
            <LeaveHistoryTable>
              <thead>
                <tr>
                  {['', '8 ~ 17시', '9 ~ 18시', '10 ~ 19시'].map(
                    (item, index) => (
                      <th key={index}>{item}</th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody>
                {[
                  ['오전 반반차', '10시 출근', '11시 출근', '13시 출근'],
                  ['오전 반차', '13시 출근', '14시 출근', '15시 출근'],
                  ['오후 반차', '12시 퇴근', '14시 퇴근', '15시 퇴근'],
                  ['오후 반반차', '15시 퇴근', '16시 퇴근', '17시 퇴근'],
                ].map((content, index) => (
                  <tr key={index}>
                    {content.map((item) => (
                      <td key={item}>{item}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </LeaveHistoryTable>
          </AccordionBody>
        </Accordion>
      </PolicySection>
    </>
  );
};

export default Vacation;

const ListItem = ({
  items,
}: {
  items: { title: string; content: string; isRed?: boolean }[];
}) => {
  return (
    <Stack gap={'8px'}>
      {items.map(({ title, content, isRed }, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '8px',
          }}
        >
          <Typography
            sx={{
              flex: 1,
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 400,
              color: '#888D96',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              flex: 'none',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 400,
              color: isRed ? '#F55060' : '#000000',
            }}
          >
            {content}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};
