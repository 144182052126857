import styled from '@emotion/styled';
import { Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageWithErrorHandling from '@/components/ImageWithErrorHandling';
import { maxBreakpoints } from '@/utils/mediaQuery';

const CustomDrawer = styled(Drawer)`
  position: fixed;
  z-index: 1400;
`;
CustomDrawer.defaultProps = {
  anchor: 'right',
};

const StyledContainer = styled.div`
  position: relative;
  padding: 3.5rem 2rem;
  width: 400px;
  height: 100vh;
`;

const CustomCloseIcon = styled(CloseIcon)`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.5rem;
  position: fixed;
  top: 2rem;
  right: 2rem;
  transition: all 0.2s ease-in-out;
  background-color: var(--button-background-color);
  color: var(--text-primary-color);
  &:hover {
    background-color: var(--text-primary-color);
    color: var(--button-background-color);
  }
`;

const BottomButton = styled.button`
  position: fixed;
  display: flex;
  width: inherit;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  height: 5vh;
  background-color: ${({ theme }) => theme.colors.dark};
`;
BottomButton.defaultProps = {
  type: 'button',
};

const DisplayName = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 0.75rem;
`;

const ProfileContainer = styled.div<{ color?: string }>`
  font-size: 1rem;
  margin-top: 0.75rem;
  color: ${({ color }) => color || 'var(--text-secondary-color)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 7rem;
`;

const StyledButton = styled.button`
  min-width: max-content;
  color: white;
  gap: 0.5rem;
  display: flex;
  width: calc(1.5em + 1.65rem + 2px);
  height: calc(1.5em + 1.65rem + 2px);
  justify-content: center;
  align-items: center;
  border-radius: 0.42rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const NickName = styled.div<{ color?: string }>`
  @media screen and (${maxBreakpoints.s}) {
    display: none;
  }
  min-width: max-content;
  color: ${({ color }) => color};
`;

const StyledImg = styled(ImageWithErrorHandling)`
  object-fit: cover;
  border-radius: 50%;
  min-width: 10rem;
  min-height: 10rem;
  width: 2rem;
  height: 2rem;
`;

const StyledMailLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyleCopyLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

StyledMailLink.defaultProps = {
  href: 'https://outlook.office.com/mail/',
  target: '_blank',
};

const StyledButtonImg = styled(ImageWithErrorHandling)`
  object-fit: cover;
  border-radius: 100% !important;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0 9px 16px 0 rgba(24, 28, 50, 0.25);
  color: ${({ theme }) => theme.colors.textSecondary};
  background-color: white;
  transition: opacity 0.1s ease-in-out;
  right: 0;
  bottom: 1.25rem;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`;

StyledLabel.defaultProps = {
  htmlFor: 'inputImage',
};

const StyleInput = styled.input`
  display: none;
`;

StyleInput.defaultProps = {
  id: 'inputImage',
  type: 'file',
  accept: 'image/png, image/jpeg',
};

const StyledCopyButton = styled.button`
  padding: 0;
  margin: 0;
  color: inherit;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

StyledCopyButton.defaultProps = {
  type: 'button',
};

export {
  StyledButtonImg,
  StyledButton,
  NickName,
  StyledImg,
  CustomDrawer,
  CustomCloseIcon,
  DisplayName,
  ProfileContainer,
  FirstContainer,
  SecondContainer,
  StyledContainer,
  BottomButton,
  StyledLabel,
  StyleInput,
  StyleCopyLayout,
  StyledCopyButton,
};
