import { Box, styled, Typography } from '@mui/material';

const Header = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const OptionWrapper = styled(Box)(() => ({
  background: '#f7f7f7',
  marginTop: '8px',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const ImageUploadWrapper = styled(Box)(() => ({
  marginBottom: `${40 - 12}px`,
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const ImageUploadLabel = styled(Typography)(() => ({
  color: '#000',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  marginBottom: '12px',
}));

export const ImageUploadFormWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
}));

export const GrayText = styled(Typography)(() => ({
  color: '#888D96',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
}));

export const PreviewWrapper = styled(Box)(() => ({
  display: 'inline-block',
  maxWidth: '100%',
}));

export const PreviewHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const PreviewBody = styled(Box)(() => ({
  marginTop: '4px',
  borderRadius: '8px',
  padding: '12px',
}));

export const PreviewImg = styled(Box<'img'>)(({ theme }) => ({
  width: '100%',
  height: 'auto',
}));

export const PreviewTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '20px',
}));

export const PreviewContentSummary = styled(Typography)(() => ({
  color: '#000',
  fontSize: '14px',
  fontWeight: '400',

  overflow: 'hidden',
  textOverflow: 'ellipsis',

  width: '100%',
  whiteSpace: 'nowrap',
  webkitLineClamp: '2',
  wordWrap: 'break-word',
  maxWidth: '100%',
  lineHeight: '20px',
}));

export const DisplayDateWrapper = styled(Box)(() => ({}));
export const DisplayDateHeader = Header;
export const DisplayDateBody = styled(Box)(() => ({
  marginTop: '12px',
  display: 'flex',
  alignItems: 'start',
  gap: '12px',
}));

export const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const PopupContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '32px',
  width: '500px',
  background: '#fff',
  borderRadius: '16px',
}));
export const PopupHeader = Header;
export const PopupHeaderTitle = styled(Typography)(() => ({
  color: '#000',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '24px',
}));

export const PopupBody = styled(Box)(() => ({
  // border: '3px solid #000',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));
export const PopupFooter = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
}));

export const GuideText = styled(Typography)(() => ({
  color: '#888D96',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
}));

export const ErrorMessage = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '18px',
}));

export const ErrorText = styled(Typography)(() => ({
  color: '#F55060',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '18px',
}));
