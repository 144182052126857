import { BooleanOption } from '@/components/Board/BoardForm/@hooks/useBoardForm';

export const DisplaySectionOptions = [
  {
    value: 'importantSection',
    label: '좌측 Important Notice',
  },
  {
    value: 'interviewSection',
    label: '좌측 Interviews',
  },
  {
    value: 'bannerSection',
    label: '메인 배너',
  },
  {
    value: 'rightFixSection',
    label: '우측 상단 고정',
  },
  {
    value: 'rightSection',
    label: '우측 목록 ',
  },
  {
    value: 'none',
    label: '미노출하기',
  },
];

export const CommentPositionOptions = [
  {
    id: 'footerSection',
    selected_image: (
      <svg
        width="76"
        height="64"
        viewBox="0 0 76 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="75" height="63" rx="3.5" stroke="black" />
        <rect x="16" y="8" width="44" height="20" fill="#F3F5F6" />
        <rect
          x="15.5"
          y="41.5"
          width="44"
          height="15"
          rx="3.5"
          fill="white"
          stroke="#B7BBC2"
        />
        <rect
          x="15.5"
          y="31.5"
          width="44"
          height="6"
          rx="3"
          fill="white"
          stroke="#B7BBC2"
        />
      </svg>
    ),
    image: (
      <svg
        width="76"
        height="64"
        viewBox="0 0 76 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="75"
          height="63"
          rx="3.5"
          stroke="#B7BBC2"
        />
        <rect x="16" y="8" width="44" height="20" fill="#F3F5F6" />
        <rect
          x="15.5"
          y="41.5"
          width="44"
          height="15"
          rx="3.5"
          fill="white"
          stroke="#B7BBC2"
        />
        <rect
          x="15.5"
          y="31.5"
          width="44"
          height="6"
          rx="3"
          fill="white"
          stroke="#B7BBC2"
        />
      </svg>
    ),
    label: '게시글 아래',
  },
  {
    id: 'rightSection',
    selected_image: (
      <svg
        width="77"
        height="64"
        viewBox="0 0 77 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.625977"
          y="0.5"
          width="75"
          height="63"
          rx="3.5"
          stroke="black"
        />
        <rect
          x="43.626"
          y="46.5"
          width="26"
          height="6"
          rx="3"
          stroke="#B7BBC2"
        />
        <rect x="7.12598" y="11" width="32" height="42" fill="#F3F5F6" />
        <rect
          x="43.626"
          y="10.5"
          width="26"
          height="33"
          rx="3.5"
          stroke="#B7BBC2"
        />
      </svg>
    ),
    image: (
      <svg
        width="77"
        height="64"
        viewBox="0 0 77 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="43.626"
          y="46.5"
          width="26"
          height="6"
          rx="3"
          stroke="#B7BBC2"
        />
        <rect
          x="0.625977"
          y="0.5"
          width="75"
          height="63"
          rx="3.5"
          stroke="#B7BBC2"
        />
        <rect x="7.12598" y="11" width="32" height="42" fill="#F3F5F6" />
        <rect
          x="43.626"
          y="10.5"
          width="26"
          height="33"
          rx="3.5"
          stroke="#B7BBC2"
        />
      </svg>
    ),
    label: '게시글 오른쪽',
  },
];

export const UploadTimeOptions = [
  { value: BooleanOption.FALSE, label: '바로 올리기' },
  { value: BooleanOption.TRUE, label: '예약하기' },
];

export const ImportantOptions = [
  { value: BooleanOption.TRUE, label: '설정하기' },
  { value: BooleanOption.FALSE, label: '설정 안하기' },
];
