import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>{children}</Box>
))(() => ({
  backgroundColor: 'black',
  color: 'white',
  position: 'relative',
}));

export const SectionContainer = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>{children}</Box>
))(() => ({
  padding: '12px 0',
}));

export const Header = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
    <Box {...props} ref={ref} component="div">
      {children}
    </Box>
  )),
)(() => ({
  position: 'sticky',
  top: 0,
  backgroundColor: 'black',
  zIndex: 2,
}));

export const CategoryWrapper = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
    <Box {...props} ref={ref} component="div">
      {children}
    </Box>
  )),
)(() => ({
  display: 'flex',
  gap: '8px',
  overflowX: 'scroll',
  whiteSpace: 'nowrap',
  padding: '0 20px',
}));

export const Category = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, ...props }, ref) => (
      <Button {...props} ref={ref}>
        {children}
      </Button>
    ),
  ),
)(() => ({
  marginRight: ' 8px',
  padding: '8px 16px',

  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  textTransform: 'uppercase',
  borderRight: '6px',
  border: '1px solid #E7E9EC',
  color: '#5F636B',
  backgroundColor: '#ffffff',

  '&.curr': {
    color: '#ffffff',
    backgroundColor: '#34383D',
  },

  ':hover': {
    border: '1px solid #E7E9EC',
    color: '#5F636B',
    backgroundColor: '#ffffff',

    '&.curr': {
      color: '#ffffff',
      backgroundColor: '#34383D',
      border: '1px solid #E7E9EC',
    },
  },
}));

export const ListWrapper = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>{children}</Box>
))(() => ({
  position: 'relative',
}));

export const MenuSectionWrapper = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
    <Box {...props} ref={ref} component="div">
      {children}
    </Box>
  )),
)(() => ({
  padding: '24px 20px',
}));

export const MenuSectionTitle = styled(
  ({ children, ...props }: TypographyProps) => (
    <Typography component="h3" {...props}>
      {children}
    </Typography>
  ),
)(() => ({
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '20px',
  fontWeight: 400,
  color: '#888D96',
}));
