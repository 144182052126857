import React from 'react';
import { Box, Link, LinkProps, Stack, StackProps } from '@mui/material';

import { MyPageMainProps } from '@/components/MenuDrawer/MyMenuDrawer/V2/Main';
import { styled } from '@mui/material/styles';

export const OutlookLink = styled((props: LinkProps) => <Link {...props} />)(
  () => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
    padding: '12px 16px',
    borderRadius: '6px',
    border: '1px solid #E7E9EC',
    gap: '4px',
    color: '#000',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    textDecoration: 'none',

    '& img': {
      width: '20px',
      height: '20px',
    },
  }),
);
