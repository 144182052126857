import React from 'react';
import { Grid, GridProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GlobalMenuGridItem = styled(
  ({ children, ...props }: GridProps) => (
    <Grid item component={'section'} {...props}>
      {children}
    </Grid>
  ),
)(() => ({
  width: '100%',
  padding: '0 24px',
}));

export const SectionTitle = styled(
  ({ children, ...props }: TypographyProps) => (
    <Typography {...props}>{children}</Typography>
  ),
)(() => ({
  display: 'block',
  color: '#888D96',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  textTransform: 'uppercase',
  borderBottom: '1px solid #888D96',
  paddingBottom: '12px',
  marginBottom: '12px',
  '@media (min-width: 1921px)': {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));
