import React from 'react';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

type IntranetLibraryDatePickerProps = DatePickerProps<Date> & {
  sx?: SxProps<Theme>;
  placeholder?: string;
  error?: { message?: string };
};

const IntranetLibraryDatePicker: React.FC<IntranetLibraryDatePickerProps> = ({
  sx,
  slots,
  placeholder,
  error,
  ...datePickerProps
}) => {
  return (
    <DatePicker
      {...datePickerProps}
      label={placeholder}
      sx={{
        ...DateSelectorNormalizedStyle,
        ...sx,
      }}
      slots={{
        ...slots,
        openPickerIcon: CalendarIcon,
      }}
      slotProps={{
        textField: { helperText: error?.message ?? '', error: Boolean(error) },
      }}
    />
  );
};

export default IntranetLibraryDatePicker;

export const DateSelectorNormalizedStyle = {
  borderRadius: '8px',
  '& .MuiInputBase-root': {
    background: '#ffffff',
    height: '44px',
    boxSizing: 'border-box',
  },
  '.MuiOutlinedInput-notchedOutline': {
    top: 0,
    borderWidth: '1px',
    borderColor: '#E7E9EC',
    '> legend': {
      display: 'none',
    },
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000',
      borderWidth: '1px',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    padding: 0,
    margin: 0,

    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#000 !important',
        borderWidth: '1px !important',
      },
    },
  },
  '.MuiFormLabel-root': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '44px',
    transform: 'none',
    margin: '0 0 0 16px',
    padding: 0,
    color: '#B7BBC2',
    '&.MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  '& .MuiInputAdornment-root': {
    width: '20px',
    height: '20px',
    margin: '0 16px 0 0',
    padding: 0,

    '.MuiButtonBase-root': {
      padding: 0,
      margin: 0,
      width: '100%',
      height: '100%',
    },
  },
  '& .MuiInputBase-input': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    padding: '12px 0 12px 16px',

    '&::placeholder': {
      color: 'transparent',
    },
  },
};

const CalendarIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="10.8316"
      height="2.83432"
      transform="matrix(1 0 0 -1 4.58398 7.52637)"
      stroke="black"
      strokeLinejoin="round"
    />
    <rect
      x="4.58398"
      y="7.52637"
      width="10.8316"
      height="8.28743"
      stroke="black"
      strokeLinejoin="round"
    />
    <path
      d="M7.24023 3.46484L7.24023 5.82812"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7598 3.46484L12.7598 5.82812"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
