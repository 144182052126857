import React from 'react';
import { MenuItem, MenuItemProps } from '@mui/material';
import { ActionType } from '@/constant/button';

type PopMenuItemProps = {
  type: ActionType;
  onClick: () => void;
} & MenuItemProps;

const PopMenuItem = (_props: PopMenuItemProps) => {
  const { type, children, onClick, ...props } = _props;

  return (
    <MenuItem
      data-menu-type={type}
      onClick={() => {
        onClick();
      }}
      {...props}
    >
      {children}
    </MenuItem>
  );
};

export default PopMenuItem;
