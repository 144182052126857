import { FieldError, useFormContext } from 'react-hook-form';
import { AutocompleteValue } from '@mui/material';
import { WrappedAutoCompleteProps } from '@/components/AutoCompleteHighLightsSelect/common';
import * as React from 'react';
import { useEffect, useState } from 'react';
import MultiAutoCompleteHighLightsSelect from '@/components/AutoCompleteHighLightsSelect/multiSelect';

export default function RHFMultiAutoCompleteHighLightsSelect<
  TOption,
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: {
    name: string;
    helperText?: string;
  } & WrappedAutoCompleteProps<TOption, Multiple, DisableClearable, FreeSolo>,
) {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  const { name, helperText, ...other } = props;
  const { valueKey } = other;
  const [error, setError] = useState<FieldError>();
  const optionValue = (option: string | TOption) =>
    typeof option === 'string' ? option : valueKey ? option[valueKey] + '' : '';

  useEffect(() => {
    setError(errors[name] as FieldError);
  }, [errors[name]]);

  const handleChange = (
    event: React.SyntheticEvent,
    value: AutocompleteValue<
      string | TOption,
      Multiple,
      DisableClearable,
      FreeSolo
    >,
  ) => {
    if (Array.isArray(value)) {
      setValue(
        name,
        value.map((v) => optionValue(v)),
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        },
      );
    }
  };

  return (
    <MultiAutoCompleteHighLightsSelect
      {...other}
      onChange={handleChange}
      error={error}
      helperText={helperText}
    />
  );
}
