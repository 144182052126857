import {
  Input,
  Layout,
  StyledPrintButton,
} from '@/components/Profile/SecondItem/ProofEmp/PrintEmpProof/styles';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CertifyForm, PrintParams, usePrintEmpProof } from '@/hooks/apiHooks';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';

type P = {
  certifyData: CertifyForm;
  handleInit: () => void;
};

type FormType = {
  outputReason: string;
};

const PrintEmpProof = ({ certifyData, handleInit }: P) => {
  const { passportId } = useRecoilValue(MyInfoState);
  const { register, handleSubmit } = useForm<FormType>();

  const onSubmitToPrint: SubmitHandler<FormType> = (data) => {
    usePrintEmpProof({
      ...{
        passportId,
      },
      ...data,
      ...certifyData,
    })
      .then((res) => {
        if (window.isRNWebview) {
          const sendData = JSON.stringify({
            actionType: 'OPEN_APP',
            url: res,
          });
          window?.ReactNativeWebView?.postMessage(sendData);
        } else {
          window.open(res, '_blank', 'noopener, noreferrer');
        }
        handleInit();
      })
      .catch((e) => alert(e));
  };

  return (
    <form onSubmit={handleSubmit(onSubmitToPrint)}>
      <Layout>
        <Input
          type={'text'}
          placeholder={'용도'}
          {...register('outputReason')}
        />
      </Layout>
      <StyledPrintButton>발급</StyledPrintButton>
    </form>
  );
};

export default PrintEmpProof;
