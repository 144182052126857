import React from 'react';
import { Box, BoxProps, ButtonProps, styled } from '@mui/material';
import Button from '@mui/material/Button';

export const Wrapper = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>{children}</Box>
))(() => ({
  color: 'black',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  wordWrap: 'break-word',
  '& img': { width: '100%' },
}));

export const Tag = styled(({ children, ...props }: ButtonProps) => (
  <Button {...props} disableRipple>
    {children}
  </Button>
))(() => ({
  padding: '0 4px',
  margin: 0,
  borderRadius: '12px',
  border: 'none',
  backgroundColor: '#F3F5F6',
  color: '#000',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  verticalAlign: 'bottom',

  '&:focus': {
    backgroundColor: '#E5E9EB',
    color: '#000',
  },
  '&:hover': {
    backgroundColor: '#E5E9EB',
    color: '#000',
  },
  '&.Mui-disabled': {
    background: '#F9FAFB',
    color: '#D1D8E2',
  },
}));
