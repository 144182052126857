import { styled } from '@mui/material/styles';
import { IconButton, IconButtonProps, Stack, StackProps } from '@mui/material';
import React from 'react';
import IntranetLibraryBoxButton, {
  BoxButtonProps,
} from '@/components/@IntranetLibrary/BoxButton';

export const ProfileEditButton = styled(
  ({ children, ...props }: IconButtonProps) => (
    <IconButton {...props}>{children}</IconButton>
  ),
)(() => ({
  position: 'absolute',
  top: `${200 - 16}px`,
  left: `calc(50% + ${90 - 16}px)`,
  width: '24px',
  height: '24px',
  borderRadius: '12px',
  border: '1px solid #000',
  boxShadow:
    '0px 0px 1.5px 0px rgba(0, 0, 0, 0.12), 0px 12px 12px 0px rgba(0, 0, 0, 0.12)',
  backdropFilter: 'blur(7.5px)',
  background: '#fff',
  padding: 0,
  margin: 0,
  '&:hover': {
    background: '#fff',
  },
}));

export const Form = styled(({ children, ...props }: StackProps) => (
  <Stack {...props}>{children}</Stack>
))(() => ({
  padding: '28px 20px 24px',
}));

export const SubmitButton = styled(({ children, ...props }: BoxButtonProps) => (
  <Stack
    sx={{
      padding: '24px 20px 32px',
    }}
  >
    <IntranetLibraryBoxButton {...props}>{children}</IntranetLibraryBoxButton>
  </Stack>
))(() => ({}));
