import React from 'react';
import { Box } from '@mui/material';

import { MyPageMainProps } from 'components/MenuDrawer/MyMenuDrawer/V2/Main';

// TODO 재직증명서 발급
const ProofEmploymentContent = (props: MyPageMainProps) => {
  return (
    <Box>
      <div>재직증명서 발급 해보자아아아아</div>
    </Box>
  );
};

export default ProofEmploymentContent;
