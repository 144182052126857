import { styled } from '@mui/material/styles';
import { Stack, StackProps } from '@mui/material';
import React from 'react';

const ContentDescription = styled((props: StackProps) => <Stack {...props} />)(
  () => ({
    padding: '20px 12px',
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
    gap: '12px',
    fontSize: '14px',
    color: '#888D96',
  }),
);

export default ContentDescription;
