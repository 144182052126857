import React from 'react';
import { Box } from '@mui/material';

import { MyPageMainProps } from 'components/MenuDrawer/MyMenuDrawer/V2/Main';

// TODO 비밀번호 관리 작업
const PasswordSettingContent = (props: MyPageMainProps) => {
  return (
    <Box>
      <div>비밀번호 관리 해보자아아아아</div>
    </Box>
  );
};

export default PasswordSettingContent;
