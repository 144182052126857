import { AnchorHTMLAttributes } from 'react';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

// ButtonBase LinkComponent 사용 시 AnchorElement props 가 필요함
type BlackButtonProps = ButtonBaseProps &
  Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target' | 'rel'>;

export default function BlackButton(_props: BlackButtonProps) {
  const { children, sx, ...props } = _props;

  return (
    <ButtonBase
      {...props}
      sx={{
        display: 'flex',
        width: '100%',
        height: '48px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        color: 'white',
        fontSize: '16px',
        fontWeight: 500,
        borderRadius: '6px',
        cursor: 'pointer',
        '&:disabled, &[data-disabled=true]': {
          color: '#D0D0D0',
          backgroundColor: '#F8F8F8',
        },
        ...sx,
      }}
    >
      {children}
    </ButtonBase>
  );
}
