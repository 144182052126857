import { Theme } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';

export default function Radio(theme: Theme) {
  return {
    MuiRadio: {
      defaultProps: {
        icon: (
          <SvgIcon viewBox={'0 0 20 20'}>
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="9.5"
              fill="white"
              stroke="#B7BBC2"
            />
          </SvgIcon>
        ),
        checkedIcon: (
          <SvgIcon viewBox={'0 0 20 20'}>
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="9.5"
              fill="white"
              stroke="#B7BBC2"
            />
            <rect x="4" y="4" width="12" height="12" rx="6" fill="black" />
          </SvgIcon>
        ),
      },
      styleOverrides: {
        root: {},
      },
    },
  };
}
