import React, { ReactNode, useEffect, useState } from 'react';
import EmployeeContents from '@/components/EmployModal/EmployeeContents';
import { Alert, Dialog, Skeleton, Slide, Snackbar } from '@mui/material';
import { useFindPassport, useSaveSearchData } from '@/hooks/apiHooks';
import {
  CustomDivider,
  ContentsContainer,
  InfoLayout,
  StyledNameFonts,
  StyledDeptFonts,
  StyledImg,
  ImgLayout,
  StyledCloseButton,
  StyledName,
  BottomLayout,
  StyledEmpty,
  StyledContent,
  StyledTitle,
  StyledDate,
  StyleCopyLayout,
  StyledCopyButton,
  Divider,
  Container,
} from './styles';
import { imgUrlChk } from '@/utils/function';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { VIP_NUM } from '@/utils/const';
import Header from 'components/Mobile/Header';
import DirectAsset from '@/components/MobileOrganization/Content/ProfileCardDialog/DirectAsset';
import SvgImage from '@/components/Module/SvgImage';
import { useTranslation } from 'react-i18next';
import { TransitionProps } from '@mui/material/transitions';
import { formatBirthday } from '@/utils/date';

type P = {
  passportId: string;
  open: boolean;
  handleClose: () => void;
};
const ProfileCardDialog = ({ passportId, open, handleClose }: P) => {
  const { data } = useFindPassport(passportId);
  const [toastOpen, setToastOpen] = useState(false);
  const [isImgError, setIsImgError] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const contentData = new Map<string, ReactNode>([
    [t('법인'), data?.detail.companyName],
    [
      `${t('생일')} (${
        data?.detail.birthdayType === 0 ? t('양력') : t('음력')
      })`,
      formatBirthday(data?.detail.birthday),
    ],
    [
      t('이메일'),
      <>
        {data?.detail.email && (
          <CopyToClipboard text={data?.detail.email}>
            <StyledCopyButton
              onClick={() => setToastOpen(true)}
              style={{ marginRight: '0' }}
            >
              {data?.detail.email}
              <SvgImage name={'copy'} />
            </StyledCopyButton>
          </CopyToClipboard>
        )}
      </>,
    ],
    [t('내선전화'), data?.detail.phoneInside],
    [
      t('휴대폰'),
      <>
        {data?.detail.phoneMobile && (
          <CopyToClipboard text={data?.detail.phoneMobile}>
            <StyledCopyButton
              onClick={() => setToastOpen(true)}
              style={{ marginRight: '0' }}
            >
              {data?.detail.phoneMobile}
              <SvgImage name={'copy'} />
            </StyledCopyButton>
          </CopyToClipboard>
        )}
      </>,
    ],
  ]);
  data?.job && contentData.set(t('직무'), data.job);

  useEffect(() => {
    if (data) {
      useSaveSearchData({
        name: data.detail.name,
        passportId: data.passportId,
        positionName: data.detail.positionName,
        organizationShortName: data.detail.organizationShortName,
        profileImageUrl: data.detail.profileImageUrl,
        divisionName: data.detail.divisionName,
        departmentName: data.detail.departmentName,
      });
    }
  }, [data]);

  const noData =
    i18n.language === 'ko'
      ? `등록된 업무소개가 없습니다. ${data?.detail.name}님께 어떤 일을 하는지 소개해달라고 요청해 보세요.`
      : `There's no job description registered. Please ask ${data?.detail.name} to introduce what they do.`;

  return (
    <Dialog
      fullScreen={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Container>
        <Header
          title={t('프로필 카드')}
          leftButton={
            <>
              <StyleCopyLayout>
                <CopyToClipboard
                  text={`${process.env.NEXT_PUBLIC_ORIGIN_URL}?search=${passportId}`}
                >
                  <StyledCopyButton onClick={() => setToastOpen(true)}>
                    <SvgImage name={'link'} />
                  </StyledCopyButton>
                </CopyToClipboard>
              </StyleCopyLayout>
            </>
          }
          rightButton={
            <StyledCloseButton onClick={handleClose} style={{}}>
              <SvgImage name={'dialogClose'} />
            </StyledCloseButton>
          }
        />
        <ImgLayout>
          {data?.detail.profileImageUrl ? (
            <StyledImg
              src={
                isImgError
                  ? '/images/noProfile.svg'
                  : imgUrlChk(data?.detail.profileImageUrl)
              }
              alt={`img-${data?.detail.name}`}
              onError={() => setIsImgError(true)}
            />
          ) : (
            <Skeleton width={'180px'} height={'180px'} variant="circular" />
          )}
        </ImgLayout>
        <InfoLayout>
          {data ? (
            <>
              <StyledName>
                <StyledNameFonts>
                  {`${data?.detail.name} `}
                  <span>{data?.detail.positionName}</span>
                </StyledNameFonts>
              </StyledName>
              <StyledDeptFonts>
                {data?.detail.employeeNumber === VIP_NUM
                  ? '대표이사'
                  : `${data?.detail.divisionName} ${data?.detail.departmentName}`}
              </StyledDeptFonts>
              <StyledDeptFonts>
                {data?.detail.organizationShortName}
              </StyledDeptFonts>
            </>
          ) : (
            <>
              <Skeleton width={'50%'} />
              <Skeleton width={'50%'} />
              <Skeleton width={'50%'} />
            </>
          )}
        </InfoLayout>
        <DirectAsset
          phoneInside={data?.detail.phoneInside}
          phoneMobile={data?.detail.phoneMobile}
          email={data?.detail.email}
        />
        <Divider />
        <ContentsContainer>
          {Array.from(contentData.entries()).map(([key, value], idx) => {
            return (
              <>
                {data ? (
                  <>
                    <EmployeeContents
                      key={`empModal-${idx}`}
                      left={key}
                      right={value}
                    />
                    <CustomDivider />
                  </>
                ) : (
                  <Skeleton
                    width={'252px'}
                    height={'2.15rem'}
                    style={{ marginBottom: '1.5rem' }}
                  />
                )}
              </>
            );
          })}
        </ContentsContainer>
        <BottomLayout>
          <div>{t('업무소개')}</div>
          {data ? (
            data?.jobDescription.summary || data?.jobDescription.content ? (
              <>
                <StyledTitle>{data?.jobDescription.summary}</StyledTitle>
                <StyledContent>{data?.jobDescription.content}</StyledContent>
                <StyledDate>
                  {data?.jobDescription.updatedAt &&
                    `최근 업데이트: ${data?.jobDescription.updatedAt}`}
                </StyledDate>
              </>
            ) : (
              <StyledEmpty>{noData}</StyledEmpty>
            )
          ) : (
            <Skeleton height={'1rem'} />
          )}
        </BottomLayout>

        <Snackbar
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={toastOpen}
          onClose={() => setToastOpen(false)}
        >
          <Alert onClose={() => setToastOpen(false)} severity="success">
            {t('링크가 복사되었습니다.')}
          </Alert>
        </Snackbar>
      </Container>
    </Dialog>
  );
};

export default ProfileCardDialog;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
