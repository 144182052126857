import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { SubContentProps } from '../../LanguageSettingContent';
import { validateFile } from '@/components/file-thumbnail/utils';
import { useCustomDialog } from '@/hooks/useCustomDialog';
import useUpdateProfileImage from '@/hooks/apiHooks/useUpdateProfileImage';
import { EditProfileProps } from '../index';
import useProfileForm, { ProfileForm } from './useProfileForm';
import {
  checkNickname,
  updateNickname,
  useChangedJob,
  useGetJobList,
} from '@/hooks/apiHooks';
import useCustomToast from '@/hooks/useCustomToast';
import useMutateMyInfo from '@/hooks/apiHooks/useUpdateProfileData';

const useEditProfileProps = (settings: SubContentProps): EditProfileProps => {
  const {
    myInfo: { profileImageUrl, nickname = '', job, passportId },
  } = settings;

  const dialog = useCustomDialog();
  const { showSuccess } = useCustomToast();

  const { update: updateMyInfo } = useMutateMyInfo(passportId);

  const { isLoading: isJobListFetching, data: jobList } = useGetJobList();

  const methods = useProfileForm();
  const {
    handleSubmit,
    getFieldState,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { isValid, isDirty },
  } = methods;

  const { update, isLoading: isEditButtonDisabled } = useUpdateProfileImage();

  const [isNickNameAvailable, setIsNickNameAvailable] = useState(false);
  const [isNickNameChecking, setIsNickNameChecking] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const isFormValid = isNickNameAvailable || (isDirty && isValid);

  const resetLocalState = () => {
    setIsNickNameAvailable(false);
    setIsNickNameChecking(false);
    setIsFormSubmitting(false);
  };

  const handleNicknameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNickNameAvailable(false);
    setValue('nickname', e.target.value.trim(), { shouldValidate: true });
  };

  const handleEditButtonOnClick = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    try {
      validateFile([file], {
        isOnlyImage: true,
        maxLength: 1,
      });
    } catch (e) {
      if (e instanceof Error) {
        dialog.show({
          title: '이미지를 다시 선택해주세요',
          message: e?.message,
        });
      } else {
        dialog.show({
          title: '에러가 발생했습니다',
        });
      }
    }

    try {
      await update(file);
    } catch (e) {
      dialog.show({
        title: '이미지를 변경에 실패했습니다.',
      });
    }
  };

  const handleFormOnSubmit = (e: FormEvent) => {
    e.preventDefault();

    handleSubmit(async (data: ProfileForm) => {
      try {
        setIsFormSubmitting(true);

        if (settings.myInfo.nickname !== data.nickname) {
          await updateNickname(passportId, data.nickname);
        }

        if (
          settings.myInfo.job.name !== data.job ||
          settings.myInfo.job.summary !== data.summary ||
          settings.myInfo.job.content !== data.content
        ) {
          await useChangedJob(data);
        }

        //TODO: 업데이트 바로 되도록 수정
        await updateMyInfo({
          ...settings.myInfo,
          nickname,
          job: {
            name: data.job,
            summary: data.summary,
            content: data.content,
          },
        });

        resetLocalState();
        showSuccess({ message: '프로필이 변경되었습니다.' });
      } catch (e) {
        dialog.show({
          title: '정보 수정에 변경에 실패했습니다.',
        });
      } finally {
        setIsFormSubmitting(false);
      }
    })();
  };

  const handleCheckButtonOnClick = async () => {
    const nicknameKey = 'nickname';

    setIsNickNameChecking(true);
    clearErrors(nicknameKey);

    try {
      await checkNickname(getValues(nicknameKey));
      setIsNickNameAvailable(true);
    } catch (e) {
      setError(nicknameKey, {
        type: 'manual',
        message: '이미 사용중인 이름이에요.',
      });
      setIsNickNameAvailable(false);
    } finally {
      setIsNickNameChecking(false);
    }
  };

  useEffect(() => {
    reset({
      nickname: (nickname || '').replace(/[^a-zA-Z]/g, ''),
      job: job.name || '',
      summary: job.summary || '',
      content: job.content || '',
    });
  }, [job, nickname]);

  return {
    nicknameHelperText: isNickNameAvailable
      ? '사용 가능한 이름이에요'
      : '최대 15자까지 가능합니다.',
    nicknameOnChange: handleNicknameOnChange,
    editButtonOnClick: handleEditButtonOnClick,
    isEditButtonDisabled,
    profileImageUrl,
    formProps: { methods, onSubmit: handleFormOnSubmit, id: 'mypage-profile' },

    checkButtonOnClick: handleCheckButtonOnClick,
    isCheckButtonDisabled:
      !getFieldState('nickname') ||
      getValues('nickname') === nickname ||
      isNickNameAvailable,
    isCheckButtonLoading: isNickNameChecking,

    isSubmitButtonDisabled: !isFormValid,
    isFormSubmitting,

    jobOptions: useMemo(
      () =>
        jobList?.data?.map((item) => {
          return `${item.name_kor}`;
        }) ?? [],
      [jobList],
    ),
    jobValue: getValues('job'),
    isJobListFetching,
  };
};

export default useEditProfileProps;
