import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box';

type DataListGridItemProps = { label: string } & PropsWithChildren;
export const DataListGridItem = ({
  label,
  children,
}: DataListGridItemProps) => {
  return (
    <>
      <Typography component={'dt'} sx={{ fontSize: '14px', color: '#888D96' }}>
        {label}
      </Typography>
      <Typography component={'dd'} sx={{ fontSize: '14px', color: 'black' }}>
        {children}
      </Typography>
    </>
  );
};

type DataListGridProps = {
  rowGap?: string;
  columnGap?: string;
  gridTemplateColumns?: string;
} & BoxProps;

export const DataListGrid = (_props: DataListGridProps) => {
  const {
    rowGap = '24px',
    columnGap = '12px',
    gridTemplateColumns = '112px 1fr',
    sx,
    children,
    ...props
  } = _props;

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type !== DataListGridItem) {
      console.error(
        'DataListGrid only accepts DataListGridItem as its children.',
      );
    }
  });

  return (
    <Box
      component={'dl'}
      sx={{
        display: 'grid',
        rowGap,
        columnGap,
        gridTemplateColumns,
        margin: 0,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

DataListGrid.propTypes = {
  children: PropTypes.node.isRequired,
};
