import styled from '@emotion/styled';

import { MailSignatureLink } from '@/components/MenuDrawer/MyMenuDrawer/V2/SubMenu/MailSignature/@hooks/constants';

const RowLayout = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
`;
const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  padding: 20px 0;
`;
StyledLink.defaultProps = {
  href: MailSignatureLink,
  target: '_blank',
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 5rem;
`;

const StyledTitle = styled.div`
  padding: 2rem;
  font-size: 1.25rem;
  font-weight: 600;
`;

const StyledContent = styled.div`
  display: flex;
  margin: 0 2rem 3rem 2rem;
  padding: 3rem 1rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  background-color: rgba(225, 240, 255, 1);
  height: 2rem;
  border-radius: 0.42rem;
`;

export {
  Layout,
  RowLayout,
  HeaderLayout,
  StyledLink,
  StyledTitle,
  StyledContent,
};
