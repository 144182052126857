import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { LanguageValue, MyInfo } from '@/types/api';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import useLanguageSettingContentProps from '@/components/MenuDrawer/MyMenuDrawer/V2/@hooks/useLanguageSettingContentProps';
import ContentDescription from '@/components/MenuDrawer/MyMenuDrawer/V2/@components/ContentDescription';

export type SubContentProps = {
  readonly myInfo: MyInfo;
};

export type LanguageSettingContentProps = Pick<MyInfo, 'language'> & {
  readonly selectedLanguage: LanguageValue;
  readonly handleChange: RadioGroupProps['onChange'];
  readonly selectOptions: { value: string; label: string }[];
};

const LanguageSettingContent = ({ myInfo }: SubContentProps) => {
  const { language, selectedLanguage, handleChange, selectOptions } =
    useLanguageSettingContentProps({ myInfo });

  return (
    <Box padding={'28px 20px'}>
      <ContentDescription>
        언어 변경은 새로고침하여 적용됩니다.
      </ContentDescription>
      <RadioGroup
        defaultValue={language}
        name="language"
        value={selectedLanguage}
        onChange={handleChange}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          marginTop: '28px',
          padding: '24px 0 24px 3px',
          borderTop: '1px solid #E7E9EC',
          borderBottom: '1px solid #E7E9EC',
        }}
      >
        {selectOptions.map(({ value, label }) => (
          <FormControlLabel
            key={`${value}`}
            value={value}
            control={<Radio />}
            label={label}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default LanguageSettingContent;
