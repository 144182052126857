import { Skeleton } from '@mui/material';
import React from 'react';

const JobSkeleton = () => {
  return (
    <div
      style={{
        height: '160.5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '4px',
        }}
      >
        <Skeleton width={45} />
        <Skeleton variant={'rounded'} width={20} height={20} />
      </div>
      <Skeleton variant={'rounded'} width={90} height={21} />
      <Skeleton width={'100%'} />
      <Skeleton width={'100%'} />
      <Skeleton width={'100%'} />
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <Skeleton width={40} />
        <Skeleton variant={'rounded'} width={15} height={15} />
      </div>
    </div>
  );
};
export default JobSkeleton;
