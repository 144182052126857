import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const LoginInfoBoxSx: SystemStyleObject<Theme> = {
  marginTop: '28px',
  padding: '24px 0',
  borderTop: '1px solid #E7E9EC',
  h1: {
    margin: 0,
    fontWeight: 700,
    fontSize: '14px',
    color: 'black',
  },
  'table.companyCode': {
    marginTop: '16px',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    thead: { borderBottom: '1px solid black', th: { padding: '8px 4px' } },
    tbody: {
      tr: {
        borderTop: '1px solid #E7E9EC',
        '&:first-of-type': { borderColor: 'black' },
      },
      td: {
        padding: '12px 4px',
        border: 'none',
        fontSize: '14px',
        '&:first-of-type': { color: '#888D96' },
      },
    },
  },
};
