import React from 'react';
import { Box, BoxProps, Stack } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { imgUrlChk } from '@/utils/function';

type ImageSwiperProps = {
  imageList: string[];
} & BoxProps;

const ImageSwiper = (_props: ImageSwiperProps) => {
  const { imageList, sx, ...props } = _props;
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 0,
        'div[class*=swiper-button]': {
          width: '32px',
          backgroundImage: 'url(/images/icon/arrow.svg)',
          '&::after': { display: 'none' },
          '&.swiper-button-prev': {
            transform: 'rotate(180deg)',
          },
        },
        '--swiper-navigation-top-offset': 'calc(50% - 12px)',
        '--swiper-navigation-size': '32px',
        '--swiper-navigation-sides-offset': 0,
        '--swiper-pagination-bottom': 0,
        '--swiper-pagination-color': 'black',
        '--swiper-pagination-bullet-inactive-color': 'transparent',
        '--swiper-pagination-bullet-inactive-opacity': 1,
        '.swiper-pagination-bullet': {
          border: '1px solid black',
        },
        ...sx,
      }}
      {...props}
    >
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        simulateTouch={true}
        grabCursor={true}
        centeredSlides={true}
        observer={true}
        navigation={true}
        pagination={true}
        autoHeight={false}
        modules={[Navigation, Pagination]}
        style={{ alignItems: 'stretch' }}
      >
        {imageList?.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              style={{ height: 'auto', padding: '0 40px 24px' }}
            >
              <Stack
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ height: '100%', backgroundColor: '#F9FAFB' }}
              >
                <img
                  width={'100%'}
                  src={imgUrlChk(item)}
                  alt={`image-${index}`}
                />
              </Stack>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default ImageSwiper;
