import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ListParams, useCommentList } from '@/hooks/apiHooks';
import CommentList from '@/components/Comment/CommentList';
import SortIcon from '@/public/images/icon/sort.svg';
import PopMenuItem from '@/components/PopMenu/PopMenuItem';
import PopMenu from '@/components/PopMenu';
import CommentInput from '@/components/Comment/CommentInput';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { ActionType } from '@/constant/button';

type BoardDetailCommentProps = {
  commentGroupId: string;
  onChangeCount?: (value: number) => void;
  handleComment?: (isComment: boolean, commentCount: number) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  isFixedInput: boolean;
};

const BoardDetailComment = (props: BoardDetailCommentProps) => {
  const {
    commentGroupId,
    onChangeCount,
    handleComment,
    sx,
    disabled,
    isFixedInput,
  } = props;
  const [params, setParams] = useState<ListParams>({
    listSize: 999,
    currentPage: 1,
    orderType: 'desc',
    sortType: 'createdAt',
  });
  const myInfo = useRecoilValue(MyInfoState);
  const { data, mutate } = useCommentList(commentGroupId, params);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const isSortMenuOpen = Boolean(sortMenuAnchorEl);

  useEffect(() => {
    if (onChangeCount && Array.isArray(data)) {
      onChangeCount(data.length);
    }
    if (Array.isArray(data)) {
      handleComment?.(
        data.filter((item) => item.createdBy.passportId === myInfo.passportId)
          .length > 0,
        data.length,
      );
    }
  }, [data]);

  const setOrder = (value: ActionType.SORT_RECENT | ActionType.SORT_OLD) => {
    let orderType: 'desc' | 'asc';
    switch (value) {
      case ActionType.SORT_OLD:
        orderType = 'asc';
        break;
      case ActionType.SORT_RECENT:
        orderType = 'desc';
        break;
    }
    if (orderType) {
      setParams({
        ...params,
        orderType,
      });
      setSortMenuAnchorEl(null);
    }
  };

  return (
    <Stack
      direction={isFixedInput ? 'column-reverse' : 'column'}
      spacing={'24px'}
      className={classNames({
        'board-detail-comment': true,
        fixedInput: isFixedInput,
      })}
      sx={{ position: 'relative', paddingTop: '24px', ...sx }}
    >
      <Box id={'comment-input-wrap'}>
        <CommentInput
          commentGroupId={commentGroupId}
          updateCommentList={mutate}
          disabled={disabled}
        />
      </Box>
      <Box>
        <Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
          <Button
            id={'comment-sort-menu-button'}
            aria-controls={isSortMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup={'true'}
            aria-expanded={isSortMenuOpen ? 'true' : undefined}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setSortMenuAnchorEl(event.currentTarget);
            }}
            disabled={disabled}
            sx={{
              padding: 0,
              paddingRight: '4px',
              fontSize: '14px',
              color: 'black',
            }}
          >
            <Box width={'24px'} height={'24px'} marginRight={'4px'}>
              <SortIcon />
            </Box>
            {params.orderType === 'desc' ? '최신순' : '오래된순'}
          </Button>
        </Stack>
        <PopMenu
          id={'comment-sort-menu'}
          anchorEl={sortMenuAnchorEl}
          open={isSortMenuOpen}
          onClose={() => setSortMenuAnchorEl(null)}
          MenuListProps={{ 'aria-labelledby': 'menu-button' }}
        >
          <PopMenuItem
            type={ActionType.SORT_RECENT}
            onClick={() => setOrder(ActionType.SORT_RECENT)}
            disabled={disabled}
          >
            최신순
          </PopMenuItem>
          <PopMenuItem
            type={ActionType.SORT_OLD}
            onClick={() => setOrder(ActionType.SORT_OLD)}
            disabled={disabled}
          >
            오래된순
          </PopMenuItem>
        </PopMenu>
        <CommentList list={data || []} onChanged={() => mutate()} />
      </Box>
    </Stack>
  );
};

export default BoardDetailComment;
